import React from 'react';
import {FormattedMessage} from "react-intl";
import * as PropTypes from "prop-types";
import "../CRUDTableFilterComponent.css";

const CRUDUserTableOptions = ({OnPageFilter, OnPageSizeChange, pageSizeOptions, pageSize, displayElements, OnDisplayElementsChange, DisplayElementsOptions , itemCounts}) => {
    const [inputValue, setInputValue] = React.useState("");

    function handleInputChange(event) {
        setInputValue(event.target.value);
        OnPageFilter(event.target.value);
    }

    return (
        <>
            <div className="CRUDTableOptions">
                <div className="filterOptions">Options de recherche</div>
                <div className="filterOptionsContent">
                    <div id="dataset-filter_filter" className="dataTables_filter">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-5"><label><FormattedMessage id="table.filter_items"/></label></div>
                                    <div className="col-md-7"><input type="search" value={ inputValue } className="filterTextBox" onChange={handleInputChange} placeholder="" aria-controls="dataset-filter"/></div>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                    <div className="dataTables_length" id="dataset-filter_length">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="row">
                                    <div className="col-md-5"><FormattedMessage id="table.show"/></div>
                                    <div className="col-md-7">
                                        <select style={{marginLeft:10}} onChange={OnPageSizeChange} value={pageSize} name="dataset-filter_length" aria-controls="dataset-filter" className="">
                                            {pageSizeOptions.map(option => <option key={option} value={option}>{option}</option>)}
                                        </select>
                                        <FormattedMessage id="table.entries_per_page"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6"></div>
                        </div>
                    </div>
                </div>
                <div className="filterOptionsFooter">
                    <div className="" id="dataset-filter_info" role="status" aria-live="polite">
                        <FormattedMessage id="table.foundelements" tagName="div" values={{count: itemCounts}} />
                    </div>
                </div>
            </div>
        </>
    );

};

CRUDUserTableOptions.propTypes = {
    pageSizeOptions : PropTypes.array.isRequired,
    itemCounts : PropTypes.number.isRequired,
    OnPageSizeChange : PropTypes.func.isRequired,
    pageSize: PropTypes.number.isRequired,
    displayElements : PropTypes.number.isRequired,
    OnDisplayElementsChange : PropTypes.func.isRequired
}

export default CRUDUserTableOptions;
