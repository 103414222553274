import React, {Fragment, useEffect} from 'react';
import {Link} from "react-router-dom";
import PropTypes from "prop-types";
import {useIntl} from "react-intl";
import Contexts from "../../../../LangContext";
import LanguageLink from "../../LanguageLink";

const LanguageSwitcherDropdown2023 = ({mobile, enabled}) => {
    const intl = useIntl();
    const { switchToEnglish, switchToFrench } = React.useContext(Contexts);
    const currentLanguage = intl.locale;

    let menuReturned = <></>;


// case 'fr': return <Link to="/en/homepage" hrefLang="en-CA" onClick={switchToEnglish}>EN</Link>;
// case 'en': return <Link to="/fr/homepage" hrefLang="en-CA" onClick={switchToFrench}>FR</Link>;
// default:  return <Link to="/en/homepage" hrefLang="en-CA" onClick={switchToEnglish}>EN</Link>;

    let languageButton = {
        link    : <Link to="#" hrefLang="en-CA" onClick={switchToEnglish}>EN</Link>,
        mobileOption: <>
                        <span className="visually-hidden" lang="en">English</span>
                        <span aria-hidden="true" aria-role="presentation" className="current-language">en</span>
                      </>,
        button  : <button type="button" onClick={switchToEnglish} className="header-language-switcher-trigger" aria-expanded="false" aria-controls="ul-language-switcher-list" aria-label="Actual language : French, Please select your language">
                    <span aria-hidden="true" aria-role="presentation">EN</span>
                  </button>
    }

    switch (currentLanguage) {
        case 'fr':
            languageButton = {
                link: <Link to="#" className="menu-link" hrefLang="en-CA" onClick={switchToEnglish}>
                        <span lang="en" aria-label="Francais">en</span>
                      </Link>,
                mobileOption: <>
                                <span className="visually-hidden" lang="en">English</span>
                                <span aria-hidden="true" aria-role="presentation" className="current-language">en</span>
                              </>,
                button: <button type="button" onClick={switchToEnglish} className="header-language-switcher-trigger"
                                aria-expanded="false" aria-controls="ul-language-switcher-list"
                                aria-label="Actual language : French, Please select your language">
                            <span aria-hidden="true" aria-role="presentation">EN</span>
                        </button>
            }
            break;
        case 'en':
            languageButton = {
                link: <Link to="#" className="menu-link" hrefLang="fr-CA" onClick={switchToFrench}>
                        <span lang="fr" aria-label="Francais">fr</span>
                      </Link>,
                mobileOption: <>
                                  <span className="visually-hidden" lang="fr">Français</span>
                                  <span aria-hidden="true" aria-role="presentation" className="current-language">fr</span>
                              </>,
                button: <button type="button" onClick={switchToFrench} className="header-language-switcher-trigger"
                                aria-expanded="false" aria-controls="ul-language-switcher-list"
                                aria-label="Langue actuelle : Anglais, Sélectionnez votre langue">
                            <span aria-hidden="true" aria-role="presentation">FR</span>
                        </button>
            }
            break;
    }


    if (enabled)
    mobile?
        menuReturned =
            <>
                <nav className="header-language-switcher--mobile" aria-label="Sélectionnez votre langue">
                    <ul className="menu">
                        <li style={{color:"white"}} className="menu-item">
                            <LanguageLink mobile={true}/>
                        </li>
                    </ul>
                </nav>
            </>
    :
        menuReturned =
            <>
                <nav className="header-language-switcher">
                    <LanguageLink mobile={false}/>
                </nav>
            </>

    return menuReturned;

}

export default LanguageSwitcherDropdown2023;

LanguageSwitcherDropdown2023.propTypes = {
    mobile: PropTypes.bool,
    enabled: PropTypes.bool
}

LanguageSwitcherDropdown2023.defaultProps = {
    mobile: false,
    enabled : true
};
