import React from 'react';
import {Link} from "react-router-dom";
import PropTypes from 'prop-types';

const HorizontalCard = ({image, title, text, link, linkText, link2, link2Text, registrationDate, registeredText, updated, updatedText, isMatched}) => {


    const registeredMessage = ()=>{
        if(updated){
            return (
                <>
                    <div style={{float:"right", marginBottom: 10, marginTop: 20}}>
                        <p className="card-text"><small className="text-muted">{registeredText} <strong>{registrationDate}</strong></small></p>
                        {!isMatched && <button style={{float: "right"}} onClick={link2}>{link2Text}</button>}
                    </div>
                </>
            );
        }
        else {return null}
    };

    const updatedMessage = ()=>{
        if(updated){
            return (
                <>
                    <div style={{textAlign:"right", fontStyle:"italic", display:"block", marginRight:10}}>
                        <p className="card-text"><small className="text-muted">{updatedText} <strong>{updated}</strong></small></p>
                    </div>
                </>
            );
        }
        else {return null}
    };

    return (
        <>
            <div className="card mb-3">
                <div className="row no-gutters">
                    <div className="col-md-4">
                        <img src={image} className="card-img" alt="..."/>
                    </div>
                    <div className="col-md-8">
                        {updatedMessage()}
                        <div className="card-body">
                            <h5 className="card-title">{title}</h5>
                            <span className="card-text"> <div dangerouslySetInnerHTML={{__html: text}} />
                                <br/>
                                {!isMatched && <Link to={link}>{linkText}</Link>}
                            </span>

                            {registeredMessage()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

HorizontalCard.prototype = {
    image : PropTypes.string,
    title : PropTypes.string,
    text : PropTypes.string,
    link : PropTypes.string,
    updated : PropTypes.string
}

export default HorizontalCard;
