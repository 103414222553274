import React from 'react';
import { Switch, Route} from 'react-router-dom';
import "./SubMenu2023.css";
import {AdministrationSubMenu} from "./Administration/AdministrationSubMenu";
import {useIntl} from "react-intl";

const SubMenu2023 = ({Caption}) => {

    const intl = useIntl();


    return (
        <>
            <div className="header-sub-nav-container container">
                <div className="header-sub-nav-top">
                    <a className="header-sub-nav-title" href="#">{Caption}</a>
                    <button style={{visibility:"hidden"}} type="button" className="header-sub-nav-button" aria-expanded="false" aria-controls="header-sub-nav-main" aria-label="Ouvrir le menu"></button>
                </div>

                <nav className="header-sub-nav-main" id="header-sub-nav-main" aria-label="Menu de sous-unité">
                    <ul className="menu">
                        <Switch>
                            <Route path={`/${intl.locale}/admin`}>
                                <AdministrationSubMenu />
                            </Route>
                        </Switch>
                    </ul>


                </nav>
            </div>





        </>
    );
};

SubMenu2023.propTypes = {

};

export default SubMenu2023;