import React from 'react';
import ReactTooltip from 'react-tooltip';
import {injectIntl} from "react-intl";
import "./Checkbox.css"

const Checkbox = ({error, name, label, intl, isChecked, tooltip, mandatory, ...rest}) => {
    const {formatMessage} = intl;
    const check = isChecked ? true:false;

    return (
        <div className="form-group">
            <div className="row">

                <ReactTooltip />

                <div className="col-md-4">
                    <label htmlFor={name} className="leftSideLabel">
                        {formatMessage({id:label})}
                        {mandatory?<span className="mandatoryInput">*</span>:""}
                    </label>
                    {(tooltip)?<span data-tip={tooltip} data-multiline="multiline" className="badge badge-primary badge-tooltip">i</span>:""}
                </div>
                <div className="col-md-5">
                    <input
                        type="checkbox"
                        {...rest}
                        checked = {check}
                        id={name}
                        name={name}
                        className=""
                    />
                </div>
                <div className="col-md-3">
                    {error && <div className="alert alert-danger"><span className="validationCustomMessage">{formatMessage({id:error.msg})}</span></div>}
                </div>
            </div>

        </div>
    );
}

export default injectIntl(Checkbox);
