import React from "react";
import * as PropTypes from 'prop-types'
import _ from "lodash";
import {injectIntl} from "react-intl";
import "./pagination.css"

const Pagination = ({ itemsCount, pageSize, currentPage, onPageChange, renderFullButtons, intl, isLoading }) => {

    const pagesCount = Math.ceil(itemsCount / pageSize);
    if (pagesCount === 1) return null;
    const pages = _.range(1, pagesCount + 1);
    const paramsCombined = { pages, itemsCount, pageSize, currentPage, onPageChange, intl, pagesCount }

    if (isLoading === false){
        return (
            <nav>
                <ul className="pagination">
                    {RenderPreviousPageButton(paramsCombined)}
                    {renderFullButtons?
                        RenderFullIndexButtons(paramsCombined):
                        RenderIndexButtonsWithEllipsis(paramsCombined)
                    }
                    {RenderNextPageButton(paramsCombined)}
                </ul>
            </nav>
        )
    }
    else{
        return null;
    }

};

Pagination.propTypes = {
    itemsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
    renderFullButtons: PropTypes.bool.isRequired,
    isLoading: PropTypes.bool.isRequired
};


const RenderFullIndexButtons = ({pages, currentPage, onPageChange}) => {
    return(
        <>
        {pages.map(page => (
            <li key={page} className={page === currentPage ? "page-item active" : "page-item"} >
                <button type="button" className="link-button" onClick={() => onPageChange(page)}>{page}</button>
            </li>
        ))}
        </>
    )
}

const RenderIndexButtonsWithEllipsis = ({pages, currentPage, onPageChange, pagesCount}) => {
    const SPLIT_VALUE = 2

    return (
        <>
        {pages.map(page =>
            {
                if ((page < currentPage - SPLIT_VALUE) && (page !== 1)) return null
                if ((page > currentPage + SPLIT_VALUE) && (page !== pagesCount)) return null
                if ((page === currentPage - SPLIT_VALUE) && (page !== 1) && (page !== 2)) return <li key={page}><span className="ellipsis" style={{paddingTop:10, paddingBottom:10}}>…</span></li>
                if ((page === currentPage + SPLIT_VALUE) && (page !== pagesCount) && (page !== pagesCount -1)) return <li key={page}><span className="ellipsis" style={{paddingTop:10, paddingBottom:10}}>…</span></li>
                else{
                    return (
                        <li key={page} className={page === currentPage ? "page-item active" : "page-item"} >
                            <button type="button" className="link-button" onClick={() => onPageChange(page)}>{page}</button>
                        </li>
                    )
                }
            }
        )}
        </>
    );
};

const RenderPreviousPageButton = ({currentPage, onPageChange, intl, pagesCount}) => {
    const SHOW_FROM_SIZE = 3
    const { formatMessage } = intl;
    const showButton = (currentPage > 1) && pagesCount > SHOW_FROM_SIZE
    const previousPage = (currentPage < 2)? 1 : currentPage-1


    return (
        <li className={showButton ? "page-item" : "page-item disabled"}>
            <button type="button" className="link-button"
                onClick={() => onPageChange(previousPage)}>
                {formatMessage({id:"pagination.previousButton"})}
            </button>
        </li>
    )
}

const RenderNextPageButton = ({currentPage, onPageChange, intl, pagesCount}) => {
    const SHOW_FROM_SIZE = 3
    const { formatMessage } = intl;
    const showButton = (currentPage !== pagesCount) && pagesCount > SHOW_FROM_SIZE
    const nextPage = (currentPage < pagesCount) ? currentPage +1 : pagesCount

    return(
        <li className={showButton ? "page-item" : "page-item disabled"}>
            <button type="button" className="link-button"
                    onClick={() => onPageChange(nextPage)}>
                {formatMessage({id:"pagination.nextButton"})}
            </button>
        </li>
    );
}



export default injectIntl(Pagination);
