import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowWorldRegionsTable extends Component {

    columns = [
        //{path:"id",label:this.props.intl.formatMessage({id: "table.worldregions.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.worldregions.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.worldregions.fr.short"})},
        {path:"en",label:this.props.intl.formatMessage({id: "table.worldregions.en.short"})},
        {key:"edit", content: worldRegion => (
                <Link to={`./manage-worldregions/${worldRegion.id}`} >{this.props.intl.formatMessage({id: "table.worldregions.editLink"})}</Link>
            )}
    ];

    render() {
        const {worldRegionsList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {worldRegionsList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowWorldRegionsTable);
