import React, {Component} from 'react';
import httpService from "../../../../services/httpService";
import {getCountryList} from "../../../../services/countryService"
import {getFacultyList} from "../../../../services/facultyService"
import {getInterestsList} from "../../../../services/interestsService"
import {getLanguagesList} from "../../../../services/languagesService"
import {getPreferencesList} from "../../../../services/preferencesService"
import {getReferencesList} from "../../../../services/referencesService"
import {getSessionsList} from "../../../../services/sessionsService"
import {getIdulNotes} from "../../../../services/idulNotesService";
import {getAvailableFormationsList} from "../../../../services/formationsService";
import {getCurrentSponsor,getSponsorByID} from "../../../../services/sponsorService"
import SponsorForm from "./SponsorForm";
import {Redirect, withRouter} from 'react-router-dom';
import LoadingComponent from "../../common/loadingComponent";
import Contexts from "../../../../LangContext";
import authService from "../../../../services/authService";
import {getConfigsByNames, getPublicConfigsByNames} from "../../../../services/configsService";

class LoadSponsorForm extends Component {
    static contextType = Contexts;
    _isMounted = false;

    state = {
        isLoading : false,
        sponsorNotFound : false,
        sponsorLoad : 1,
        countryList : {},
        userToken : this.props.user,
        currentSponsor : {},
        facultyList : {},
        interestsList : {},
        languagesList : {},
        preferencesList : {},
        referencesList : {},
        sessionsList : {},
        formationsList : {},
        idulNotes : null,
        locale : this.props.locale
    };


    componentDidMount() {
        this._isMounted = true;
        const {switchToFrench} = this.context;
        switchToFrench();
        this.onLoadSponsor();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadSponsor = async () =>{
        try {
            this.setState({isLoading:true});
            const language = this.props.locale;

            const [
                {data: countryList},
                {data: currentSponsor},
                {data: facultyList},
                {data: interestsList},
                {data: languagesList},
                {data: preferencesList},
                {data: referencesList},
                {data: sessionsList},
                {data: formationsList},
                {data: sponsorLoad}
                ] = await httpService.all([
                        getCountryList(language),
                        (this.props.id)?getSponsorByID(this.props.id):getCurrentSponsor(),
                        getFacultyList(language),
                        getInterestsList(language),
                        getLanguagesList(language),
                        getPreferencesList(language, 2),
                        getReferencesList(language),
                        getSessionsList(language, 2),
                        getAvailableFormationsList(language),
                        getPublicConfigsByNames(["maxSponsorLoad"])
                    ]);

            const user = authService.getCurrentUserToken();
            let idulNotes = null;
            if(user.isAdmin){
                const [{data: idulNotesFetched}] = await httpService.all([getIdulNotes(currentSponsor.idul)]);
                idulNotes = idulNotesFetched;
            }


            if (this._isMounted){
                this.setState({
                    countryList,
                    currentSponsor,
                    idulNotes,
                    facultyList,
                    interestsList,
                    languagesList,
                    preferencesList,
                    referencesList,
                    sessionsList,
                    formationsList,
                    sponsorLoad,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false, sponsorNotFound:true});
            }
        }
    }


    renderPage = () => {
        return (
          <>
              <SponsorForm
                  isAdminForm = {(this.props.id)?true:false}
                  locale = {this.props.locale}
                  usersList = {this.props.usersList}
                  setUsersList = {this.props.setUsersList}
                  countryList = {this.state.countryList}
                  idulNotes = {this.state.idulNotes}
                  currentSponsor = {this.state.currentSponsor}
                  facultyList = {this.state.facultyList}
                  interestsList = {this.state.interestsList}
                  languagesList = {this.state.languagesList}
                  preferencesList = {this.state.preferencesList}
                  referencesList = {this.state.referencesList}
                  sessionsList = {this.state.sessionsList}
                  formationsList = {this.state.formationsList}
                  setEditSponsorWindowState = {this.props.setEditSponsorWindowState}
                  sponsorLoad = {this.state.sponsorLoad.maxSponsorLoad}
              />
          </>
        );
    }

    render() {

        if (this.state.sponsorNotFound) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        );
    }
}

export default withRouter(LoadSponsorForm);
