import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import {injectIntl, useIntl} from "react-intl";
import LoadConfigsForm from "../contents/Forms/Configs/LoadConfigsForm";

const ConfigsPageLayout = () => {

    const intl = useIntl();

    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <LoadConfigsForm locale={intl.locale}/>
            </main>
            <Footer/>
        </>
    );
}

export default injectIntl(ConfigsPageLayout);
