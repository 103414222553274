import React from 'react';
import {injectIntl} from "react-intl";
import {default as Select} from 'react-select'

const SelectDropdown = ({name, label, selectedOption, options, onChange, isMulti = false, error, intl, mandatory, ...rest} ) => {
    //console.log("SelectDropDown value: " + JSON.stringify(selectedOption)) // expected : [{"value":1,"label":"Inconnue"}]
    //console.log("SelectDropDown options: " + JSON.stringify(options))      // expected: [{"value":1,"label":"Inconnue"},{"value":2,"label":"Classe A"},{"value":3,"label":"Classe B"}]
    const {formatMessage} = intl;
    const values = renderSelectedElements(options, selectedOption);
    return (
            <div className="form-group">
                <div className="row">
                    <div className="col-md-4">
                        <label htmlFor={name}>
                            {formatMessage({id:label})}
                            {mandatory?<span className="mandatoryInput">*</span>:""}
                        </label>
                    </div>
                    <div className="col-md-5">
                        <Select
                            {...rest}
                            name={name}
                            isMulti={isMulti}
                            defaultValue={values}
                            placeholder={formatMessage({id: "client.form.placeholder"})}
                            options={options}
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange = {onChange}
                        />
                    </div>
                    <div className="col-md-3">
                        {error && <div className="alert alert-danger"><span className="validationCustomMessage">{formatMessage({id:error.msg})}</span></div>}
                    </div>
                </div>
            </div>
    )
};

const renderSelectedElements = (completeList, elements) => {
    let arrayOfElements = Array.isArray(elements) ? elements : [elements]
    const filtered = completeList.filter(item => arrayOfElements.includes(item.value))
    return filtered
}

export default injectIntl(SelectDropdown);
