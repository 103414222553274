import CryptoJS from "crypto-js";

export const cryptoSecretKey="vOVH6sdmpNWjRRIqCc7rdxs01lwHzfr3";

export const encryptData = (data, secretPass) => {
   return CryptoJS.AES.encrypt(
       JSON.stringify(data),
       secretPass
   ).toString();
}


export const decryptData = (data, secretPass) => {
    try {
        const bytes = CryptoJS.AES.decrypt(data, secretPass);
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
    catch(e){
        return "*** Erreur lors de la decryption ***";
    }
}



