import {withRouter, useHistory} from "react-router-dom";
import {FormattedMessage, injectIntl} from "react-intl";
import React, {useEffect, useState} from "react";
import authService from "../../../../services/authService";
import {useVerifyLogin} from "../../common/formFunctionSharing";
import {Button, Col, Form, Nav, Row, Tab} from "react-bootstrap";
import {getAllMails, updateMails} from "../../../../services/mailsService";
import TipTapEditor from "../../TiptapEditor/TiptapEditor";
import "./MailsForm.css";


function MailsForm(locale) {

    const user = authService.getCurrentUserToken();
    const history = useHistory();

    useVerifyLogin(user);

    const [mailsData, setMailsData] = useState([{}]);
    const fetchMails = async () => {
        try {
            const mails = await getAllMails();
            setMailsData(mails.data);
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des courriels :", error);
        }
    };

    useEffect(() => {
        fetchMails().then();
    }, []);



    const doSubmit = async e => {
        try{
            e.preventDefault();
            await updateMails(mailsData);
            history.goBack();
        }catch(err){
            console.error(err.message);
        }

    };

    const tags = () => {
        return(
            <div className="emailTagList">
                <h2>Liste des champs automatisés disponibles:</h2>
                <ul className="tagsUL">
                    <li>###PM_Prenom###</li>
                    <li>###PM_Nom###</li>
                    <li>###EE_Prenom###</li>
                    <li>###EE_Nom###</li>
                    <li>###EE_Salutations###</li>
                    <li>###EE_PaysOrigine###</li>
                    <li>###EE_DateArrivee###</li>
                    <li>###EE_Age###</li>
                    <li>###EE_langueMaternelle###</li>
                    <li>###EE_LangueSecondes###</li>
                    <li>###EE_Faculte###</li>
                    <li>###EE_CycleEtudes###</li>
                    <li>###EE_CourrielUlaval###</li>
                    <li>###EE_CourrielOther###</li>
                    <li>###EE_Messenger###</li>
                    <li>###EE_WhatsAPP###</li>
                    <li>###EE_Telephone###</li>
                    <li>###EE_Messenger###</li>
                </ul>
            </div>
        );
    }

    const handleSubjectChange = (e) => {
        const { name, value } = e.target;
        setMailsData(prevState =>
            prevState.map(mail =>
                mail.name === name ? { ...mail, subject: value } : mail
            )
        );
    };

    const subject = (subjectName) => {
        const mail = mailsData.find(mail => mail.name === subjectName);
        return(
            mail ? (
                <div className="subject" key={mail.id}>
                    <Form.Group controlId={subjectName}>
                        <Form.Label>Sujet:</Form.Label>
                        <Form.Control
                            type="text"
                            value={mail.subject}
                            name={subjectName}
                            onChange={(e) => handleSubjectChange(e)}
                        />
                    </Form.Group>
                </div>
            ) : null
        );
    }

    const handleMailChange = (mailObject, value) => {
        setMailsData(prevState =>
            prevState.map(prevMail =>
                prevMail.id === mailObject.id ? { ...prevMail, value: value } : prevMail
            )
        );
    }

    const createTipTap = (mailName) => {
        const mail = mailsData.find(mail => mail.name === mailName);
        return(
            mail ? (
                <TipTapEditor value={mail.value} onChange={(value) => handleMailChange(mail, value)}/>
            ) : null
        );
    }

    return (
        <>
            <div className="container">
                <h1>Courriels</h1>

                <Form id="mailForm" onSubmit={doSubmit}>

                    <Tab.Container id="left-tabs-example" defaultActiveKey="courrielPMFR">
                        <Row>
                            <Col md={4}>
                                <Nav variant="pills" className="flex-column">
                                    {mailsData.map(mail => (
                                        <Nav.Item key={mail.id}>
                                            <Nav.Link eventKey={mail.name}>{mail.navName}</Nav.Link>
                                        </Nav.Item>
                                    ))}
                                </Nav>
                            </Col>
                            <Col md={8}>
                                <Tab.Content>
                                    {mailsData.map(mail => (
                                        <Tab.Pane eventKey={mail.name} key={mail.id}>
                                            {tags()}
                                            {subject(mail.name)}
                                            {createTipTap(mail.name)}
                                        </Tab.Pane>
                                    ))}
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>

                    <br/>
                    <br/>

                    <div className="row">
                    <div className="col-md-10"></div>
                        <div className="col-md-2">
                            <Button form='mailForm' type="submit" className="btn btn-primary">
                                <FormattedMessage id="configs.form.saveChanges"/>
                            </Button>
                            <br/>
                            <br/>
                        </div>
                    </div>

                </Form>
            </div>
        </>
    );
}

export default withRouter(injectIntl(MailsForm));