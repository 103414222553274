import "./EssentialDocuments.css";
import React, {useEffect, useState} from "react";
import {getPublicConfigsByNames} from "../../../../services/configsService";
import {withRouter} from "react-router-dom";
import {FormattedMessage, injectIntl, useIntl} from "react-intl";
function EssentialDocuments({userSpecificTexts}) {

    const [defaultTextConfigs, setDefaultTextConfigs] = useState("");
    const intl = useIntl();

    const fetchTextConfigs = async () => {
        try {
            const config = await getPublicConfigsByNames(["essentialDocuments" + intl.locale]);
            const values = Object.values(config.data);
            setDefaultTextConfigs(values[0]);
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération des configurations :", error);
        }
    };

    useEffect(() => {
        fetchTextConfigs().then();
    }, []);

    return (
        <>
            <div className="Title">
                <h1><FormattedMessage id="essentialDocuments.title"/></h1>
            </div>
            <div className="Text">
                <div dangerouslySetInnerHTML={{__html: defaultTextConfigs}}/>
            </div>

            <div className="Text">
                <div dangerouslySetInnerHTML={{__html: userSpecificTexts}}/>
            </div>
        </>
    );
}

export default withRouter(injectIntl(EssentialDocuments));