import React, {Component} from 'react';
import LoadingComponent from "../../common/loadingComponent";
import {Redirect} from "react-router-dom";
import httpService from "../../../../services/httpService";
import {getMatchAllUsers} from "../../../../services/matchUsersService";
import FicheMatchUser from "../../dataDisplay/FicheMatchUser";
import {getCountryList} from "../../../../services/countryService";
import {getFacultyList} from "../../../../services/facultyService";
import {getInterestsList} from "../../../../services/interestsService";
import {getLanguagesList} from "../../../../services/languagesService";
import {getPreferencesList} from "../../../../services/preferencesService";
import {getReferencesList} from "../../../../services/referencesService";
import "./PairingPageContent.css"
import {getSessionsList} from "../../../../services/sessionsService";
import ExcelJsExportButton from "../../../utility/ExcelJSExport/ExcelJSExport";

class PairingPageContent extends Component {

    _isMounted = false;

    state = {
        RedirectToLoginPage:false,
        isLoading : false,
        countryList : {},
        facultyList : {},
        interestsList : {},
        sessionsList : {},
        languagesList : {},
        studyLevelList : [{id:0, name: "Premier cycle (certificat, baccalauréat)"},{id:1, name: "Deuxième cycle (Maîtrise, DESS)"},{id:2, name: "Troisième cycle (Doctorat)"}],
        preferencesList : {},
        referencesList : {},
        matches : {},
        locale : this.props.locale
    };

    componentDidMount() {
        this._isMounted = true;
        this.onLoadPage();
    }

    onLoadPage = async () =>{
        try {
            this.setState({isLoading:true});

            const [
                {data: countryList},
                {data: matches},
                {data: facultyList},
                {data: interestsList},
                {data: sessionsList},
                {data: languagesList},
                {data: preferencesList},
                {data: referencesList},
            ] = await httpService.all([
                getCountryList('fr'),
                getMatchAllUsers(),
                getFacultyList('fr'),
                getInterestsList('fr'),
                getSessionsList('fr'),
                getLanguagesList('fr'),
                getPreferencesList('fr'),
                getReferencesList('fr'),
            ]);
            if (this._isMounted){
                this.setState({
                    countryList,
                    matches,
                    facultyList,
                    interestsList,
                    sessionsList,
                    languagesList,
                    preferencesList,
                    referencesList,
                    isLoading: false
                });
            }

        }  catch(err)  {
            console.log("Error loading API")
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }

    // <DownloadExcelFile />

    renderMatches = (matches) => {
        if(matches.length > 0){
            return(
                matches.map((value, key) => (
                    <div className={(key % 2)? "row matchRowOdd" : "row matchRowEven"} key={key}>
                        <div className="col-md-6">
                            <FicheMatchUser
                                data={value.client}
                                facultyList={this.state.facultyList}
                                countryList={this.state.countryList}
                                languageList={this.state.languagesList}
                                preferencesList={this.state.preferencesList}
                                interestsList={this.state.interestsList}
                                sessionsList={this.state.sessionsList}
                                studyLevelList={this.state.studyLevelList}
                            />
                        </div>
                        <div className="col-md-6">
                            <FicheMatchUser
                                data={value.sponsor}
                                facultyList={this.state.facultyList}
                                countryList={this.state.countryList}
                                languageList={this.state.languagesList}
                                preferencesList={this.state.preferencesList}
                                interestsList={this.state.interestsList}
                                sessionsList={this.state.sessionsList}
                                studyLevelList={this.state.studyLevelList}
                                forceFooter="Parrain/Marraine attitré(e)"
                            />
                        </div>
                    </div>
                ))
            )
        }
        else{
            return(
                <div>Aucun match à afficher.</div>
            )
        }

    }

    renderPage = (matches) => {

        if (this._isMounted){
            return (
                <>
                    <ExcelJsExportButton
                            matchesList = {this.state.matches}
                            facultyList={this.state.facultyList}
                            countryList={this.state.countryList}
                            languageList={this.state.languagesList}
                            preferencesList={this.state.preferencesList}
                            referencesList={this.state.referencesList}
                            interestsList={this.state.interestsList}
                    />

                    {this.renderMatches(matches)}
                </>
            );
        }

    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                <div className="container">
                    <h1>Résumé des matchs actuels</h1>

                    <div className="row">
                        <div className="col-md-12">
                            {this.state.isLoading ? <LoadingComponent/> : this.renderPage(this.state.matches)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default PairingPageContent;