import {NavLink} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import React from "react";

export const AdministrationSubMenu = () =>{
    const intl = useIntl();

    return <>

        <li className="has-children">
            <NavLink className="menu-link"
                     to="#"
                     activeClassName="is-active"
                     isActive={(match, location) => {
                         // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                         return location.pathname.startsWith(`/${intl.locale}/admin/usermanagement`);
                     }}>
                <span className="container">Gestion des jumelages</span>
            </NavLink>
            <ul>
                <li>
                    <NavLink to={`/${intl.locale}/admin/usermanagement/clientlist`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/usermanagement/clientlist`);
                             }}>
                        <FormattedMessage id="navigation1.clientList"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/usermanagement/sponsorlist`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/usermanagement/sponsorlist`);
                             }}>
                        <FormattedMessage id="navigation1.sponsorList"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/usermanagement/pairing-list`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/usermanagement/pairing-list`);
                             }}>
                        <FormattedMessage id="navigation1.showMatches"/>
                    </NavLink>
                </li>

            </ul>
        </li>


        <li className="has-children">
            <NavLink className="menu-link"
                     to="#"
                     activeClassName="is-active"
                     isActive={(match, location) => {
                         // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                         return location.pathname.startsWith(`/${intl.locale}/admin/configs`);
                     }}>
                <span className="container">Configuration du système</span>
            </NavLink>
            <ul>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-countries`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-countries`);
                             }}>
                        <FormattedMessage id="navigation1.manageCountries"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-worldregions`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-worldregions`);
                             }}>
                        <FormattedMessage id="navigation1.manageWorldRegions"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-interests`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-interests`);
                             }}>
                        <FormattedMessage id="navigation1.manageInterests"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-preferences`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-preferences`);
                             }}>
                        <FormattedMessage id="navigation1.managePreferences"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-references`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-references`);
                             }}>
                        <FormattedMessage id="navigation1.manageReferences"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-sessions`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-sessions`);
                             }}>
                        <FormattedMessage id="navigation1.manageSessions"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-formations`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-formations`);
                             }}>
                        <FormattedMessage id="navigation1.manageFormations"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-mails`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-mails`);
                             }}>
                        <FormattedMessage id="navigation1.manageMails"/>
                    </NavLink>
                </li>
                <li>
                    <NavLink to={`/${intl.locale}/admin/configs/manage-configs`}
                             className="item"
                             activeClassName="is-active"
                             isActive={(match, location) => {
                                 // Vérifiez si le chemin actuel est exactement '/admin/sousmenu'
                                 return location.pathname.startsWith(`/${intl.locale}/admin/configs/manage-configs`);
                             }}>
                        <FormattedMessage id="navigation1.manageConfigs"/>
                    </NavLink>
                </li>
            </ul>
        </li>

    </>
}


