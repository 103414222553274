import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {Col, Nav, Row, Tab} from "react-bootstrap";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";
import {updateConfigs, systemReset} from "../../../../services/configsService";
import {confirmAlert} from "react-confirm-alert";
import RichTextEditor from "react-rte";

import './ConfigsForm.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class ConfigsForm extends Form {

    state = {
        locale : this.props.locale,
        configs : this.props.configs,
        errors:{}
    }



    constructor(props) {
        super(props);
        this.state.sendButtonDisabled = false;
        this.state.resetButtonDisabled = false;
        this.state.configs = this.props.configs;
        this.RedirectToLogoutPage = this.RedirectToLogoutPage.bind(this);

        this.state.data = {
            emailNotificationsAddr : this.getConfig("emailNotificationsAddr"),
            maxSponsorLoad : this.getConfig("maxSponsorLoad"),
            startDate: this.getConfig("startDate"),
            startTime: this.getConfig("startTime"),
            endDate: this.getConfig("endDate"),
            endTime: this.getConfig("endTime"),
            pmConfirmation : RichTextEditor.createValueFromString(this.getConfig("pmConfirmation"), 'html'),
            infoBoxTitleFR : this.getConfig("infoBoxTitleFR"),
            infoBoxTitleEN : this.getConfig("infoBoxTitleEN"),
            infoBoxTextFR : RichTextEditor.createValueFromString(this.getConfig("infoBoxTextFR"), 'html'),
            infoBoxTextEN : RichTextEditor.createValueFromString(this.getConfig("infoBoxTextEN"), 'html'),
            homepageEnabledTextFR : RichTextEditor.createValueFromString(this.getConfig("homepageEnabledTextFR"), 'html'),
            homepageEnabledTextEN : RichTextEditor.createValueFromString(this.getConfig("homepageEnabledTextEN"), 'html'),
            homepageDisabledTextFR : RichTextEditor.createValueFromString(this.getConfig("homepageDisabledTextFR"), 'html'),
            homepageDisabledTextEN : RichTextEditor.createValueFromString(this.getConfig("homepageDisabledTextEN"), 'html'),
            clientRegisteredTextFR : RichTextEditor.createValueFromString(this.getConfig("clientRegisteredTextFR"), 'html'),
            clientRegisteredTextEN : RichTextEditor.createValueFromString(this.getConfig("clientRegisteredTextEN"), 'html'),
            sponsorRegisteredTextFR : RichTextEditor.createValueFromString(this.getConfig("sponsorRegisteredTextFR"), 'html'),
            sponsorRegisteredTextEN : RichTextEditor.createValueFromString(this.getConfig("sponsorRegisteredTextEN"), 'html'),
            clientDescriptionFR : RichTextEditor.createValueFromString(this.getConfig("clientDescriptionFR"), 'html'),
            clientDescriptionEN : RichTextEditor.createValueFromString(this.getConfig("clientDescriptionEN"), 'html'),
            sponsorDescriptionFR : RichTextEditor.createValueFromString(this.getConfig("sponsorDescriptionFR"), 'html'),
            sponsorDescriptionEN : RichTextEditor.createValueFromString(this.getConfig("sponsorDescriptionEN"), 'html'),
            essentialDocumentsFR : RichTextEditor.createValueFromString(this.getConfig("essentialDocumentsFR"), 'html'),
            essentialDocumentsEN : RichTextEditor.createValueFromString(this.getConfig("essentialDocumentsEN"), 'html'),
            essentialDocumentsEIFR : RichTextEditor.createValueFromString(this.getConfig("essentialDocumentsEIFR"), 'html'),
            essentialDocumentsEIEN : RichTextEditor.createValueFromString(this.getConfig("essentialDocumentsEIEN"), 'html'),
            essentialDocumentsPMFR : RichTextEditor.createValueFromString(this.getConfig("essentialDocumentsPMFR"), 'html'),
            essentialDocumentsPMEN : RichTextEditor.createValueFromString(this.getConfig("essentialDocumentsPMEN"), 'html'),
            welcomePairingEIFR : RichTextEditor.createValueFromString(this.getConfig("welcomePairingEIFR"), 'html'),
            welcomePairingEIEN : RichTextEditor.createValueFromString(this.getConfig("welcomePairingEIEN"), 'html'),
            welcomePairingPMFR : RichTextEditor.createValueFromString(this.getConfig("welcomePairingPMFR"), 'html'),
            welcomePairingPMEN : RichTextEditor.createValueFromString(this.getConfig("welcomePairingPMEN"), 'html'),
/*            confirmEIFR : RichTextEditor.createValueFromString(this.getConfig("confirmEIFR"), 'html'),
            confirmEIEN : RichTextEditor.createValueFromString(this.getConfig("confirmEIEN"), 'html'),
            confirmPMFR : RichTextEditor.createValueFromString(this.getConfig("confirmPMFR"), 'html'),
            confirmPMEN : RichTextEditor.createValueFromString(this.getConfig("confirmPMEN"), 'html'),*/
            congratulationPairingFR : RichTextEditor.createValueFromString(this.getConfig("congratulationPairingFR"), 'html'),
            congratulationPairingEN : RichTextEditor.createValueFromString(this.getConfig("congratulationPairingEN"), 'html'),


            errors:[]
        };

    }

    schemaDefinition = {
        emailNotificationsAddr: Joi.string().required(),
        maxSponsorLoad: Joi.number().max(10).integer().required(),
        startDate: Joi.date().format("YYYY-MM-DD").required(),
        startTime: Joi.string().required(),
        endDate: Joi.date().format("YYYY-MM-DD").required(),
        endTime: Joi.string().required(),
        pmConfirmation : Joi.object(),
        infoBoxTitleFR : Joi.string(),
        infoBoxTitleEN : Joi.string(),
        infoBoxTextFR : Joi.object(),
        infoBoxTextEN : Joi.object(),
        homepageEnabledTextFR : Joi.object(),
        homepageEnabledTextEN : Joi.object(),
        homepageDisabledTextFR : Joi.object(),
        homepageDisabledTextEN : Joi.object(),
        clientRegisteredTextFR : Joi.object(),
        clientRegisteredTextEN : Joi.object(),
        sponsorRegisteredTextFR : Joi.object(),
        sponsorRegisteredTextEN : Joi.object(),
        clientDescriptionFR : Joi.object(),
        clientDescriptionEN : Joi.object(),
        sponsorDescriptionFR : Joi.object(),
        sponsorDescriptionEN : Joi.object(),
        essentialDocumentsFR : Joi.object(),
        essentialDocumentsEN : Joi.object(),
        essentialDocumentsEIFR : Joi.object(),
        essentialDocumentsEIEN : Joi.object(),
        essentialDocumentsPMFR : Joi.object(),
        essentialDocumentsPMEN : Joi.object(),
        welcomePairingEIFR : Joi.object(),
        welcomePairingEIEN : Joi.object(),
        welcomePairingPMFR : Joi.object(),
        welcomePairingPMEN : Joi.object(),
/*        confirmEIFR : Joi.object(),
        confirmEIEN : Joi.object(),
        confirmPMFR : Joi.object(),
        confirmPMEN : Joi.object(),*/
        congratulationPairingFR : Joi.object(),
        congratulationPairingEN : Joi.object()
    };

    getConfig = (configName) => {
        const config = this.state.configs.filter(obj => {return obj.name === configName});
        return config[0].value;
    }

    doSubmit = async e => {
        this.setState({sendButtonDisabled:true})
        let configObject = {...this.state.data};

        configObject.emailNotificationsAddr = this.state.data.emailNotificationsAddr.toString('html');
        configObject.maxSponsorLoad = parseInt(this.state.data.maxSponsorLoad);
        configObject.pmConfirmation = this.state.data.pmConfirmation.toString('html');
        configObject.infoBoxTitleFR = this.state.data.infoBoxTitleFR.toString('html');
        configObject.infoBoxTitleEN = this.state.data.infoBoxTitleEN.toString('html');
        configObject.infoBoxTextFR = this.state.data.infoBoxTextFR.toString('html');
        configObject.infoBoxTextEN = this.state.data.infoBoxTextEN.toString('html');
        configObject.homepageEnabledTextFR = this.state.data.homepageEnabledTextFR.toString('html');
        configObject.homepageEnabledTextEN = this.state.data.homepageEnabledTextEN.toString('html');
        configObject.homepageDisabledTextFR = this.state.data.homepageDisabledTextFR.toString('html');
        configObject.homepageDisabledTextEN = this.state.data.homepageDisabledTextEN.toString('html');
        configObject.clientRegisteredTextFR = this.state.data.clientRegisteredTextFR.toString('html');
        configObject.clientRegisteredTextEN = this.state.data.clientRegisteredTextEN.toString('html');
        configObject.sponsorRegisteredTextFR = this.state.data.sponsorRegisteredTextFR.toString('html');
        configObject.sponsorRegisteredTextEN = this.state.data.sponsorRegisteredTextEN.toString('html');
        configObject.clientDescriptionFR = this.state.data.clientDescriptionFR.toString('html');
        configObject.clientDescriptionEN = this.state.data.clientDescriptionEN.toString('html');
        configObject.sponsorDescriptionFR = this.state.data.sponsorDescriptionFR.toString('html');
        configObject.sponsorDescriptionEN = this.state.data.sponsorDescriptionEN.toString('html');
        configObject.essentialDocumentsFR = this.state.data.essentialDocumentsFR.toString('html');
        configObject.essentialDocumentsEN = this.state.data.essentialDocumentsEN.toString('html');
        configObject.essentialDocumentsEIFR = this.state.data.essentialDocumentsEIFR.toString('html');
        configObject.essentialDocumentsEIEN = this.state.data.essentialDocumentsEIEN.toString('html');
        configObject.essentialDocumentsPMFR = this.state.data.essentialDocumentsPMFR.toString('html');
        configObject.essentialDocumentsPMEN = this.state.data.essentialDocumentsPMEN.toString('html');
        configObject.welcomePairingEIFR = this.state.data.welcomePairingEIFR.toString('html');
        configObject.welcomePairingEIEN = this.state.data.welcomePairingEIEN.toString('html');
        configObject.welcomePairingPMFR = this.state.data.welcomePairingPMFR.toString('html');
        configObject.welcomePairingPMEN = this.state.data.welcomePairingPMEN.toString('html');
/*        configObject.confirmEIFR = this.state.data.confirmEIFR.toString('html');
        configObject.confirmEIEN = this.state.data.confirmEIEN.toString('html');
        configObject.confirmPMFR = this.state.data.confirmPMFR.toString('html');
        configObject.confirmPMEN = this.state.data.confirmPMEN.toString('html');*/
        configObject.congratulationPairingFR = this.state.data.congratulationPairingFR.toString('html');
        configObject.congratulationPairingEN = this.state.data.congratulationPairingEN.toString('html');


        delete configObject.errors;

        try{
            // await setConfig("startDate", this.state.data.startDate);
            await updateConfigs(configObject);
            this.props.history.goBack();
        }catch(err){
            console.error(err.message);
        }

    };


    handleMaxSponsorLoadChange = (value) => {
        let data = {...this.state.data}
        data.maxSponsorLoad = value;
        this.setState({data});
    }
    handleInfoBoxTextFRChange = (value) => {
        let data = {...this.state.data}
        data.infoBoxTextFR = value;
        this.setState({data});
    }
    handleInfoBoxTextENChange = (value) => {
        let data = {...this.state.data}
        data.infoBoxTextEN = value;
        this.setState({data});
    }
    handlePMConfirmationChange = (value) => {
        let data = {...this.state.data}
        data.pmConfirmation = value;
        this.setState({data});
    }
    handleHomepageEnabledTextFRChange = (value) => {
        let data = {...this.state.data}
        data.homepageEnabledTextFR = value;
        this.setState({data});
    }
    handleHomepageEnabledTextENChange = (value) => {
        let data = {...this.state.data}
        data.homepageEnabledTextEN = value;
        this.setState({data});
    }
    handleHomepageDisabledTextFRChange = (value) => {
        let data = {...this.state.data}
        data.homepageDisabledTextFR = value;
        this.setState({data});
    }
    handleHomepageDisabledTextENChange = (value) => {
        let data = {...this.state.data}
        data.homepageDisabledTextEN = value;
        this.setState({data});
    }
    handleClientRegisteredTextFRChange = (value) => {
        let data = {...this.state.data}
        data.clientRegisteredTextFR = value;
        this.setState({data});
    }
    handleClientRegisteredTextENChange = (value) => {
        let data = {...this.state.data}
        data.clientRegisteredTextEN = value;
        this.setState({data});
    }
    handleSponsorRegisteredTextFRChange = (value) => {
        let data = {...this.state.data}
        data.sponsorRegisteredTextFR = value;
        this.setState({data});
    }
    handleSponsorRegisteredTextENChange = (value) => {
        let data = {...this.state.data}
        data.sponsorRegisteredTextEN = value;
        this.setState({data});
    }
    handleClientDescriptionFRChange = (value) => {
        let data = {...this.state.data}
        data.clientDescriptionFR = value;
        this.setState({data});
    }
    handleClientDescriptionENChange = (value) => {
        let data = {...this.state.data}
        data.clientDescriptionEN = value;
        this.setState({data});
    }
    handleSponsorDescriptionFRChange = (value) => {
        let data = {...this.state.data}
        data.sponsorDescriptionFR = value;
        this.setState({data});
    }
    handleSponsorDescriptionENChange = (value) => {
        let data = {...this.state.data}
        data.sponsorDescriptionEN = value;
        this.setState({data});
    }

    handleEssentialDocumentsENChange = (value) => {
        let data = {...this.state.data}
        data.essentialDocumentsEN = value;
        this.setState({data});
    }

    handleEssentialDocumentsFRChange = (value) => {
        let data = {...this.state.data}
        data.essentialDocumentsFR = value;
        this.setState({data});
    }

    handleEssentialDocumentsEIENChange = (value) => {
        let data = {...this.state.data}
        data.essentialDocumentsEIEN = value;
        this.setState({data});
    }

    handleEssentialDocumentsEIFRChange = (value) => {
        let data = {...this.state.data}
        data.essentialDocumentsEIFR = value;
        this.setState({data});
    }

    handleEssentialDocumentsPMENChange = (value) => {
        let data = {...this.state.data}
        data.essentialDocumentsPMEN = value;
        this.setState({data});
    }

    handleEssentialDocumentsPMFRChange = (value) => {
        let data = {...this.state.data}
        data.essentialDocumentsPMFR = value;
        this.setState({data});
    }

    handleWelcomePairingEIFRChange = (value) => {
        let data = {...this.state.data}
        data.welcomePairingEIFR = value;
        this.setState({data});
    }

    handleWelcomePairingEIENChange = (value) => {
        let data = {...this.state.data}
        data.welcomePairingEIEN = value;
        this.setState({data});
    }

    handleWelcomePairingPMFRChange = (value) => {
        let data = {...this.state.data}
        data.welcomePairingPMFR = value;
        this.setState({data});
    }

    handleWelcomePairingPMENChange = (value) => {
        let data = {...this.state.data}
        data.welcomePairingPMEN = value;
        this.setState({data});
    }


    /*handleConfirmEIFRChange = (value) => {
        let data = {...this.state.data}
        data.confirmEIFR = value;
        this.setState({data});
    }

    handleConfirmEIENChange = (value) => {
        let data = {...this.state.data}
        data.confirmEIEN = value;
        this.setState({data});
    }

    handleConfirmPMFRChange = (value) => {
        let data = {...this.state.data}
        data.confirmPMFR = value;
        this.setState({data});
    }

    handleConfirmPMENChange = (value) => {
        let data = {...this.state.data}
        data.confirmPMEN = value;
        this.setState({data});
    }*/

    handleCongratulationPairingFRChange = (value) => {
        let data = {...this.state.data}
        data.congratulationPairingFR = value;
        this.setState({data});
    }
    handleCongratulationPairingENChange = (value) => {
        let data = {...this.state.data}
        data.congratulationPairingEN = value;
        this.setState({data});
    }

    RedirectToLogoutPage=()=> {
        let url = "/logout";
        this.props.history.push(url);
    }

    resetSystem = async (e) =>{
        e.preventDefault();
        this.setState({resetButtonDisabled: true});

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui'>
                        <h1>ATTENTION!!!</h1>
                        <p>Vous êtes sur le point de supprimer toutes les fiches EI et les jumelages pour la session actuelle.</p>
                        <p>Désirez-vous continuer ?</p>
                        <button className="btn btn-danger" style={{marginRight:10}} onClick={onClose}>Non</button>
                        <button className="btn btn-black" onClick={async () => {
                            await systemReset();
                            this.RedirectToLogoutPage();
                            onClose();
                        }}>
                            Oui, supprimez-les!
                        </button>
                    </div>
                );
            }
        });



        this.setState({resetButtonDisabled: false})
    }

    render() {

        return (
            <>
                <div className="container">

                    <div className="SpacerBlock"></div>

                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit}>

                        <Tab.Container id="left-tabs-example" defaultActiveKey="SystemActivation">
                            <Row>
                                <Col md={4}>
                                    <Nav variant="pills" className="flex-column">
                                        <Nav.Item>
                                            <Nav.Link eventKey="SystemActivation">Activation du système</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="BoiteInfos">Boite d'information</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="BienvenueActif">Message de bienvenue (Système activé)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="BienvenueDesactive">Message de bienvenue (Système désactivé)</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="DocumentsEssentiels">Documents essentiels</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="DocumentsEssentielsEI">Documents essentiels pour les EI</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="DocumentsEssentielsPM">Documents essentiels pour les PM</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="EtudiantInscrit">Félicitations ÉÉ inscrits</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="PMInscrit">Félicitations PM inscrits</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="PMConfirmation">PM Confirmation de jumelage</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="FicheEEAvantInscription">Description de la fiche ÉÉ avant inscription</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="FichePMAvantInscription">Description de la fiche PM avant inscription</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="BienvenueJumelageEI">EI message d'intro de la page Mon Jumelage</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="BienvenueJumelagePM">PM message d'intro de la page Mon Jumelage</Nav.Link>
                                        </Nav.Item>
                                        {/*<Nav.Item>
                                            <Nav.Link eventKey="ConfirmerEI">EI confirmer contact avec PM</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="ConfirmerPM">PM confirmer contact avec EI</Nav.Link>
                                        </Nav.Item>*/}
                                        <Nav.Item>
                                            <Nav.Link eventKey="FelicitationsJumelage">Félicitations de jumelage de la page Mon Jumelage</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Col>
                                <Col md={8}>
                                    <Tab.Content>

                                        <Tab.Pane eventKey="SystemActivation">
                                            <h1>Activation du système</h1>
                                            {this.renderDatePickerField("startDate", "configsForm.startDate", false, "-1d","+1y","-75:+1y",)}
                                            {this.renderInputTimeOnly("startTime", "configsForm.startTime", false)}
                                            {this.renderDatePickerField("endDate", "configsForm.endDate", false, 0,"+1y","-75:+1y",)}
                                            {this.renderInputTimeOnly("endTime", "configsForm.endTime", false)}
                                            {this.renderInput("maxSponsorLoad", "configsForm.maxSponsorLoad", false)}
                                            {this.renderInput("emailNotificationsAddr", "configsForm.emailNotificationsAddr", false)}
                                            <br/>
                                            <br/>Date de mise en ligne prévue: {this.state.data.startDate}
                                            <br/>
                                            <button disabled={this.state.resetButtonDisabled} className="btn btn-danger" onClick={this.resetSystem} style={{marginTop:50}}>
                                                <strong>*** [ RÉINITIALISER LE SYSTÈME ] ***</strong>
                                            </button>
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="BoiteInfos">
                                            <h1>Boite d'information</h1>
                                            {this.renderInput("infoBoxTitleFR", "configsForm.infoBoxTitleFR")}
                                            {this.renderInput("infoBoxTitleEN", "configsForm.infoBoxTitleEN")}

                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.infoBoxTextFR}
                                                onChange={this.handleInfoBoxTextFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.infoBoxTextEN}
                                                onChange={this.handleInfoBoxTextENChange}
                                            />

                                            {/*{this.renderTextArea("infoBoxTextFR", "configsForm.infoBoxTextFR", true)}*/}
                                            {/*{this.renderTextArea("infoBoxTextEN", "configsForm.infoBoxTextEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="BienvenueActif">
                                            <h1>Message de bienvenue<br/>(Système activé)</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.homepageEnabledTextFR}
                                                onChange={this.handleHomepageEnabledTextFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.homepageEnabledTextEN}
                                                onChange={this.handleHomepageEnabledTextENChange}
                                            />

                                            {/*{this.renderTextArea("homepageEnabledTextFR", "configsForm.homepageEnabledTextFR", true)}*/}
                                            {/*{this.renderTextArea("homepageEnabledTextEN", "configsForm.homepageEnabledTextEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="BienvenueDesactive">
                                            <h1>Message de bienvenue<br/>(Système désactivé)</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.homepageDisabledTextFR}
                                                onChange={this.handleHomepageDisabledTextFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.homepageDisabledTextEN}
                                                onChange={this.handleHomepageDisabledTextENChange}
                                            />

                                            {/*{this.renderTextArea("homepageDisabledTextFR", "configsForm.homepageDisabledTextFR", true)}*/}
                                            {/*{this.renderTextArea("homepageDisabledTextEN", "configsForm.homepageDisabledTextEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="DocumentsEssentiels">
                                            <h1>Documents essentiels</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.essentialDocumentsFR}
                                                onChange={this.handleEssentialDocumentsFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.essentialDocumentsEN}
                                                onChange={this.handleEssentialDocumentsENChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="DocumentsEssentielsEI">
                                            <h1>Documents essentiels pour les EI</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.essentialDocumentsEIFR}
                                                onChange={this.handleEssentialDocumentsEIFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.essentialDocumentsEIEN}
                                                onChange={this.handleEssentialDocumentsEIENChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="DocumentsEssentielsPM">
                                            <h1>Documents essentiels pour les PM</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.essentialDocumentsPMFR}
                                                onChange={this.handleEssentialDocumentsPMFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.essentialDocumentsPMEN}
                                                onChange={this.handleEssentialDocumentsPMENChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="EtudiantInscrit">
                                            <h1>Étudiants de l'international inscrits</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.clientRegisteredTextFR}
                                                onChange={this.handleClientRegisteredTextFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.clientRegisteredTextEN}
                                                onChange={this.handleClientRegisteredTextENChange}
                                            />

                                            {/*{this.renderTextArea("clientRegisteredTextFR", "configsForm.clientRegisteredTextFR", true)}*/}
                                            {/*{this.renderTextArea("clientRegisteredTextEN", "configsForm.clientRegisteredTextEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="PMInscrit">
                                            <h1>Parrains et marraines inscrits</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.sponsorRegisteredTextFR}
                                                onChange={this.handleSponsorRegisteredTextFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.sponsorRegisteredTextEN}
                                                onChange={this.handleSponsorRegisteredTextENChange}
                                            />

                                            {/*{this.renderTextArea("sponsorRegisteredTextFR", "configsForm.sponsorRegisteredTextFR", true)}*/}
                                            {/*{this.renderTextArea("sponsorRegisteredTextEN", "configsForm.sponsorRegisteredTextEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="PMConfirmation">
                                            <h1>Parrains et marraines : Confirmation de jumelage</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.pmConfirmation}
                                                onChange={this.handlePMConfirmationChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="FicheEEAvantInscription">
                                            <h1>Description de la fiche ÉÉ avant inscription</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.clientDescriptionFR}
                                                onChange={this.handleClientDescriptionFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.clientDescriptionEN}
                                                onChange={this.handleClientDescriptionENChange}
                                            />

                                            {/*{this.renderTextArea("clientDescriptionFR", "configsForm.clientDescriptionFR", true)}*/}
                                            {/*{this.renderTextArea("clientDescriptionEN", "configsForm.clientDescriptionEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="FichePMAvantInscription">
                                            <h1>Description de la fiche PM avant inscription</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.sponsorDescriptionFR}
                                                onChange={this.handleSponsorDescriptionFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.sponsorDescriptionEN}
                                                onChange={this.handleSponsorDescriptionENChange}
                                            />

                                            {/*{this.renderTextArea("sponsorDescriptionFR", "configsForm.sponsorDescriptionFR", true)}*/}
                                            {/*{this.renderTextArea("sponsorDescriptionEN", "configsForm.sponsorDescriptionEN", true)}*/}
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="BienvenueJumelageEI">
                                            <h1>Message d'introduction de la page Mon Jumelage pour l'EI</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.welcomePairingEIFR}
                                                onChange={this.handleWelcomePairingEIFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.welcomePairingEIEN}
                                                onChange={this.handleWelcomePairingEIENChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="BienvenueJumelagePM">
                                            <h1>Message d'introduction de la page Mon Jumelage pour le PM</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.welcomePairingPMFR}
                                                onChange={this.handleWelcomePairingPMFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.welcomePairingPMEN}
                                                onChange={this.handleWelcomePairingPMENChange}
                                            />
                                        </Tab.Pane>
                                        {/*<Tab.Pane eventKey="ConfirmerEI">
                                            <h1>Étudiants de l'international:<br/>Confirmer le contact avec le parrain/marraine</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.confirmEIFR}
                                                onChange={this.handleConfirmEIFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.confirmEIEN}
                                                onChange={this.handleConfirmEIENChange}
                                            />
                                        </Tab.Pane>
                                        <Tab.Pane eventKey="ConfirmerPM">
                                            <h1>Parrains/marraines:<br/>Confirmer le contact avec l'étudiant</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.confirmPMFR}
                                                onChange={this.handleConfirmPMFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.confirmPMEN}
                                                onChange={this.handleConfirmPMENChange}
                                            />
                                        </Tab.Pane>*/}
                                        <Tab.Pane eventKey="FelicitationsJumelage">
                                            <h1>Confirmation de jumelage</h1>
                                            <span className="FrenchLabel">Français</span>
                                            <RichTextEditor
                                                value={this.state.data.congratulationPairingFR}
                                                onChange={this.handleCongratulationPairingFRChange}
                                            />
                                            <span className="EnglishLabel">Anglais</span>
                                            <RichTextEditor
                                                value={this.state.data.congratulationPairingEN}
                                                onChange={this.handleCongratulationPairingENChange}
                                            />
                                        </Tab.Pane>
                                    </Tab.Content>
                                    <button disabled={this.state.sendButtonDisabled} className="btn btn-primary" style={{marginTop:50}}>
                                        {this.state.sendButtonDisabled ? <FormattedMessage id="configs.form.savingChanges"/> : <FormattedMessage id="configs.form.saveChanges"/>}
                                    </button>
                                </Col>
                            </Row>
                        </Tab.Container>

                        <br/>
                        <br/>


                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(ConfigsForm));
