import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowInterestsTable extends Component {

    columns = [
        //{path:"id",label:this.props.intl.formatMessage({id: "table.interests.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.interests.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.interests.fr.short"})},
        {path:"en",label:this.props.intl.formatMessage({id: "table.interests.en.short"})},
        {key:"edit", content: interest => (
                <Link to={`./manage-interests/${interest.id}`} >{this.props.intl.formatMessage({id: "table.interests.editLink"})}</Link>
            )}
    ];

    render() {
        const {interestsList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {interestsList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowInterestsTable);
