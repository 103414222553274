import React, {Component} from 'react';
import {getWorldRegionByID} from "../../../../services/worldregionsService"
import {Redirect, withRouter} from 'react-router-dom';
import LoadingComponent from "../../common/loadingComponent";
import WorldRegionForm from "./WorldRegionForm";

class LoadWorldRegionForm extends Component {

    _isMounted = false;

    state = {
        RedirectToLoginPage:false,
        isLoading : false,
        userToken : this.props.user,
        worldRegion : {},
        locale : this.props.locale
    };


    componentDidMount() {
        this._isMounted = true;

        if (this.props.id !== undefined){
            this.onLoadWorldRegion();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadWorldRegion = async () =>{
        try {
            this.setState({isLoading:true});
            const {data: worldRegion} = await getWorldRegionByID(this.props.id);
            if (this._isMounted){
                this.setState({
                    worldRegion,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }


    renderPage = () => {
        return (
          <>
              <WorldRegionForm
                  isAdminForm = {(this.props.id)?true:false}
                  locale = {this.props.locale}
                  worldRegion = {this.state.worldRegion}
              />
          </>
        );
    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        );
    }
}

export default withRouter(LoadWorldRegionForm);
