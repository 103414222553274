import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./WhiteBoxContent.css";
import {Link} from "react-router-dom";

const WhiteBoxContent = props => {
    // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    const iconClass = "bi bi-" + props.linkIcon;

    return (
        <div className="WhiteBoxContent">
            <div className="Block-Content">

                {props.isTitleH1 && <h1 className={"title " + props.titleColor}>{props.title}</h1> || <h2 className={"title " + props.titleColor}>{props.title}</h2>}

                <div className="content">
                    <div dangerouslySetInnerHTML={{__html: props.message}} />
                </div>
                <div className="Block-Icon">


                    {typeof props.link === 'function' &&
                        <Link className="linkIcon" to="#" onClick={props.link}>
                            {props.linkIcon && <i className={iconClass}></i>}
                            {props.linkText && <span>{props.linkText}</span>}
                        </Link>
                    }
                    {typeof props.link === 'string' &&
                        <Link className="linkIcon" to={props.link}>
                            {props.linkIcon && <i className={iconClass}></i>}
                            {props.linkText && <span>{props.linkText}</span>}
                        </Link>
                    }


                    {typeof props.link2 === 'function' &&
                        <Link className="linkIcon" to="#" onClick={props.link2}>
                            {props.link2Icon && <i className={iconClass}></i>}
                            {props.link2Text && <span>{props.link2Text}</span>}
                        </Link>
                    }
                    {typeof props.link2 === 'string' &&
                        <Link className="linkIcon" to={props.link2}>
                            {props.link2Icon && <i className={iconClass}></i>}
                            {props.link2Text && <span>{props.link2Text}</span>}
                        </Link>
                    }
                </div>

            </div>
        </div>
    );
};

WhiteBoxContent.propTypes = {
    title: PropTypes.string.isRequired,
    titleColor : PropTypes.string,
    message: PropTypes.string.isRequired,
    link: PropTypes.oneOfType([PropTypes.string,PropTypes.func]),
    linkIcon: PropTypes.string,
    linkText: PropTypes.string,
    link2: PropTypes.oneOfType([PropTypes.string,PropTypes.func]),
    link2Icon: PropTypes.string,
    link2Text: PropTypes.string,
    isTitleH1: PropTypes.bool
};

WhiteBoxContent.defaultProps = {
    title: "title",
    titleColor : "redTitle",
    message: "message",
    link: null,
    linkIcon: "plus",  // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    linkText:null,
    link2: null,
    link2Icon: "plus",  // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    link2Text:null,
    isTitleH1:false
};


export default WhiteBoxContent;
