import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import PairingPageContent from "../contents/Pages/PairingPage/PairingPageContent";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import {withRouter} from "react-router-dom"
import {injectIntl, useIntl} from "react-intl";

const PairingPageLayout = ({match, locale}) => {

    const intl = useIntl();

    return(
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <PairingPageContent locale={intl.locale}/>
            </main>
            <Footer/>
        </>
    );
}


export default withRouter(injectIntl(PairingPageLayout));
