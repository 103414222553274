import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowReferencesTable extends Component {

    columns = [
        //{path:"id",label:this.props.intl.formatMessage({id: "table.references.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.references.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.references.fr.short"})},
        {path:"en",label:this.props.intl.formatMessage({id: "table.references.en.short"})},
        {key:"edit", content: reference => (
                <Link to={`./manage-references/${reference.id}`} >{this.props.intl.formatMessage({id: "table.references.editLink"})}</Link>
            )}
    ];

    render() {
        const {referencesList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {referencesList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowReferencesTable);
