import React, {useState} from "react";
import PropTypes from "prop-types";
import "./PopperWindow.css";
import {Popper} from "@mui/material";
import {Button} from "react-bootstrap";
import Draggable from "react-draggable";
import {Resizable} from "re-resizable";
import {getWindowWidthAndHeight} from "../../../utility/javascriptTools.mjs";
import {ArrowsFullscreen, ChevronBarLeft, ChevronBarRight, XLg} from "react-bootstrap-icons";

const PopperWindow = ({  showValue,
                          closeWindow,
                          modalTitle,
                          modalContent,
                          saveText,
                          saveColor,
                          cancelText,
                          cancelColor,
                          width,
                          height,
                          infoBox,
                          isForm,
                      }) => {

    const [moveTitleClass, setMoveTitleClass] = useState("PopperWindow-Title");
    const contentIsComponent = typeof modalContent === "object";

    const [popperPosition, setPopperPosition] = useState({x:(width / 2) - (width / 2), y:(height/2)-(height/2) });
    const [popperSize, setPopperSize] = useState({width: width, height:height});
    const [isFullScreen, setIsFullScreen] = useState(true);
    const { windowWidth, windowHeight } = getWindowWidthAndHeight();

    const handleMouseDownOnTitle = () => {
        setMoveTitleClass("PopperWindow-Title Moving")
    }

    const handleMouseUpOnTitle = () => {
        setMoveTitleClass("PopperWindow-Title")
    }

    const style = {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        border: "solid 1px #ddd",
        background: "#f0f0f0",
    };

    const alignPopperLeft = () =>{
        setPopperPosition({x:0, y:0});
        setPopperSize({ width:windowWidth/2, height:windowHeight });
    }

    const alignPopperRight = () =>{
        setPopperPosition({x:windowWidth/2, y:0});
        setPopperSize({ width:windowWidth/2, height:windowHeight });
    }

    const handleDrag = (e, { x, y }) => {
        setPopperPosition({ x, y });
    };

    const toggleFullScreen = () => {
        if (isFullScreen) {
            const fullWindowSize = getWindowWidthAndHeight();
            setPopperPosition({ x: 0, y: 0 }); // Reset position to x:0 y:0
            setPopperSize({width:fullWindowSize.windowWidth, height: fullWindowSize.windowHeight});
            setIsFullScreen(!isFullScreen);
            console.log("Fullscreen disabled")
        } else {
            setIsFullScreen(!isFullScreen);
            setPopperSize({ width:1000, height:720 });
            console.log("Fullscreen enabled")
        }
    };

    return (
        <>
            <Draggable
                handle=".PopperWindow-Title"
                defaultPosition={null}
                position={popperPosition}
                onDrag={handleDrag}
                //bounds={{left: 0, top: 0, right: 0, bottom: 0}}
                // positionOffset={{ x: '-50%', y: '-50%' }}
                bounds="html"
                defaultClassName="PopperWindowComponent"
            >
                <Popper
                    // fullScreen
                    open={showValue}
                >

                    <Resizable
                        size={popperSize}
                        onResizeStop={(e,direction,ref,d)=>{setPopperSize({
                            width: popperSize.width + d.width,
                            height: popperSize.height + d.height,
                        })}}
                        style={style}
                        defaultSize={{width: width, height: height}}
                        minWidth={200}
                        minHeight={200}
                        enable={{ top:false, right:true, bottom:true, left:false, topRight:false, bottomRight:true, bottomLeft:false, topLeft:false }}
                    >
                        <div className="PopperWindow">

                            <div className={moveTitleClass} onMouseDown={handleMouseDownOnTitle} onMouseUp={handleMouseUpOnTitle}>
                                <div className="PopperWindowTitleText" dangerouslySetInnerHTML={{ __html: modalTitle }} />
                                <div className="PopperWindowOptions">
                                    <button onClick={alignPopperLeft}><ChevronBarLeft/></button>
                                    <button onClick={alignPopperRight}><ChevronBarRight/></button>
                                    <button onClick={toggleFullScreen}><ArrowsFullscreen/></button>
                                    <button style={{backgroundColor:"red"}} onClick={() => closeWindow()}><XLg/></button>
                                </div>
                            </div>

                            <div className="PopperWindow-Content">
                                {contentIsComponent && modalContent}
                                {!contentIsComponent && (
                                    <div dangerouslySetInnerHTML={{ __html: modalContent }} />
                                )}
                            </div>
                            {!isForm && (
                            <div className="PopperWindow-Actions">

                                    <div>
                                        {!infoBox && (
                                            <Button variant={cancelColor} onClick={() => closeWindow(false)}>
                                                {cancelText}
                                            </Button>
                                        )}
                                        <Button variant={saveColor} onClick={() => closeWindow(true)}>
                                            {saveText}
                                        </Button>
                                    </div>

                            </div>
                            )}
                        </div>
                    </Resizable>
                </Popper>
            </Draggable>
        </>
    );
}


PopperWindow.propTypes = {
    modalTitle: PropTypes.string,
    modalContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    saveText: PropTypes.string,
    saveColor: PropTypes.string, //primary,secondary,success,warning,danger,light,link
    cancelText: PropTypes.string,
    cancelColor: PropTypes.string, //primary,secondary,success,warning,danger,light,link
    showValue: PropTypes.bool.isRequired,
    width: PropTypes.number,
    height: PropTypes.number,
    closeWindow: PropTypes.func.isRequired,
    isForm: PropTypes.bool,
};

PopperWindow.defaultProps = {
    modalTitle: "Title | dialogTitle",
    modalContent: "Content | dialogContent",
    saveText: "Save | saveText",
    saveColor: "success",
    cancelText: "Cancel | cancelText",
    cancelColor: "secondary",
    width: 800,
    height: 600,
    isForm: false,
};

export default PopperWindow;