import React, {Component} from 'react';
import {getPreferenceByID} from "../../../../services/preferencesService"
import {Redirect, withRouter} from 'react-router-dom';
import LoadingComponent from "../../common/loadingComponent";
import PreferenceForm from "./PreferenceForm";

class LoadPreferenceForm extends Component {

    _isMounted = false;

    state = {
        RedirectToLoginPage:false,
        isLoading : false,
        userToken : this.props.user,
        preference : {},
        locale : this.props.locale
    };


    componentDidMount() {
        this._isMounted = true;
        if (this.props.id !== undefined) {
            this.onLoadPreference();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadPreference = async () =>{
        try {
            this.setState({isLoading:true});
            const {data: preference} = await getPreferenceByID(this.props.id);
            if (this._isMounted){
                this.setState({
                    preference,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }


    renderPage = () => {
        return (
          <>
              <PreferenceForm
                  isAdminForm = {(this.props.id)?true:false}
                  locale = {this.props.locale}
                  preference = {this.state.preference}
              />
          </>
        );
    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        );
    }
}

export default withRouter(LoadPreferenceForm);
