import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowFormationsTable extends Component {

    columns = [
        //{path:"id",label:this.props.intl.formatMessage({id: "table.formations.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.formations.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.formations.fr.short"})},
        {path:"nbr_inscrits",label:this.props.intl.formatMessage({id: "table.formations.nbr_inscrits"})},
        {path:"places",label:this.props.intl.formatMessage({id: "table.formations.places"})},
        {path:"date",label:this.props.intl.formatMessage({id: "table.formations.date"})},
        {path:"time",label:this.props.intl.formatMessage({id: "table.formations.time"})},
        {key:"edit", content: formation => (
                <Link to={`./manage-formations/${formation.id}`} >{this.props.intl.formatMessage({id: "table.formations.editLink"})}</Link>
            )}
    ];

    render() {
        const {formationsList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {formationsList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowFormationsTable);
