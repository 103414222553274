import React, {Component} from 'react';
import _ from "lodash";
import moment from 'moment';
import parse from 'html-react-parser';
import 'moment/locale/fr';
import {FormattedMessage} from "react-intl";
import {Link} from "react-router-dom";
import "./CRUDTableBody.css"
import {orderObjectByProperty} from "../../../../../../utility/arrayTools";


class CRUDTableBody extends Component {

    renderCell = (item, column) => {
        // console.log("item is: "+ JSON.stringify(item))
        // console.log("Column is: "+ JSON.stringify(column))
        // console.log("-----------------------------------------")


        const language = this.props.locale;
        if (column.content) return column.content(item);

        let retValue = _.get(item, column.path);

        let retValueIsDate = moment(retValue, moment.ISO_8601,true).isValid();

        if (retValueIsDate) {
            const lang = typeof language === "string" ? language : language.locale;
            const now = moment();
            let daysAgo = now.diff(retValue, "days");
            if (daysAgo < 0){
                daysAgo = "invalid";
            }
            else{
                daysAgo = (daysAgo > 7)?7:daysAgo;
            }



            if(retValue.length === 10){
                return moment(retValue).locale(lang).format('ll');
            }
            else{
                return  <>
                            <div><img className="ageIcons" alt="ageIcon" src={require("./icons/"+ daysAgo + ".png")}/></div>
                            {moment(retValue).locale(lang).format('ll')}
                        </>;
            }
        }

        if (column.path === "inChargeOfClientsNames"){
            const inChargeOfClientsID = item.inChargeOfClientsID;
            const arrayOfNames = item.inChargeOfClientsNames?.split("<br/>");
            let retVal = [];

            if (inChargeOfClientsID?.length){
                inChargeOfClientsID.forEach(item => {
                    const nameOfClient = arrayOfNames[inChargeOfClientsID.indexOf(item)];
                    retVal.push(
                        <div className="inChargeOfLink" key={inChargeOfClientsID.indexOf(item)}>
                            <span title="Non confirmé">{nameOfClient}</span>
                            <br/>
                        </div>
                    )
                });
            }
            return(retVal);
        }

        if (column.path === "state"){
            switch (item.state){    //Modifier aussi components\contents\Forms\Sponsor\SponsorForm.jsx
                                    //               components\tableLayouts\UserTableFrame100.jsx
                case 1:
                    return "Nouvelle candidature";
                case 2:
                    return "Candidature approuvée";
                case 3:
                    return "Candidat désisté";
                case 4:
                    return "Candidat non formé";
                case 5:
                    return "Candidat refusé";
                case 6:
                    return "Candidat exclu";
                case 7:
                    return "À vérifier";
                case 10:
                    return "Confirmé";
                default:
                    return "";
            }
        }



        else return retValue
    };

    createKey = (item, column) => {
        return item.id + (column.path || column.key);
    };

    returnCellClass = state => {
        switch (state){
            case 1:
                return "candidateNew";
            case 2:
                return "candidateApproved";
            case 3:
                return "candidateDesisted";
            case 4:
                return "candidateNoFormation";
            case 5:
                return "candidateRefused";
            case 7:
                return "candidateToBeChecked";
            case 10:
                return "candidateConfirmed";
            default:
                return "";
        }
    }

    renderData = (dataElements, columns) => {
        //console.log(dataElements)
        if(Array.isArray(dataElements)){

            let orderedObject = orderObjectByProperty(dataElements, this.props.sortColumn.path, this.props.sortColumn.order)
            //  let objectProperty = this.props.sortColumn.path;
            //  console.log(objectProperty)
            // let orderedObject = _.sortBy(dataElements,
            //     [function(o) { return o[objectProperty]; }]);

            return(
                orderedObject.map(item => (

                        <tr key={item.id} className={this.returnCellClass(item.state)}>

                        {this.props.select?
                            <td><input id={"select" + item.id} name="userElements" value={item.id} type="checkbox" onChange={this.props.onSelectElement}/></td>
                            :
                            null
                        }

                        {columns.map(column => (
                            <td key={this.createKey(item, column)}>
                                {
                                    (typeof(this.renderCell(item, column)) == 'string')?
                                        parse(this.renderCell(item, column))
                                        :
                                        this.renderCell(item, column)
                                }
                            </td>
                        ))}
                    </tr>
                ))
            )
        }
        else{
            return(
                <tr>
                    <td colSpan={7}><FormattedMessage id="api.error.access"/></td>
                </tr>
            );

        }
    }

    renderLoadingData = () => {
        return (
            <tr>
                <td valign="top" colSpan="7" className="dataTables_empty"><FormattedMessage id="api.loading" tagName="div" /></td>
            </tr>
        )
    }

    render(){
        const { data, columns, isLoading } = this.props;
        return (
            <>
                <tbody>
                    {isLoading ? this.renderLoadingData() : this.renderData(data,columns)}
                </tbody>
            </>
        );
    }
}

export default CRUDTableBody;
