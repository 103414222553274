import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import {UpdateCountry, CreateNewCountry} from "../../../../services/countryService";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class CountryForm extends Form {

    state = {
        locale : this.props.locale,
        worldRegionsList : this.createSelectItemsList(this.props.worldRegionsList),

        errors:{}
    }

    constructor(props) {
        super(props);
        const currentCountry = this.props.country;
        this.state.sendButtonDisabled = false;

        this.state.data = {
            id : currentCountry.id,
            active : (currentCountry.active === 1)?true:false,
            fr : currentCountry.fr,
            en : currentCountry.en,
            worldRegion_id : currentCountry.worldRegion_id,

            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        active : Joi.boolean(),
        fr: Joi.string().required(),
        en : Joi.string().required(),
        worldRegion_id: Joi.number()
    };

    doSubmit = async e => {
        this.setState({sendButtonDisabled:true})
        let countryObject = {...this.state.data};

        delete countryObject.errors;
        if(countryObject.id){
            try{
                await UpdateCountry(countryObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }
        else{
            try{
                await CreateNewCountry(countryObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }

    };


    render() {
        //console.log(JSON.stringify(this.state.data));
        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="countries.form.h1" /> {this.props.country.id}</h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit}>
                        <h2><FormattedMessage id="countries.form.h2" /></h2>
                            {this.renderInput("fr", "countries.form.fr")}
                            {this.renderInput("en", "countries.form.en")}
                            {this.renderInputDropDown("worldRegion_id", "countries.form.WorldRegionID", this.state.worldRegionsList)}
                            {/*{this.renderInput("worldRegion_id", "countries.form.WorldRegionID")}*/}
                            {this.renderCheckBox("active", "countries.form.active")}
                        <button disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                            {this.state.sendButtonDisabled ? <FormattedMessage id="countries.form.savingChanges"/> : <FormattedMessage id="countries.form.saveChanges"/>}
                        </button>

                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(CountryForm));
