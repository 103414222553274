import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import React from "react";
import FormationsTableFrame100 from "../tableLayouts/FormationsTableFrame100";

const FormationsListLayout = (props) => {
    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <FormationsTableFrame100 locale={props.locale}/>
            </main>
            <Footer/>
        </>
    );
}

export default withRouter(injectIntl(FormationsListLayout));
