import React, {Component} from 'react';

class JQueryDatePicker extends Component {

    constructor(props) {
        super(props);
        this.datepickerContainer = React.createRef();
    }

    componentDidMount() {
        const locale = this.props.locale?this.props.locale:"fr";

        const options = window.$.extend({},
            window.$.datepicker.regional[locale], {
                dateFormat : "yy-mm-dd",
                onSelect: this.props.onDateChange,
                onChange: this.props.onDateChange,

                minDate : this.props.minDate,
                maxDate: this.props.maxDate,
                yearRange : this.props.yearRange,

                changeMonth : true,
                changeYear : true,
                name : this.props.name
            }
        );
        window.$(this.datepickerContainer.current).datepicker(options).val(this.props.value?this.props.value:null);

    }
    componentWillUnmount() {
        window.$(this.datepickerContainer.current).datepicker("destroy");
    }

    render() {
        return <input className="DatePicker form-control" data-date-language="fr" readOnly="readonly" name={this.props.name} id={this.props.id} ref={this.datepickerContainer} />;
    }
}


// JQueryDatePicker.propTypes = {
//     onDateChange : PropTypes.func.isRequired,
//     locale : PropTypes.string.isRequired,
//     minDate : [PropTypes.string.isRequired, PropTypes.number.isRequired],
//     maxDate : [PropTypes.string.isRequired, PropTypes.number.isRequired],
//     yearRange : PropTypes.string.isRequired,
//     name : PropTypes.string.isRequired,
//     id : PropTypes.string.isRequired
// }


export default JQueryDatePicker;