import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import React from "react";
import UserTableFrame100 from "../tableLayouts/UserTableFrame100";

const UsersListLayout = (props) => {
    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <UserTableFrame100 locale={props.locale} userType={props.userType}/>
            </main>
            <Footer/>
        </>
    );
}

export default withRouter(injectIntl(UsersListLayout));
