import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import {UpdateInterest, CreateNewInterest} from "../../../../services/interestsService";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class InterestForm extends Form {

    state = {
        locale : this.props.locale,
        interest : this.props.interest,

        errors:{}
    }

    constructor(props) {
        super(props);
        const currentInterest = this.props.interest;
        this.state.sendButtonDisabled = false;

        this.state.data = {
            id : currentInterest.id,
            active : (currentInterest.active === 1)?true:false,
            fr : currentInterest.fr,
            en : currentInterest.en,

            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        active : Joi.boolean(),
        fr: Joi.string().required(),
        en : Joi.string().required()
    };

    doSubmit = async e => {
        this.setState({sendButtonDisabled:true})
        let interestObject = {...this.state.data};

        delete interestObject.errors;
        if(interestObject.id){
            try{
                await UpdateInterest(interestObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }
        else{
            try{
                await CreateNewInterest(interestObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }

    };


    render() {
        //console.log(JSON.stringify(this.state.data));
        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="interest.form.h1" /> {this.props.interest.id}</h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit}>
                        <h2><FormattedMessage id="interest.form.h2" /></h2>
                            {this.renderInput("fr", "interest.form.fr")}
                            {this.renderInput("en", "interest.form.en")}
                            {this.renderCheckBox("active", "interest.form.active")}
                        <button disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                            {this.state.sendButtonDisabled ? <FormattedMessage id="interest.form.savingChanges"/> : <FormattedMessage id="interest.form.saveChanges"/>}
                        </button>

                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(InterestForm));
