import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";
import LoadReferenceForm from "../contents/Forms/References/LoadReferenceForm";

const ClientFormLayout = ({match, locale}) => {

    return(
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <LoadReferenceForm locale={locale} id={match.params.id}/>
            </main>
            <Footer/>
        </>
    );
}


export default withRouter(injectIntl(ClientFormLayout));
