import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/interests";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getInterestsList(language){
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}
    return httpService.get(apiEndpoint + "?locale=" +lang, {headers: {"x-auth-token" : jwt}})
}

export function getAllInterests(filterString){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/?filterString=" + filterString + "&showInactive=1", {headers: {"x-auth-token" : jwt}})
}

export function getInterestByID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
}

export function UpdateInterest(interestObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = interestObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, interestObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function CreateNewInterest(interestObject){
    if(interestObject.id !== undefined)
        delete interestObject.id

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}`, interestObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}