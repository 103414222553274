import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/idulnotes";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getIdulNotes(idul){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/" +idul, {headers: {"x-auth-token" : jwt}})
}

export function UpdateIdulNotes(idulNotesObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const idul = idulNotesObject.idul;
        return httpService.put(`${apiEndpoint}/${idul}`, idulNotesObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

