import React from "react";

export const TiptapFooter = ({ editor, limit }) => {

  return (
    <>
      <div className="character-count">
        {editor.storage.characterCount.characters()}/{limit} caractères
        <br />
        {editor.storage.characterCount.words()} mots
      </div>
    </>
  );
};


export default TiptapFooter;