import React from "react";
import CRUDTableHeader from "./CRUDTableHeader";
import CRUDTableBody from "./CRUDTableBody";

const CRUDTable = ({ columns, sortColumn, onSort, data, isLoading, locale, select, onSelectElement, handleMatchButtonClick }) => {
    return (
        <table className="table table-striped table-hover dataTable no-footer">
            <CRUDTableHeader columns={columns} sortColumn={sortColumn} onSort={onSort} locale={locale} select={select} />
            <CRUDTableBody columns={columns} sortColumn={sortColumn} data={data} isLoading={isLoading} locale={locale} select={select} onSelectElement={onSelectElement} handleMatchButtonClick={handleMatchButtonClick}/>
        </table>
    );
};

export default CRUDTable;
