import {Redirect, Route, Switch} from 'react-router-dom';
import React, {Component} from "react";
import HomePageLayout from "./components/pageLayouts/HomePageLayout";
import AdminPageLayout from "./components/pageLayouts/AdminPageLayout";
import LoginPage from "./components/pageLayouts/LoginPageLayout";
import Logout from "./components/utility/logout";
import TemplateNotFound from "./components/error_pages/TemplatedNotFound";
import authService from "./services/authService";
import ClientFormLayout from "./components/pageLayouts/ClientFormLayout";
import SponsorFormLayout from "./components/pageLayouts/SponsorFormLayout";
import PairingPageLayout from "./components/pageLayouts/PairingPageLayout";
import UsersListLayout from "./components/pageLayouts/UsersListLayout";
import InterestsListLayout from "./components/pageLayouts/InterestsListLayout";
import InterestFormLayout from "./components/pageLayouts/InterestFormLayout";
import PreferencesListLayout from "./components/pageLayouts/PreferencesListLayout";
import PreferenceFormLayout from "./components/pageLayouts/PreferenceFormLayout";
import ReferencesListLayout from "./components/pageLayouts/ReferencesListLayout";
import ReferenceFormLayout from "./components/pageLayouts/ReferenceFormLayout";
import SessionsListLayout from "./components/pageLayouts/SessionsListLayout";
import SessionsFormLayout from "./components/pageLayouts/SessionsFormLayout";
import FormationsListLayout from "./components/pageLayouts/FormationsListLayout";
import FormationFormLayout from "./components/pageLayouts/FormationFormLayout";
import WorldRegionsListLayout from "./components/pageLayouts/WorldRegionsListLayout";
import WorldRegionFormLayout from "./components/pageLayouts/WorldRegionFormLayout";
import ConfigsPageLayout from "./components/pageLayouts/ConfigsPageLayout";
import CountriesListLayout from "./components/pageLayouts/CountriesListLayout";
import CountryFormLayout from "./components/pageLayouts/CountryFormLayout";
import MatchClientPageLayout from "./components/pageLayouts/MatchClientPageLayout";
import MatchSponsorPageLayout from "./components/pageLayouts/MatchSponsorPageLayout";
import DocumentsListLayout from "./components/pageLayouts/DocumentsListLayout";
import MailsPageLayout from "./components/pageLayouts/MailsPageLayout";
import MyPairingLayout from "./components/pageLayouts/MyPairingLayout";

class RouteConfig extends Component {

    state = {
        locale:this.props.locale,
    };

    constructor(props) {
        super(props);
        const userToken = authService.getCurrentUserToken();
        this.state.userToken = userToken;
    }

    componentDidMount() {
        //console.log("RouteConfig componentDidMount")
    }

    isLoggedIn = () =>  {
        if (this.state.userToken) {
            return true;
        }
        return false;
    }

    isAdmin = () => {
        if (this.state.userToken && this.state.userToken.isAdmin){
            return true;
        }
        return false;
    }

    render() {

        return (
            <>
                <Switch>
                    <Route exact path="/" render = {(props)=> (<HomePageLayout {...props} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/login" component={LoginPage} />

                    <Route exact path="/:locale(en|fr)/homepage" render={(props)=> (<HomePageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />



                    <Route exact path="/:locale(en|fr)/documents" render={(props)=> (<DocumentsListLayout {...props} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/my-pairing" render={(props)=> (<MyPairingLayout {...props} locale={this.state.locale} />) } />

                    <Route exact path="/:locale(en|fr)/admin" render={(props)=> (<AdminPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs" render={(props)=> (<AdminPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-mails" render={(props)=> (<MailsPageLayout {...props} locale={this.state.locale} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-configs" render={(props)=> (<ConfigsPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-countries" render={(props)=> (<CountriesListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-countries/new" onEnter={this.isAdmin} render={(props)=> (<CountryFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-countries/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<CountryFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-preferences" render={(props)=> (<PreferencesListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-preferences/new" onEnter={this.isAdmin} render={(props)=> (<PreferenceFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-preferences/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<PreferenceFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-references" render={(props)=> (<ReferencesListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-references/new" onEnter={this.isAdmin} render={(props)=> (<ReferenceFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-references/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<ReferenceFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-interests" render={(props)=> (<InterestsListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-interests/new" onEnter={this.isAdmin} render={(props)=> (<InterestFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-interests/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<InterestFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-sessions" render={(props)=> (<SessionsListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-sessions/new" onEnter={this.isAdmin} render={(props)=> (<SessionsFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-sessions/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<SessionsFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-formations" render={(props)=> (<FormationsListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-formations/new" onEnter={this.isAdmin} render={(props)=> (<FormationFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-formations/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<FormationFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/configs/manage-worldregions" render={(props)=> (<WorldRegionsListLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-worldregions/new" onEnter={this.isAdmin} render={(props)=> (<WorldRegionFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/configs/manage-worldregions/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<WorldRegionFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />


                    <Route exact path="/:locale(en|fr)/admin/usermanagement" render={(props)=> (<AdminPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/forms/client" onEnter={this.isLoggedIn} render={(props)=> (<ClientFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/forms/sponsor" onEnter={this.isLoggedIn} render={(props)=> (<SponsorFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />

                    <Route exact path="/:locale(en|fr)/admin/usermanagement/clients/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<ClientFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact key="manage-clients" path="/:locale(en|fr)/admin/usermanagement/clientlist"
                           render={(props)=> (
                                this.isAdmin()?
                                    (<UsersListLayout {...props} userType="clients" locale={this.state.locale} user={this.state.userToken} />)
                                    :
                                    (<LoginPage/>)
                            )}
                    />

                    <Route exact path="/:locale(en|fr)/admin/usermanagement/sponsors/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<SponsorFormLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact key="manage-sponsors" path="/:locale(en|fr)/admin/usermanagement/sponsorlist"
                           render={(props)=> (
                               this.isAdmin()?
                                   (<UsersListLayout {...props} userType="sponsors" locale={this.state.locale} user={this.state.userToken} />)
                                   :
                                   (<LoginPage/>)
                           )}
                    />


                    <Route exact path="/:locale(en|fr)/admin/usermanagement/clients/not-found" component={TemplateNotFound}/>
                    <Route exact path="/:locale(en|fr)/admin/usermanagement/sponsors/not-found" component={TemplateNotFound}/>


                    <Route exact path="/:locale(en|fr)/admin/usermanagement/match/clients/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<MatchClientPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/usermanagement/match/sponsors/:id(\d+)" onEnter={this.isAdmin} render={(props)=> (<MatchSponsorPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />
                    <Route exact path="/:locale(en|fr)/admin/usermanagement/pairing-list" onEnter={this.isAdmin} render={(props)=> (<PairingPageLayout {...props} locale={this.state.locale} user={this.state.userToken} />) } />


                    <Route exact path="/logout" component={Logout} />

                    <Route path="/not-found" component={TemplateNotFound} />

                    <Redirect to="/not-found" />
                </Switch>
            </>
        );
    }
}

export default RouteConfig;
