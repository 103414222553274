import React, {Component} from 'react';
import httpService from "../../../../services/httpService";
import {getCountryList} from "../../../../services/countryService"
import {getFacultyList} from "../../../../services/facultyService"
import {getInterestsList} from "../../../../services/interestsService"
import {getLanguagesList} from "../../../../services/languagesService"
import {getPreferencesList} from "../../../../services/preferencesService"
import {getReferencesList} from "../../../../services/referencesService"
import {getSessionsList} from "../../../../services/sessionsService"
import {getIdulNotes} from "../../../../services/idulNotesService";
import {getCurrentMatchForID} from "../../../../services/matchUsersService";
import {getClientByID, getCurrentClient} from "../../../../services/clientService"
import ClientForm from "./ClientForm";
import {Redirect, withRouter} from 'react-router-dom';
import LoadingComponent from "../../common/loadingComponent";
import authService from "../../../../services/authService";

class LoadClientForm extends Component {

    _isMounted = false;

    state = {
        RedirectToLoginPage:false,
        isLoading : false,
        countryList : {},
        userToken : this.props.user,
        currentClient : {},
        selectedSponsor : null,
        facultyList : {},
        interestsList : {},
        languagesList : {},
        preferencesList : {},
        referencesList : {},
        sessionsList : {},
        idulNotes : null,
        locale : this.props.locale
    };


    componentDidMount() {
        this._isMounted = true;
        this.onLoadClient();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onLoadClient = async () =>{
        try {
            this.setState({isLoading:true});
            const language = this.props.locale;

            const [
                {data: countryList},
                {data: currentClient},
                {data: selectedSponsor},
                {data: facultyList},
                {data: interestsList},
                {data: languagesList},
                {data: preferencesList},
                {data: referencesList},
                {data: sessionsList}
                ] = await httpService.all([
                        getCountryList(language),
                        (this.props.id)?getClientByID(this.props.id):getCurrentClient(),
                        (this.props.id)?getCurrentMatchForID(this.props.id): {},
                        getFacultyList(language),
                        getInterestsList(language),
                        getLanguagesList(language),
                        getPreferencesList(language, 1),
                        getReferencesList(language),
                        getSessionsList(language, 1)
                    ]);

            const user = authService.getCurrentUserToken();
            let idulNotes = null;
            if(user.isAdmin){
                const [{data: idulNotesFetched}] = await httpService.all([getIdulNotes(currentClient.idul)]);
                idulNotes = idulNotesFetched;
            }


            if (this._isMounted){
                this.setState({
                    countryList,
                    currentClient,
                    idulNotes,
                    selectedSponsor,
                    facultyList,
                    interestsList,
                    languagesList,
                    preferencesList,
                    referencesList,
                    sessionsList,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }


    renderPage = () => {
        return (
          <>
              <ClientForm
                  isAdminForm = {(this.props.id)?true:false}
                  locale = {this.props.locale}
                  usersList = {this.props.usersList}
                  setUsersList = {this.props.setUsersList}
                  countryList = {this.state.countryList}
                  idulNotes = {this.state.idulNotes}
                  currentClient = {this.state.currentClient}
                  selectedSponsor = {this.state.selectedSponsor}
                  facultyList = {this.state.facultyList}
                  interestsList = {this.state.interestsList}
                  languagesList = {this.state.languagesList}
                  preferencesList = {this.state.preferencesList}
                  referencesList = {this.state.referencesList}
                  sessionsList = {this.state.sessionsList}
                  setEditClientWindowState = {this.props.setEditClientWindowState}
              />
          </>
        );
    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        );
    }
}

export default withRouter(LoadClientForm);
