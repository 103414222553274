import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/sessions";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getSessionsList(language, showItemsFor = 0, showInactive = 0){
    //showItemsFor : 0 = All , 1 = Clients, 2 = Sponsors
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}

    return httpService.get(apiEndpoint + `?locale=${lang}&showItemsFor=${showItemsFor}&showInactive=${showInactive}`, {headers: {"x-auth-token" : jwt}})
}

export function getAllSessions(filterString){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/?filterString=" + filterString + "&showInactive=1", {headers: {"x-auth-token" : jwt}})
}

export function getSessionByID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
}

export function UpdateSession(sessionObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = sessionObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, sessionObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function CreateNewSession(sessionObject){
    if(sessionObject.id !== undefined)
        delete sessionObject.id

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}`, sessionObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}