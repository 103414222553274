import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/matching";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getMatchAllUsers(){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint, {headers: {"x-auth-token" : jwt}})
}

export function getPossibleMatchesForID(usertype, id, limit, filter, availabilityDate,showMatchedUserStatus){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${usertype}/${id}?limit=${limit}&filter=${filter}&availability=${availabilityDate}&showMatchedUserStatus=${showMatchedUserStatus}`,{headers: {"x-auth-token" : jwt}})
}

export function getCurrentMatchForID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`,{headers: {"x-auth-token" : jwt}})
}

export function getMyMatchConfirmationStatus(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/mymatchconfirmationstatus/`,{headers: {"x-auth-token" : jwt}})
    } catch (ex){
        return null;
    }
}

export function getMyMatchData(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/mymatchdata/`,{headers: {"x-auth-token" : jwt}})
    } catch (ex){
        return null;
    }
}

export function getContactRequestAttempt(clientID){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/contactrequestattempt/${clientID}`,{headers: {"x-auth-token" : jwt}})
    } catch (ex){
        return null;
    }
}

export function saveMatch(matchObject){
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}/match`, matchObject, {headers: {"x-auth-token" : jwt}})
    } catch (ex) {
        return null;
    }
}

export function deleteMatchFromClientID(id){
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.delete(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
    } catch (ex) {
        return null;
    }
}

export function confirmMatch(clientObject, template, mail){
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}/confirm`, {clientObject, template, mail}, {headers: {"x-auth-token" : jwt}})
    } catch (ex) {
        return null;
    }
}

export function updateContactRequest(userType, contactType, userID){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}/updateContactRequest`, {userType, contactType, userID}, {headers: {"x-auth-token" : jwt}})
    }catch (ex) {
        return null;
    }
}

export function confirmStatus(clientID, status){
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.put(`${apiEndpoint}/confirmStatus`, {clientID, status}, {headers: {"x-auth-token" : jwt}})
    } catch (ex) {
        return null;
    }
}