import React from "react";
import {Link} from "react-router-dom";
import {useIntl} from "react-intl";
import Contexts from "../../LangContext";
import './LanguageLink.css';

const LanguageLink = (mobile) => {
    const intl = useIntl();
    const { switchToEnglish, switchToFrench } = React.useContext(Contexts);
    const currentLanguage = intl.locale;

    if(mobile?.mobile === true) {
        switch (currentLanguage) {
            case 'fr': return <a href="/en/homepage" className="header-language-switcher-trigger" hrefLang="en-CA" onClick={() => switchToEnglish}>EN</a>;
            case 'en': return <a href="/fr/homepage" className="header-language-switcher-trigger" hrefLang="fr-CA" onClick={() => switchToFrench}>FR</a>;
            default:  return <a href="/en/homepage" className="header-language-switcher-trigger" hrefLang="en-CA" onClick={() => switchToEnglish}>EN</a>;
        }
    } else {
        switch (currentLanguage) {
            case 'fr': return <Link to="/en/homepage" className="header-language-switcher-trigger" hrefLang="en-CA" onClick={switchToEnglish}>EN</Link>;
            case 'en': return <Link to="/fr/homepage" className="header-language-switcher-trigger" hrefLang="fr-CA" onClick={switchToFrench}>FR</Link>;
            default:  return <Link to="/en/homepage" className="header-language-switcher-trigger" hrefLang="en-CA" onClick={switchToEnglish}>EN</Link>;
        }
    }
};

export default LanguageLink;
