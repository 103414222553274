import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";
import {UpdateReference, CreateNewReference} from "../../../../services/referencesService";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class ReferenceForm extends Form {

    state = {
        locale : this.props.locale,
        interest : this.props.interest,

        errors:{}
    }

    constructor(props) {
        super(props);
        const currentReference = this.props.reference;
        this.state.sendButtonDisabled = false;

        this.state.data = {
            id : currentReference.id,
            active : (currentReference.active === 1)?true:false,
            fr : currentReference.fr,
            en : currentReference.en,

            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        active : Joi.boolean(),
        fr: Joi.string().required(),
        en : Joi.string().required(),
    };

    doSubmit = async e => {
        this.setState({sendButtonDisabled:true})
        let referenceObject = {...this.state.data};

        delete referenceObject.errors;
        if(referenceObject.id){
            try{
                await UpdateReference(referenceObject);
              //this.props.history.push(`/${this.state.locale}/home`);
              this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }
        else{
            try{
                await CreateNewReference(referenceObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }
    };


    render() {
        //console.log(JSON.stringify(this.state.data));
        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="reference.form.h1" /> {this.props.reference.id}</h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit}>
                        <h2><FormattedMessage id="reference.form.h2" /></h2>
                            {this.renderInput("fr", "reference.form.fr")}
                            {this.renderInput("en", "reference.form.en")}
                            {this.renderCheckBox("active", "reference.form.active")}
                        <button disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                            {this.state.sendButtonDisabled ? <FormattedMessage id="reference.form.savingChanges"/> : <FormattedMessage id="reference.form.saveChanges"/>}
                        </button>

                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(ReferenceForm));
