import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./BlackBoxContent.css";
import {Link} from "react-router-dom";

const BlackBoxContent = props => {
    // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    const iconClass = "bi bi-" + props.linkIcon;

    return (
        <div className="BlackBoxContent">
            <div className="Block-Content">
                {props.isTitleH1 && <h1 className={"title " + props.titleColor}>{props.title}</h1> || <h2 className={"title " + props.titleColor}>{props.title}</h2>}
                <div className="content">
                    <div dangerouslySetInnerHTML={{__html: props.message}} />
                </div>
                {props.link &&
                    <div className="Block-Icon">
                        <Link className="linkIcon" to={props.link}>
                            {props.linkIcon && <i className={iconClass}></i>}
                            {props.linkText && <span>{props.linkText}</span>}
                        </Link>
                    </div>
                }
            </div>
        </div>
    );
};

BlackBoxContent.propTypes = {
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired,
    link: PropTypes.string,
    linkIcon: PropTypes.string.isRequired,
    linkText: PropTypes.string,
    isTitleH1: PropTypes.bool
};

BlackBoxContent.defaultProps = {
    title: "title",
    message: "message",
    link: null,
    linkIcon: "plus",  // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    linkText:null,
    isTitleH1:false
};


export default BlackBoxContent;
