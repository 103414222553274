import React, {Fragment, useEffect} from 'react';
import PropTypes from "prop-types";

const MenuUlaval2023 = ({mobile, enabled}) => {

    let menuReturned = <></>;

    if (enabled)
        mobile?
            menuReturned =
                <>
                    <nav className="header-secondary-nav header-secondary-nav--mobile accessible-links-wrapper" aria-label="Liens rapides">
                        <ul className="menu">
                            <li className="menu-item"><a className="menu-link" target="_blank" href="https://www.dse.ulaval.ca/nous-joindre">Nous joindre</a></li>
                        </ul>
                    </nav>
                </>
            :
            menuReturned =
                <>
                    <nav className="header-secondary-nav header-secondary-nav--desktop accessible-links-wrapper" aria-label="Liens rapides">
                        <ul className="menu">
                            <li className="menu-item"><a className="menu-link" target="_blank" href="https://www.dse.ulaval.ca/nous-joindre">Nous joindre</a></li>
                        </ul>
                    </nav>
                </>

    return menuReturned;

}

export default MenuUlaval2023;

MenuUlaval2023.propTypes = {
    mobile: PropTypes.bool,
    enabled: PropTypes.bool
}

MenuUlaval2023.defaultProps = {
    mobile: false,
    enabled : true
};
