import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/references";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getReferencesList(language){
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}
    return httpService.get(apiEndpoint + "?locale=" +lang, {headers: {"x-auth-token" : jwt}})
}

export function getAllReferences(filterString){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/?filterString=" + filterString + "&showInactive=1", {headers: {"x-auth-token" : jwt}})
}

export function UpdateReference(referenceObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = referenceObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, referenceObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}
export function getReferenceByID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
}

export function CreateNewReference(referenceObject){
    if(referenceObject.id !== undefined)
        delete referenceObject.id

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}`, referenceObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}