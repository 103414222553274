import React, {Component} from 'react';
import {getAllUsers} from "../../services/usersService"
import {getClientByID} from "../../services/clientService"
import {withRouter} from "react-router-dom";
import ShowUsersTable from "../contents/Tables/ShowUsersTable";
import Pagination from "../contents/Tables/pagination";
import CRUDUserTableOptions from "../contents/common/htmlElements/TableComponent/subTableComponents/CRUDUserTableOptions";
import _ from 'lodash';
import moment from "moment";
import "./UserTableFrame100.css";
import {
    confirmMatch,
    deleteMatchFromClientID,
    getCurrentMatchForID,
    getPossibleMatchesForID,
    saveMatch
} from "../../services/matchUsersService";
import {getSponsorByID} from "../../services/sponsorService";
import {Button} from "react-bootstrap";
import PopperWindow from "../contents/PopperWindow/PopperWindow";
import {getWindowWidthAndHeight} from "../../utility/javascriptTools.mjs";
import SendMailForm from "../contents/Forms/Mail/SendMailForm";
import LoadingComponent from "../contents/common/loadingComponent";


class UserTableFrame100 extends Component {

    _isMounted = false;

    state = {
      userType : this.props.userType,
      isLoading : true,
      userList: {},
      selectedUsers : [],
      selectAllText : "Sélectionner tous les étudiants",
      sendMailAllChecked : "Envoyer un courriel aux étudiants sélectionnés",
      selectAllActive : false,
      filterString : "",
      NoUsersFound : false,
      pageSize:2000,
      currentPage:1,
      showInactive : 0,
      showMatchedUserStatus : 0,        //Possible status: [0]Tous, [1]Parrainés, [2]Non parrainés [3]Parrains disponibles(Parrains seulement)
      showUserState : 0,         //Possible state : [0]All, [1]New candidate, [2]Approved, [3]Candidate desisted [4]Untrained candidate, [5] Candidate refused, [10] Candidate confirmed(Clients Only)
      sortColumn: { path: "id", order: "asc" },
      apiSummary:{
          totalElements:null,
          totalPages:null,
          currentPage:null,
          pageSize:null,
          elementsInCurrentPage:null
      },
      windowWidth: 0,
      windowHeight: 0,
      sendSimpleMailWindowState: false,
      cci: "",
    };


    componentDidMount() {
        this._isMounted = true;
        this.loadDataFromAPI(this.state.currentPage, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isEmptyObject(obj){
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    }

    loadDataFromAPI = async (currentPage, pageSize, filter, showMatchedUserStatus, showUserState) => {

        try{
            this.setState({ isLoading: true });
            const {data : userList} = await getAllUsers(this.state.userType, 'date_created', currentPage, pageSize, this.state.showInactive, filter, showMatchedUserStatus, showUserState )
            const { windowWidth, windowHeight } = getWindowWidthAndHeight();

            if (this._isMounted) {
                const summary = userList[0];
                userList.shift();
                this.setState({
                    apiSummary: summary,
                    userList : userList,
                    windowWidth : windowWidth,
                    windowHeight : windowHeight,
                    isLoading : false
                });
            }

        }
        catch(err){
            if (this._isMounted) {
                this.setState({isLoading: false, NoUsersFound: true});
            }
        }

        this.setState({selectedUsers : []})
    };


    handlePageChange = page => {
        this.setState({ currentPage: page });
        this.loadDataFromAPI(page, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState)
        this.resetSelectBoxes();
    };

    handleFilterPage = string => {
        this.loadDataFromAPI(1, this.state.pageSize, string, this.state.showMatchedUserStatus, this.state.showUserState)
        this.setState({filterString: string})
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn: {...sortColumn} });
    };

    handlePageSize = pageSize => {
        this.setState({pageSize: parseInt(pageSize.target.value)})
        this.loadDataFromAPI(1, parseInt(pageSize.target.value), this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState)
    };

    handleDisplayWhichElements = displayElements => {
        this.setState({showMatchedUserStatus: parseInt(displayElements.target.value)})
        this.loadDataFromAPI(1, this.state.pageSize, this.state.filterString, parseInt(displayElements.target.value), this.state.showUserState)
        this.resetSelectBoxes();
    };

    handleDisplayWhichState = displayState => {
        this.setState({showUserState: parseInt(displayState.target.value)})
        this.loadDataFromAPI(1, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, parseInt(displayState.target.value))
        this.resetSelectBoxes();
    };

    handleSelectElement = element => {
        let selectedUsers = this.state.selectedUsers;
        const elementID = element.currentTarget.value;
        const value = element.currentTarget.checked;

        if (value){
            selectedUsers.push(elementID)
        }
        else{
            if(selectedUsers.includes(elementID)){
                _.remove(selectedUsers, function(n) { return n === elementID;});
            }
        }

        selectedUsers.sort((a, b) => a - b);
        this.setState({selectedUsers: selectedUsers})
    }


    toggleSelectAll = async () => {
        const checkboxes = await document.getElementsByName('userElements');
        let selectedUsers = [];
        this.setState({selectAllActive: !this.state.selectAllActive})

        checkboxes.forEach(checkbox => {
            const elementID = checkbox.value;
            if (this.state.selectAllActive){
                this.setState({selectAllText : "Désélectionner tous les étudiants"})
                selectedUsers.push(elementID)
            }
            else{
                this.setState({selectAllText : "Sélectionner tous les étudiants"})
            }

            checkbox.checked = this.state.selectAllActive;
        })

        selectedUsers.sort((a, b) => a - b);
        this.setState({selectedUsers: selectedUsers})
    }

    handleSelectAll = async () => {
        await this.toggleSelectAll();
    }

    handleMassiveMailSending = async () => {
        if (this.state.selectedUsers.length === 0)
        {
            return;
        }
        const sortedSelectedUsers = this.state.userList.filter(user => this.state.selectedUsers.includes(user.id.toString()));
        //Ici, on récupère que les courriels de l'objet, on supprime les index qui sont null et on crée une string
        const cci = sortedSelectedUsers.map(user => [user.emailUlaval, user.emailOther]).flat()
            .filter(mail => mail !== null)
            .join(', ');
        this.setState({cci:cci, sendSimpleMailWindowState:true});
    }

    handleMatchSelected = async () => {
        // console.log("matching elements.")
        const userThatStillHaveLoadAvailable = 3;
        const usersToManage = this.state.selectedUsers;
        const availableTodayDate = moment(new Date()).format("YYYY-MM-DD");
        this.setState({isLoading:true});

        for (const userID of usersToManage){
            // console.log("matching: " + userID)
            let {data: match} = await getPossibleMatchesForID("clients", userID, 1, true, availableTodayDate, userThatStillHaveLoadAvailable);
            const sponsorSelected = match[0];

            if (sponsorSelected) {
                await this.handleSaveMatch(userID, sponsorSelected.id);
            }
            else{
                let {data: match} = await getPossibleMatchesForID("clients", userID, 1, true, availableTodayDate,0);
                const sponsorOverloaded = match[0];
                if (sponsorOverloaded !== undefined) await this.handleSaveMatch(userID, sponsorOverloaded.id);
            }
        }
        this.resetSelectBoxes();
        this.loadDataFromAPI(this.state.currentPage, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState);
        this.setState({isLoading:false});

    }

    resetSelectBoxes = () => {
        this.setState({
            selectedUsers : [],
            selectAllText : "Sélectionner tous les étudiants",
            selectAllActive : false
        }

        );
    }

    handleSaveMatch = async (clientID, sponsorID) => {
        // console.log("Saving Match: " + clientID + " with " + sponsorID)
        const matchObject = {
            clientID: clientID,
            sponsorID: sponsorID
        }

        try{
            await saveMatch(matchObject);
            this.setState({userIsMatched:true});
            const {data:currentMatch} = await getCurrentMatchForID(this.state.clientID);
            this.setState({selectedSponsor:currentMatch});
        } catch (e){
            console.log("Matching error has occured while saving.")
        }

    }

    handleDeleteSelectedMatches = async () => {
        const usersToManage = this.state.selectedUsers;
        this.setState({isLoading:true});

        for (const userID of usersToManage){
            // console.log("Deleting match for clientID: " + userID)
            try{
                await deleteMatchFromClientID(userID);

            }
            catch(e){
                console.log("Error occured while deleting match object.")
            }
        }
        this.resetSelectBoxes();
        this.loadDataFromAPI(this.state.currentPage, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState);
        this.setState({isLoading:false});

    }

    handleConfirmSelectedMatches = async () => {
        const usersToManage = this.state.selectedUsers;
        this.setState({isLoading:true});

        for (const userID of usersToManage){
            // console.log("Confirming match for clientID: " + userID)
            try{
                const {data: client} = await getClientByID(userID);
                const {data: sponsor} = await getSponsorByID(client.takenInChargeByID);
                if ((client.state === 2) || (client.state === 10)){
                    await confirmMatch(client, "courrielPMFR", (sponsor.emailUlaval + ", " + (sponsor.emailOther ? sponsor.emailOther : "")));
                    await confirmMatch(client, "courrielEIFR", (client.emailUlaval + ", " + (client.emailOther ? client.emailOther : "")));
                }
            }
            catch(e){
                console.log("Error occured while deleting match object.")
            }
        }
        this.resetSelectBoxes();
        this.loadDataFromAPI(this.state.currentPage, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState);
        this.setState({isLoading:false});
    }

    renderMatchOptionsButtons = () => {
        const matchButton = <button className="matchSelectedButton btn btn-dark" onClick={this.handleMatchSelected}>Jumeler les étudiants sélectionnés</button>
        const deleteMatchButton = <button className="matchSelectedButton btn btn-dark" onClick={this.handleDeleteSelectedMatches}>Supprimer les jumelages sélectionnés</button>
        const confirmMatchButton = <button className="confirmMatchSelectedButton btn btn-dark" onClick={this.handleConfirmSelectedMatches}>Confirmer les jumelages sélectionnés (courriels)</button>
        let actionButton = null;

        switch (this.state.showMatchedUserStatus){
            case 1:
                actionButton = deleteMatchButton
                break;
            case 2:
                actionButton = matchButton
                break;
            default:actionButton = null;
                break;
        }

        return (
            <>
                <div className="menuActionButtons">
                    <Button variant="dark" className="selectAllButton" onClick={this.handleSelectAll}>{this.state.selectAllText}</Button>
                    <Button variant="dark" className="sendMassiveMailButton" onClick={this.handleMassiveMailSending}>{this.state.sendMailAllChecked}</Button>
                    {actionButton}
                    {(this.state.showMatchedUserStatus === 1) && confirmMatchButton}<br/>
                </div>
            </>
        )
    }

    confirmClient = async (client) => {
        this.setState({isLoading: true});
        try{
            const sponsor = await getSponsorByID(client.takenInChargeByID);
            await confirmMatch(client, "courrielPMFR", (sponsor.data.emailUlaval + ", " + (sponsor.data && sponsor.data.emailOther ? sponsor.data.emailOther : "")));
            await confirmMatch(client, "courrielEIFR", (client.emailUlaval + ", " + (client.emailOther ? client.emailOther : "")));
        }
        catch(e){
            console.log("Error occured while confirming match object.")
        }
        this.loadDataFromAPI(this.state.currentPage, this.state.pageSize, this.state.filterString, this.state.showMatchedUserStatus, this.state.showUserState);
        this.setState({isLoading:false});
    }

    hideSendSimpleMailWindow = () => {
        this.setState({sendSimpleMailWindowState:false});
    }

    render() {
        const count = this.state.apiSummary.totalElements == null ? 0 : this.state.apiSummary.totalElements;
        const pageSize = this.state.apiSummary.pageSize == null ? 0 : this.state.apiSummary.pageSize;
        const displayElementsWithUserStatus = this.state.showMatchedUserStatus == null ? 0 : this.state.showMatchedUserStatus;
        const displayElementsWithUserState = this.state.showUserState == null ? 0 : this.state.showUserState;
        const currentPage = this.state.apiSummary.currentPage == null ? 0 : this.state.apiSummary.currentPage;
        const { sortColumn, userList } = this.state;
        const pageSizeOptions = [50,100,500,2000];
        const clientsElementsToDisplay = [{value:0,text:"Tous les étudiants étrangers"}, {value:1,text:"Étudiants étrangers jumelés"}, {value:2,text:"Étudiants étrangers prêts pour jumelage"}];
        const sponsorsElementsToDisplay = [{value:0,text:"Tous les parrains"}, {value:1,text:"Parrains jumelés"}, {value:2,text:"Parrains non jumelés"}, {value:3,text:"Parrains disponibles"}];
        const clientsStateToDisplay = [{value:0,text:"Tous les états"}, {value:1,text:"Nouvelles candidatures"}, {value:2,text:"Candidatures approuvées"}, {value:3,text:"Candidatures désistées"}, {value:4,text:"Candidatures non formées"}, {value:5,text:"Candidatures refusées"}, {value:7,text:"Candidatures à vérifier"}, {value:10,text:"Candidatures confirmées"}];
        const sponsorsStateToDisplay = [{value:0,text:"Tous les états"}, {value:1,text:"Nouvelles candidatures"}, {value:2,text:"Candidatures approuvées"}, {value:3,text:"Candidatures désistées"}, {value:4,text:"Candidatures non formées"}, {value:5,text:"Candidatures refusées"}, {value:7,text:"Candidatures à vérifier"}];

        return [
            <div className="container">
                <div className="row" style={{marginTop:30}}>

                    <div className="col-md-12">
                        <CRUDUserTableOptions
                            userType ={this.state.userType}
                            itemCounts = {count}
                            OnPageFilter = {this.handleFilterPage}
                            OnPageSizeChange = {this.handlePageSize}
                            pageSize = {pageSize}
                            pageSizeOptions = {pageSizeOptions}
                            displayElements = {displayElementsWithUserStatus}
                            DisplayElementsOptions = {this.state.userType === "clients"? clientsElementsToDisplay : sponsorsElementsToDisplay}
                            OnDisplayElementsChange = {this.handleDisplayWhichElements}
                            displayUserState = {displayElementsWithUserState}
                            OnDisplayUserStateChange = {this.handleDisplayWhichState}
                            DisplayUserStateOptions ={this.state.userType === "clients"? clientsStateToDisplay : sponsorsStateToDisplay}
                            locale = {this.props.locale}
                        />

                        {this.state.userType === "clients"? this.renderMatchOptionsButtons() : null }

                        { this.state.isLoading && <LoadingComponent /> ||
                            <ShowUsersTable
                                userType={this.state.userType}
                                itemCounts={count}
                                userList={userList}
                                sortColumn={sortColumn}
                                onSort={this.handleSort}
                                isLoading={this.state.isLoading}
                                locale = {this.props.locale}
                                onSelectElement = {this.handleSelectElement}
                                userConfirmationFunction = {this.confirmClient}
                            />
                        }

                        <Pagination
                            itemsCount={count}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={this.handlePageChange}
                            renderFullButtons={false}
                            isLoading={this.state.isLoading}
                        />
                    </div>

                </div>
            </div>
            ,
            <div>
                <PopperWindow
                    modalTitle={"Envoyer un courriel"}
                    modalContent={<SendMailForm cci={this.state.cci} hideWindowState={this.hideSendSimpleMailWindow}/>}
                    saveText="Sauvegarder"
                    saveColor="success"
                    cancelText="Annuler"
                    width={Math.max(this.state.windowWidth*0.4, 700)}
                    height={Math.max(this.state.windowHeight*0.4, 500)}
                    showValue={this.state.sendSimpleMailWindowState}
                    closeWindow={this.hideSendSimpleMailWindow}
                    isForm={true}
                    isLargeWidth={true}
                />
            </div>
        ]
    }
}

export default withRouter(UserTableFrame100);
