import moment from "moment/moment";
import _ from "lodash";
import {getRegisteredSponsorsForFormationID} from "../../../services/formationsService";

export const GenJumelagesSheet = (workbook, buildObject) => {
    const worksheetJumelages = workbook.addWorksheet(`Jumelages_${moment().format('YYYY-MM-DD HH.mm')}`);

    formatMergedTitleCells(worksheetJumelages, 'A1', 'C1', 'E6E6E6', `Infos Jumelage`);
    formatMergedTitleCells(worksheetJumelages, 'D1', 'P1', 'ABABAB', `Étudiant de l'international`);
    formatMergedTitleCells(worksheetJumelages, 'Q1', 'AC1', 'E6E6E6', `Parrain/Marraine`);

    genMatchTableFor(worksheetJumelages, buildObject,'A2')

}

export const GenEESheet = (workbook, matchObject) => {
    const worksheetEE = workbook.addWorksheet(`ÉÉ`);

    genEETableFor(worksheetEE, matchObject, true,'A1')
}

export const GenEE_NonJumelesSheet = (workbook, matchObject) => {
    const worksheetEE = workbook.addWorksheet(`ÉÉ non jumelés`);

    genEETableFor(worksheetEE, matchObject, false, 'A1')
}

export const GenPMSheet = (workbook, matchObject) => {
    const worksheetEE = workbook.addWorksheet(`PM`);

    genPMTableFor(worksheetEE, matchObject, true, 'A1')
}

export const GenPM_NonJumelesSheet = (workbook, matchObject) => {
    const worksheetEE = workbook.addWorksheet(`PM non jumelés`);

    genPMTableFor(worksheetEE, matchObject, false, 'A1')
}

export const GenFormationsSheets = (workbook, matchObject) => {
    matchObject.formationsList?.forEach(formation => {
        const formationName = `Form-${formation.date}-${formation.time.replace(/:/g, "-")}`;
        const worksheetFormation = workbook.addWorksheet(formationName);
        genFormationTableFor(worksheetFormation, matchObject, formation, formationName, 'A6')
    })
}

const genMatchTableFor = (worksheet, buildObject, position) => {

    const propertyName = "Jumelages";

    const defaultCols = [
        {name: 'date_jumelage', filterButton: false},
        {name: 'Status_Cadenas', filterButton: false},
        {name: 'État_ÉÉ', filterButton: false},
        {name: 'ÉÉ_Salutations', filterButton: false},
        {name: 'ÉÉ_Prénom', filterButton: false},
        {name: 'ÉÉ_Nom', filterButton: false},
        {name: 'ÉÉ_PaysOrigine', filterButton: false},
        {name: 'ÉÉ_Age', filterButton: false},
        {name: 'ÉÉ_LangueMaternelle', filterButton: false},
        {name: 'ÉÉ_LangueSecondes', filterButton: false},
        {name: 'ÉÉ_Faculté', filterButton: false},
        {name: 'ÉÉ_NiveauÉtudes', filterButton: false},
        {name: 'ÉÉ_EMAILulaval', filterButton: false},
        {name: 'ÉÉ_EMAILother', filterButton: false},
        {name: 'ÉÉ_Phone', filterButton: false},

        {name: 'ÉÉ_PhoneOther', filterButton: false},
        {name: 'PM_Salutations', filterButton: false},
        {name: 'PM_Prénom', filterButton: false},
        {name: 'PM_Nom', filterButton: false},
        {name: 'PM_PaysOrigine', filterButton: false},
        {name: 'PM_Age', filterButton: false},
        {name: 'PM_LangueMaternelle', filterButton: false},
        {name: 'PM_LangueSecondes', filterButton: false},
        {name: 'PM_Faculté', filterButton: false},
        {name: 'PM_NiveauÉtudes', filterButton: false},
        {name: 'PM_EmailUlaval', filterButton: false},
        {name: 'PM_EmailOther', filterButton: false},
        {name: 'PM_Phone', filterButton: false},
        {name: 'PM_PhoneOther', filterButton: false}
    ];

    const defaultRows = [
        ['','Aucune entrée trouvée']
    ]
    let generatedRows = [];

    buildObject.matches.length && buildObject.matches.map(match => {generatedRows.push(
        [
            match.matchDate,
            convertStatusNoToString(match.client.confirmation_Status),
            convertStateNoToString(match.client.state),
            getSalutations(match.client.gender),
            match.client.firstName,
            match.client.lastName,
            convertItemIDToStringFromList(match.client.originCountry_id, buildObject.countryList),
            moment().diff(match.client.birthdate, 'years'),
            convertItemIDToStringFromList(match.client.languagePrimary_id, buildObject.languageList),
            convertArrayOfItemIDToStringFromList(match.client.languageOther, buildObject.languageList),
            convertItemIDToStringFromList(match.client.faculty_id, buildObject.facultyList),
            convertItemIDToStringFromList(match.client.studyLevel, buildObject.studyLevelList),
            match.client.emailUlaval,
            match.client.emailOther,
            match.client.phone,
            match.client.phoneOther,

            getSalutations(match.sponsor.gender),
            match.sponsor.firstName,
            match.sponsor.lastName,
            convertItemIDToStringFromList(match.sponsor.originCountry_id, buildObject.countryList),
            moment().diff(match.sponsor.birthdate, 'years'),
            convertItemIDToStringFromList(match.sponsor.languagePrimary_id, buildObject.languageList),
            convertArrayOfItemIDToStringFromList(match.sponsor.languageOther, buildObject.languageList),
            convertItemIDToStringFromList(match.sponsor.faculty_id, buildObject.facultyList),
            convertItemIDToStringFromList(match.sponsor.studyLevel, buildObject.studyLevelList),
            match.sponsor.emailUlaval,
            match.sponsor.emailOther,
            match.sponsor.phone,
            match.sponsor.phoneOther
        ]
    )})

    let columns =  defaultCols;
    let rows = buildObject.matches.length===0?defaultRows:generatedRows;

    createExcelJSTable(worksheet, position, propertyName, rows, columns);

}


const genEETableFor = (worksheet, buildObject, showMatched, position) => {

    const studentList = showMatched?buildObject.clientsList:buildObject.clientsNotMatched;

    const propertyName = showMatched?"ÉtudiantsDeLInternationalJumelés":"ÉtudiantsDeLInternationalNonJumelés";

    const defaultCols = [
        {name: 'État', filterButton: false},
        {name: 'Date/Heure inscription', filterButton: false},
        {name: 'Arrivée au Québec', filterButton: false},
        {name: 'NI', filterButton: false},
        {name: 'Idul', filterButton: false},
        {name: 'Prénom', filterButton: false},
        {name: 'Nom', filterButton: false},
        {name: 'Age', filterButton: false},
        {name: 'Sexe', filterButton: false},
        {name: 'Langue maternelle', filterButton: false},
        {name: 'Langues secondaires', filterButton: false},
        {name: 'Faculté', filterButton: false},
        {name: 'Première Session', filterButton: false},
        {name: 'Niveau d\'études', filterButton: false},
        {name: 'Je prévois étudier', filterButton: false},
        {name: 'Je participe à un programme d\'échange (visiteur)', filterButton: false},
        {name: 'Préférences', filterButton: false},
        {name: 'Références', filterButton: false},
        {name: 'Téléphone', filterButton: false},
        {name: 'Téléphone - Autre', filterButton: false},
        {name: 'Courriel Ulaval', filterButton: false},
        {name: 'Courriel - Autre', filterButton: false},
        {name: 'Commentaires', filterButton: false},
        {name: 'Notes administratives', filterButton: false}
    ];

    const defaultRows = [
        ['','Aucune entrée trouvée']
    ]
    let generatedRows = [];

    studentList.length && studentList.map(client => {generatedRows.push(
        [
            client.state,
            moment(client.date_registered).utc().format('DD-MM-YYYY HH:MM'),
            client.arrivalInQuebec,
            client.ni,
            client.idul,
            client.firstName,
            client.lastName,
            moment().diff(client.birthdate, 'years'),
            getGender(client.gender),
            convertItemIDToStringFromList(client.languagePrimary_id, buildObject.languageList),
            convertArrayOfItemIDToStringFromList(client.languageOther, buildObject.languageList),
            convertItemIDToStringFromList(client.faculty_id, buildObject.facultyList),
            convertSessionIDToString(client.firstSession_id, buildObject.sessionsList),
            convertItemIDToStringFromList(client.studyLevel, buildObject.studyLevelList),
            convertItemIDToStringFromList(client.nextSession, buildObject.nextSessionList),
            convertBoolToYesNo(client.isVisitor),
            convertArrayOfItemIDToStringFromList(client.preferences, buildObject.preferencesList),
            convertArrayOfItemIDToStringFromList(client.references, buildObject.referencesList),
            client.phone,
            client.phoneOther,
            client.emailUlaval,
            client.emailOther,
            client.comments,
            disableHtmlFromString(client.adminComments)

        ]
    )});

    let columns =  defaultCols;
    let rows = studentList.length===0?defaultRows:generatedRows;

    createExcelJSTable(worksheet, position, propertyName, rows, columns);

}


const genPMTableFor = (worksheet, buildObject, showMatched ,position) => {

    const studentList = showMatched?buildObject.sponsorsList:buildObject.sponsorsNotMatched;

    const propertyName = showMatched?"PMJumelés":"PMNonJumelés";

    const defaultCols = [
        {name: 'État', filterButton: false},
        {name: 'Charge actuelle', filterButton: false},
        {name: 'Charge maximale', filterButton: false},
        {name: 'En charge de', filterButton: false},
        {name: 'Date/Heure inscription', filterButton: false},
        {name: 'Formation complétée le', filterButton: false},
        {name: 'Date inscription formation', filterButton: false},
        {name: 'Titre de la formation inscrite', filterButton: false},
        {name: 'NI', filterButton: false},
        {name: 'Idul', filterButton: false},
        {name: 'Prénom', filterButton: false},
        {name: 'Nom', filterButton: false},
        {name: 'Age', filterButton: false},
        {name: 'Sexe', filterButton: false},
        {name: 'Langue maternelle', filterButton: false},
        {name: 'Langues secondaires', filterButton: false},
        {name: 'Faculté', filterButton: false},
        {name: 'Première Session', filterButton: false},
        {name: 'Niveau d\'études', filterButton: false},
        {name: 'Je prévois étudier', filterButton: false},
        {name: 'Préférences', filterButton: false},
        {name: 'Références', filterButton: false},
        {name: 'Téléphone', filterButton: false},
        {name: 'Téléphone - Autre', filterButton: false},
        {name: 'Courriel Ulaval', filterButton: false},
        {name: 'Courriel - Autre', filterButton: false},
        {name: 'J\'ai déjà été PM', filterButton: false},
        {name: 'Commentaires', filterButton: false},
        {name: 'Notes administratives', filterButton: false}

    ];

    const defaultRows = [
        ['','Aucune entrée trouvée']
    ]
    let generatedRows = [];

    studentList.length && studentList.map(client => {generatedRows.push(
        [
            client.state,
            client.sponsorLoadCurrent,
            client.sponsorLoad,
            client.inChargeOfClientsNames,
            moment(client.date_registered).utc().format('DD-MM-YYYY HH:MM'),
            client.formationDate,
            client.date_inscription_formation,
            client.formationTitre,
            client.ni,
            client.idul,
            client.firstName,
            client.lastName,
            moment().diff(client.birthdate, 'years'),
            getGender(client.gender),
            convertItemIDToStringFromList(client.languagePrimary_id, buildObject.languageList),
            convertArrayOfItemIDToStringFromList(client.languageOther, buildObject.languageList),
            convertItemIDToStringFromList(client.faculty_id, buildObject.facultyList),
            client.firstSession_text,
            convertItemIDToStringFromList(client.studyLevel, buildObject.studyLevelList),
            convertItemIDToStringFromList(client.nextSession, buildObject.nextSessionList),
            convertArrayOfItemIDToStringFromList(client.preferences, buildObject.preferencesList),
            convertArrayOfItemIDToStringFromList(client.references, buildObject.referencesList),
            client.phone,
            client.phoneOther,
            client.emailUlaval,
            client.emailOther,
            client.isVeteran,
            client.comments,
            disableHtmlFromString(client.adminComments)
        ]
    )});

    let columns =  defaultCols;
    let rows = studentList.length===0?defaultRows:generatedRows;

    createExcelJSTable(worksheet, position, propertyName, rows, columns);

}

const genFormationTableFor = (worksheet, buildObject, formation, formationName, position) => {

    worksheet.getCell('A1').value = "Formation";
    worksheet.getCell('A1').font = {bold:true, color:{argb:'FFFFFFFF'}};
    worksheet.getCell('A1').fill = {type: 'pattern',pattern: 'solid',fgColor: { argb: 'FF4F81BD' } };
    worksheet.getCell('B1').value = formation.name;
    worksheet.getCell('B1').font = {bold:true};

    worksheet.getCell('A2').value = "Enseignant";
    worksheet.getCell('A2').font = {bold:true, color:{argb:'FFFFFFFF'}};
    worksheet.getCell('A2').fill = {type: 'pattern',pattern: 'solid',fgColor: { argb: 'FF4F81BD' } };
    worksheet.getCell('B2').value = formation.teacher;
    worksheet.getCell('B2').font = {bold:true};

    worksheet.getCell('A3').value = "Endroit";
    worksheet.getCell('A3').font = {bold:true, color:{argb:'FFFFFFFF'}};
    worksheet.getCell('A3').fill = {type: 'pattern',pattern: 'solid',fgColor: { argb: 'FF4F81BD' } };
    worksheet.getCell('B3').value = formation.location;
    worksheet.getCell('B3').font = {bold:true};

    worksheet.getCell('A4').value = "Date de la formation";
    worksheet.getCell('A4').font = {bold:true, color:{argb:'FFFFFFFF'}};
    worksheet.getCell('A4').fill = {type: 'pattern',pattern: 'solid',fgColor: { argb: 'FF4F81BD' } };
    worksheet.getCell('B4').value = formation.date + " @ " + formation.time;
    worksheet.getCell('B4').font = {bold:true};


    const defaultCols = [
        {name: 'NI', filterButton: false},
        {name: 'Idul', filterButton: false},
        {name: 'Prénom', filterButton: false},
        {name: 'Nom', filterButton: false},
        {name: 'Age', filterButton: false},
        {name: 'Sexe', filterButton: false},
        {name: 'Langue maternelle', filterButton: false},
        {name: 'Faculté', filterButton: false},
        {name: 'Téléphone', filterButton: false},
        {name: 'Téléphone - Autre', filterButton: false},
        {name: 'Courriel Ulaval', filterButton: false},
        {name: 'Courriel - Autre', filterButton: false},
        {name: 'Titre formation', filterButton: false},
        {name: 'Date', filterButton: false},
        {name: 'Heure', filterButton: false},
        {name: 'Formateur', filterButton: false},
        {name: 'Emplacement', filterButton: false}
    ];

    const defaultRows = [
        ['','Aucune entrée trouvée']
    ]
    let generatedRows = [];

    formation.sponsorsRegistered.length && formation.sponsorsRegistered.map(item => {generatedRows.push(
        [
            item.ni,
            item.idul,
            item.firstName,
            item.lastName,
            moment().diff(item.birthdate, 'years'),
            getGender(item.gender),
            convertItemIDToStringFromList(item.languagePrimary_id, buildObject.languageList),
            convertItemIDToStringFromList(item.faculty_id, buildObject.facultyList),
            item.phone,
            item.phoneOther,
            item.emailUlaval,
            item.emailOther,
            item.titreFormation,
            item.dateFormation,
            item.timeFormation,
            item.teacherFormation,
            item.locationFormation
        ]
    )});

    let columns =  defaultCols;
    let rows = formation.sponsorsRegistered.length===0?defaultRows:generatedRows;

    createExcelJSTable(worksheet, position, formationName, rows, columns);

}


const createExcelJSTable = (worksheet, position, tableName, rows, cols) => {
    worksheet.addTable({
        name: tableName.replace(/-/g, ""),   //dash not allowed
        ref: position,
        headerRow: true,
        totalsRow: false,
        style: {
            //theme: 'TableStyleDark3',
            showRowStripes: true,
        },
        columns: cols,
        rows: rows,
    })

    setAutoWidthOnSheet(worksheet);
}


const setAutoWidthOnSheet = (sheet) => {
    // Définissez automatiquement la largeur des colonnes
    sheet.columns.forEach((column) => {
        let maxLength = 0;
        column.eachCell({includeEmpty: true}, (cell) => {
            const columnLength = cell.value ? cell.value.toString().length : 10;
            if (columnLength > maxLength) {
                maxLength = columnLength;
            }
        });
        // Définissez la largeur minimale à 10 (pour éviter les colonnes trop étroites)
        column.width = maxLength < 10 ? 10 : maxLength;
    });
}

export const formatMergedTitleCells = (worksheet, startCell, endCell, color, text) => {
    worksheet.mergeCells(`${startCell.toUpperCase()}:${endCell.toUpperCase()}`);
    worksheet.getCell(`${startCell}`).alignment = {vertical: 'middle', horizontal: 'center'};
    worksheet.getCell(`${startCell}`).border = {
        top: {style:'thin'},
        left: {style:'thin'},
        bottom: {style:'thin'},
        right: {style:'thin'}
    };
    worksheet.getCell(startCell).fill = {
        type: 'pattern',
        pattern:'solid',
        fgColor:{argb:`${color}`},
    };
    worksheet.getCell(startCell).font = {bold:true}
    worksheet.getCell(startCell).value = `${text}`;
};

export const downloadWorkbook = async(workbook) => {
    const filename = "Jumelages_"+ moment().format('YYYY-MM-DD HH.mm');

    // Écrivez le classeur dans un ArrayBuffer
    const workBookOutput = await workbook.xlsx.writeBuffer();

    // Créez un Blob à partir de l'ArrayBuffer
    const blob = new Blob([workBookOutput], {type:'application/octet-stream'});

    // Créez un lien et cliquez dessus
    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = `${filename}.xlsx`;
    downloadLink.click();
}

export const getGender = (gender) => {
    switch (gender){
        case 0: return 'Femme';
        case 1: return 'Homme';
        default: return 'Non spécifié';
    }
}

export const getSalutations = (gender) => {
    switch (gender){
        case 0: return 'Mme';
        case 1: return 'Mr';
        default: return '';
    }
}

export const disableHtmlFromString = (htmlString) => {
    let tmp = document.createElement("DIV");
    tmp.innerHTML = htmlString;
    return tmp.textContent || tmp.innerText || "";
}

const convertBoolToYesNo = (value) => {
    return value?"Oui":"Non";
}

export const convertItemIDToStringFromList = (itemID, completeList) => {
    if(completeList){
        const singleItem = completeList.filter(item => {return item.id === itemID})[0];
        return singleItem?.name;
    }
    else{return '###ERREUR_TableauNonPrisEnCharge###'};
}

export const convertArrayOfItemIDToStringFromList = (userList, completeList) => {
    let list = [];
    if (userList !== null){
        if (userList !== undefined){
            userList.forEach(itemID => {
                list.push(convertItemIDToStringFromList(itemID, completeList))
            })
            return list?.join(", ");
        }
    }
    else {return "";}
}

export const convertSessionIDToString = (sessionID, sessionsList) => {
    const indexOfSession =_.findLastIndex(sessionsList, ['id', sessionID]);
    return sessionsList[indexOfSession]?.name?sessionsList[indexOfSession]?.name:"Erreur - Choix inexistant dans la BD"
}

export const convertStatusNoToString = (statusNo) => {
    switch (statusNo){
        case 0:
            return  "0 - Pas de confirmation"
        case 1:
            return  "1 - L'EI a confirmé"
        case 2:
            return  "2 - Le PM a confirmé"
        case 3:
            return  "3 - L'EI et le PM ont confirmés"
        default :
            return "Non disponible"
    }
}

export const convertStateNoToString = (stateNo) => {
    switch (stateNo){
        case 1:
            return  "Nouvelle candidature"
        case 2:
            return  "Candidature approuvée"
        case 3:
            return  "Candidat désisté"
        case 4:
            return  "Candidat non formé"
        case 5:
            return  "Candidat refusé"
        case 6:
            return "Candidat exclu"
        case 7:
            return "À vérifier"
        case 10:
            return  "Confirmé (Courriel envoyé)"
        default :
            return "Non disponible"
    }
}


export const InsertRegisteredSponsorsToFormationsList = async (formationsList) => {
        if (formationsList.length) {
            const copyOfFormations = [...formationsList];

            for (let formation of copyOfFormations) {
                let {data: sponsorsRegistered} = await getRegisteredSponsorsForFormationID(formation.id);
                sponsorsRegistered.forEach(item => {
                    item.titreFormation = formation.name;
                    item.dateFormation = formation.date;
                    item.timeFormation = formation.time;
                    item.teacherFormation = formation.teacher;
                    item.locationFormation = formation.location;
                })
                copyOfFormations[copyOfFormations.indexOf(formation)].sponsorsRegistered = sponsorsRegistered;
            }
            return copyOfFormations;

        }
    }