import { useCurrentEditor } from "@tiptap/react";
import React from 'react'
import FormatBoldOutlinedIcon from '@mui/icons-material/FormatBoldOutlined';
import FormatItalicOutlinedIcon from '@mui/icons-material/FormatItalicOutlined';
import FormatStrikethroughOutlinedIcon from '@mui/icons-material/FormatStrikethroughOutlined';
import StrikethroughSOutlinedIcon from '@mui/icons-material/StrikethroughSOutlined';
import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import FormatListNumberedOutlinedIcon from '@mui/icons-material/FormatListNumberedOutlined';
import DeveloperModeOutlinedIcon from '@mui/icons-material/DeveloperModeOutlined';
import FormatQuoteOutlinedIcon from '@mui/icons-material/FormatQuoteOutlined';
import HorizontalRuleOutlinedIcon from '@mui/icons-material/HorizontalRuleOutlined';
import InsertPageBreakOutlinedIcon from '@mui/icons-material/InsertPageBreakOutlined';
import UndoOutlinedIcon from '@mui/icons-material/UndoOutlined';
import RedoOutlinedIcon from '@mui/icons-material/RedoOutlined';
import FormatColorTextOutlinedIcon from '@mui/icons-material/FormatColorTextOutlined';
import LinkIcon from '@mui/icons-material/Link';
import LinkOff from '@mui/icons-material/LinkOff';
import './TiptapToolbar.css';

const TiptapToolBar = ({editor}) => {

    if (!editor) {
        return null
    }


    // return (
    //   <>
    //     <button
    //       onClick={() => editor.chain().focus().toggleUnderline().run()}
    //       className={editor.isActive('underline') ? 'is-active' : ''}
    //     >
    //       toggleUnderline
    //     </button>
    //     <button
    //       onClick={() => editor.chain().focus().setUnderline().run()}
    //       disabled={editor.isActive('underline')}
    //     >
    //       setUnderline
    //     </button>
    //     <button
    //       onClick={() => editor.chain().focus().unsetUnderline().run()}
    //       disabled={!editor.isActive('underline')}
    //     >
    //       unsetUnderline
    //     </button>
    //   </>
    // );

    return (
        <div className="TiptapToolbarWrapper">
            <button title="Gras"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleBold().run();
                    }}
                    disabled={!editor.can().chain().focus().toggleBold().run()}
                    className={editor.isActive("bold") ? "is-active" : ""}
            >
                <FormatBoldOutlinedIcon/>
            </button>
            <button title="Italique"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleItalic().run();
                    }}
                    disabled={!editor.can().chain().focus().toggleItalic().run()}
                    className={editor.isActive("italic") ? "is-active" : ""}
            >
                <FormatItalicOutlinedIcon/>
            </button>
            <button title="Barré"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleStrike().run();
                    }}
                    disabled={!editor.can().chain().focus().toggleStrike().run()}
                    className={editor.isActive("strike") ? "is-active" : ""}
            >
                <FormatStrikethroughOutlinedIcon/>
                {/*<StrikethroughSOutlinedIcon />*/}
            </button>
            <button title="Code"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleCode().run();
                    }}
                    disabled={!editor.can().chain().focus().toggleCode().run()}
                    className={editor.isActive("code") ? "is-active" : ""}
            >
                <CodeOutlinedIcon/>
            </button>
            <button
                title="Lien"
                onClick={(event) => {
                    event.preventDefault();
                    let url = window.prompt('URL du lien');
                    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                    if (emailRegex.test(url)) {
                        url = `mailto:${url}`;
                    }
                    editor.chain().focus().extendMarkRange('link').setLink({href: url}).run();
                }}
                className={editor.isActive('link') ? 'is-active' : ''}
            >
                <LinkIcon/>
            </button>
            <button
                title="Désélectionner le lien"
                onClick={(event) => {
                    event.preventDefault();
                    editor.chain().focus().unsetLink().run();
                }}
                className={editor.isActive('link') ? 'is-active' : ''}
            >
                <LinkOff/>
            </button>
            <button className="clearOutlinedButton" title="Effacer mise en forme"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().unsetAllMarks().run();
                    }}
            >
                <ClearOutlinedIcon/>
            </button>
            {/*<button*/}
            {/*  onClick={(event) => {*/}
            {/*    event.preventDefault();*/}
            {/*    editor.chain().focus().clearNodes().run();*/}
            {/*  }}*/}
            {/*>*/}
            {/*  clear nodes*/}
            {/*</button>*/}
            <button title="Paragraphe"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().setParagraph().run();
                    }}
                    className={editor.isActive("paragraph") ? "is-active" : ""}
            >
                Paragraph
            </button>
            <button title="Titre 1"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleHeading({level: 1}).run();
                    }}
                    className={editor.isActive("heading", {level: 1}) ? "is-active" : ""}
            >
                h1
            </button>
            <button title="Titre 2"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleHeading({level: 2}).run();
                    }}
                    className={editor.isActive("heading", {level: 2}) ? "is-active" : ""}
            >
                h2
            </button>
            <button title="Titre 3"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleHeading({level: 3}).run();
                    }}
                    className={editor.isActive("heading", {level: 3}) ? "is-active" : ""}
            >
                h3
            </button>
            <button title="Liste non ordonnée"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleBulletList().run();
                    }}
                    className={editor.isActive("bulletList") ? "is-active" : ""}
            >
                <FormatListBulletedOutlinedIcon/>
            </button>
            <button title="Liste ordonnée"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleOrderedList().run();
                    }}
                    className={editor.isActive("orderedList") ? "is-active" : ""}
            >
                <FormatListNumberedOutlinedIcon/>
            </button>
            <button title="Mise en évidence noire"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleCodeBlock().run();
                    }}
                    className={editor.isActive("codeBlock") ? "is-active" : ""}
            >
                <DeveloperModeOutlinedIcon/>
            </button>
            <button title="Citation"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().toggleBlockquote().run();
                    }}
                    className={editor.isActive("blockquote") ? "is-active" : ""}
            >
                <FormatQuoteOutlinedIcon/>
            </button>
            <button title="Barre horizontale"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().setHorizontalRule().run();
                    }}
            >
                <HorizontalRuleOutlinedIcon/>
            </button>
            <button title="Annuler"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().undo().run();
                    }}
                    disabled={!editor.can().chain().focus().undo().run()}
            >
                <UndoOutlinedIcon/>
            </button>
            <button title="Refaire"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().redo().run();
                    }}
                    disabled={!editor.can().chain().focus().redo().run()}
            >
                <RedoOutlinedIcon/>
            </button>
            <button title="Retour de ligne forcé (Shift-Enter)"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().setHardBreak().run();
                    }}
            >
                <InsertPageBreakOutlinedIcon/>
            </button>
            {/*            <button title="Mettre en bleu"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().setColor("#0D6EFD").run();
                    }}
                    className={
                        editor.isActive("textStyle", {color: "#0D6EFD"}) ? "is-active" : ""
                    }
            >
                <FormatColorTextOutlinedIcon sx={{color: "#0D6EFD"}}/>
            </button>
            <button title="Mettre en rouge"
                    onClick={(event) => {
                        event.preventDefault();
                        editor.chain().focus().setColor("#DC3545").run();
                    }}
                    className={
                        editor.isActive("textStyle", {color: "#DC3545"}) ? "is-active" : ""
                    }
            >
                <FormatColorTextOutlinedIcon sx={{color: "#DC3545"}}/>
            </button>*/}
        </div>
    );
}

export default TiptapToolBar;