import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import * as PropTypes from 'prop-types'
import "./FullsizeTextarea.css"

const FullsizeTextarea = ({error,value, onChange, name, formattedMessage, intl}) => {

    const {formatMessage} = intl;

    value = (value !== null) ? value : ""

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-md-9">
                    <label htmlFor={name} className="leftSideLabel">
                        <FormattedMessage id={formattedMessage}/>
                    </label>
                </div>
                <div className="col-md-3">
                </div>
            </div>
            <div className="row">
                <div className="col-md-9">
                    <textarea
                        value = {value}
                        onChange={onChange}
                        type="text"
                        id={name}
                        name={name}
                        className="form-control textareaFullWidth"
                    />
                </div>
                <div className="col-md-3 formErrors">
                    {error && <div className="alert alert-danger"><span className="validationCustomMessage">{formatMessage({id:error.msg})}</span></div>}
                </div>
            </div>

        </div>
    );
}

FullsizeTextarea.propTypes = {
    error : PropTypes.object,
    value : PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
    name : PropTypes.string.isRequired,
    formattedMessage : PropTypes.string
}

export default injectIntl(FullsizeTextarea);
