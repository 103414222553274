import React, {Component} from 'react';
import {injectIntl} from "react-intl";
import "./CRUDTableHeader.css"
import SwapVertIcon from '@mui/icons-material/SwapVert';
class CRUDTableHeader extends Component{

    raiseSort = path => {
        const sortColumn = { ...this.props.sortColumn };
        if (sortColumn.path === path)
            sortColumn.order = sortColumn.order === "asc" ? "desc" : "asc";
        else {
            sortColumn.path = path;
            sortColumn.order = "asc";
        }
        console.warn("sorting " + JSON.stringify(sortColumn))
        this.props.onSort(sortColumn);
    };

    renderSortIcon = column => {
        const { sortColumn } = this.props;

        if (!column.path || column.path !== sortColumn.path) {
            return null;
        }
        return <SwapVertIcon className="sortIcon"></SwapVertIcon>;

    };

    render(){
        return (
            <>
                <thead>

                <tr>

                    {this.props.select?
                        <th className="selectBoxes"></th>
                        :
                        null
                    }

                    {this.props.columns.map(column => (
                        <th
                            className="clickable"
                            key={column.path || column.key}
                            onClick={() => this.raiseSort(column.path)}
                        >
                            {column.label} {this.renderSortIcon(column)}
                        </th>
                    ))}
                </tr>

                </thead>
            </>
        );
    }

};

export default injectIntl(CRUDTableHeader);
