import React from 'react';
import ReactTooltip from 'react-tooltip';
import {injectIntl} from "react-intl";
import "./InputViewOnly.css"

const InputViewOnly = ({error, name, label, longField, units, intl, value, tooltip, ...rest}) => {

    const {formatMessage} = intl;

    value = (value !== null) ? value : ""

    return (
        <div className="form-group">
            <div className="row">

                <ReactTooltip />

                <div className="col-md-4">
                    <label htmlFor={name} className="leftSideLabel">
                        {formatMessage({id:label})}
                    </label>
                    {(tooltip)?<span data-tip={tooltip} data-multiline="multiline" className="badge badge-primary badge-tooltip">i</span>:""}
                </div>
                <div className="col-md-5">
                    <span className="InputViewOnlyData">{value}</span>
                    {units? <span className={"inputUnits"}>{formatMessage({id:units})}</span>:null}

                </div>
                <div className="col-md-3">
                </div>
            </div>

        </div>
    );
}

export default injectIntl(InputViewOnly);
