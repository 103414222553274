import { EditorContent, useEditor } from '@tiptap/react'
import './TiptapEditor.css';
import TiptapToolbar from "./TiptapToolbar";
import TiptapExtensions from "./TiptapExtensions";
import TiptapFooter from "./TiptapFooter";
import React, { useEffect } from "react";


const limit = 65535



const TipTapEditor = (props) => {

  let content = props.value;

  const editor = useEditor({
    extensions: TiptapExtensions,
    content: content,
    onUpdate: ({ editor }) => {
      props.onChange?.(editor.getHTML())
    }
  });

  useEffect(() => {
    if (editor?.isEmpty && content !== '<p></p>') {
      editor.commands.setContent(content);
    }
  }, [content]);

  if (!editor) {
    return null
  }

  return (
    <>
      <TiptapToolbar editor={editor} />
      <EditorContent editor={editor} />

      <TiptapFooter editor={editor} limit={limit}/>
    </>
  )
}

export default TipTapEditor