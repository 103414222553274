import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import {UpdateFormation, CreateNewFormation} from "../../../../services/formationsService";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";
import {UpdateSponsor, UpdateSponsorFormation} from "../../../../services/sponsorService";
import "./FormationForm.css"

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class FormationForm extends Form {

    state = {
        locale : this.props.locale,
        formation : this.props.formation,
        registeredSponsors : Array.isArray(this.props.registeredSponsors) ? this.props.registeredSponsors : [],

        errors:{}
    }

    constructor(props) {
        super(props);
        const currentFormation = this.props.formation;
        this.state.sendButtonDisabled = false;
        this.state.checkedSponsors = {};
        this.state.data = {
            id : currentFormation.id,
            active : (currentFormation.active === 1)?true:false,
            fr : currentFormation.fr,
            en : currentFormation.en,
            teacher : currentFormation.teacher,
            places : currentFormation.places,
            time : currentFormation.time,
            date : currentFormation.date,
            location : currentFormation.location,


            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        active : Joi.boolean(),
        fr: Joi.string().required(),
        en : Joi.string().required(),
        teacher: Joi.string().required(),
        places: Joi.number().required(),
        date: Joi.date().format("YYYY-MM-DD").required(),
        time: Joi.string().required(),
        location: Joi.string().required()
    };


    doSubmit = async e => {
        this.setState({sendButtonDisabled:true})
        let formationObject = {...this.state.data};

        delete formationObject.errors;
        if(formationObject.id){
            try{
                await UpdateFormation(formationObject);
                Object.keys(this.state.checkedSponsors).map(async sponsorID => {
                    const sponsor = this.state.registeredSponsors.find(regSponsor => regSponsor.id === parseInt(sponsorID));
                    sponsor.state = 2;
                    sponsor.formationDate = formationObject.date;
                    await UpdateSponsorFormation({id: sponsorID, state: sponsor.state, formationDate: sponsor.formationDate});
                });
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }
        else{
            try{
                await CreateNewFormation(formationObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }

    };

    renderFormationDetails = () => {
        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="formation.form.h1" /> {this.props.formation.id}</h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <h2><FormattedMessage id="formation.form.h2" /></h2>
                    {this.renderInput("fr", "formation.form.fr")}
                    {this.renderInput("en", "formation.form.en")}
                    {this.renderInput("teacher", "formation.form.teacher")}
                    {this.renderInput("places", "formation.form.places")}
                    {this.renderInput("location", "formation.form.location")}
                    {this.renderDatePickerField("date", "formation.form.date", false, null,null,"-25:+1y",)}
                    {this.renderInputTimeOnly("time", "formation.form.time", false)}

                    {this.renderCheckBox("active", "formation.form.active")}


                </div>
            </>
        );
    }

    handleSponsorsCheckboxChange = (event, id) => {
        const { checked } = event.target;
        this.setState(prevState => ({
            checkedSponsors: {
                ...prevState.checkedSponsors,
                [id]: checked
            }
        }));
    };

    sortSponsors(sponsors) {
        return [...sponsors].sort((sponsorA, sponsorB) =>
            sponsorA.formationDate ? (sponsorB.formationDate ? 0 : 1) : -1
        );
    }


    renderRegistrations = () => {
        const sortedSponsors = this.sortSponsors(this.state.registeredSponsors);
        return (
            <div className="container">
                <h2 style={{marginTop:80}}><FormattedMessage id="formation.form.presentCheckListTitle" /></h2>
                <ul className="formationPresencesSheet" style={{marginTop:30}}>
                    {sortedSponsors.map((sponsor, index) => (
                        <li key={sortedSponsors.indexOf(sponsor)} className={`${index % 2 === 0 ? 'pair' : 'impair'} ${sponsor.formationDate ? 'formationDate' : ''}`}>
                            <label
                                htmlFor={sponsor.id}
                                className="">
                                {sponsor.firstName} {sponsor.lastName} {sponsor.formationDate ? `(${sponsor.formationDate})` : ""}
                            </label>
                            <input style={{marginLeft:5}}
                                   type="checkbox"
                                   id={sponsor.id}
                                   checked={this.state.checkedSponsors[sponsor.id] || false}
                                   onChange={(event) => this.handleSponsorsCheckboxChange(event, sponsor.id)}
                            />
                        </li>
                    ))}

                </ul>
            </div>

        );

    }

    render() {
        return(
            <>
                <form onSubmit={this.handleSubmit}>
                    <div className="row g-3">
                        <div className="col-md-6">
                            {this.renderFormationDetails()}
                        </div>
                        <div className="col-md-6">
                            {this.renderRegistrations()}
                        </div>
                    </div>

                    <div className="row formationFooter" >
                        <div className="col-md-9 ">

                        </div>

                        <div className="col-md-3">
                            <button style={{marginBottom:50}} disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                                {this.state.sendButtonDisabled ? <FormattedMessage id="formation.form.savingChanges"/> : <FormattedMessage id="formation.form.saveChanges"/>}
                            </button>
                        </div>
                    </div>
                </form>


            </>
        );

    }


}

export default withRouter(injectIntl(FormationForm));
