import React, {useEffect, useState} from 'react';
import {useNotifications} from "../../../../Hooks/useNotifications";
import authService from "../../../../services/authService";
import useIsMounted from "../../../../Hooks/useIsMounted";
import LoadingComponent from "../../common/loadingComponent";
import Homepage from "./Homepage";
import HomepageContent from "./homepageContent";
import {useIntl} from "react-intl";
import {getNotificationsForUser} from "../../../../services/notificationsService";



const LoadHomepage = () => {

    const intl = useIntl();
    const user = authService.getCurrentUserToken();

    const [isLoading, setIsLoading] = useState(false);
    const isMounted = useIsMounted()

    const { notifications, addNotification, setNotification } = useNotifications();


    useEffect(async ()=>{
        async function mountHomepage() {
            try {
                const {data: notifs} = await getNotificationsForUser(user);
                console.log("Mounting notifications: " + JSON.stringify(notifs));
                setNotification(notifs);

            } catch (err) {
                setIsLoading(false);
            }
            finally {
                setIsLoading(false);
            }
        }


        mountHomepage();

    },[isMounted])


    if (isLoading) {
        return (
            <LoadingComponent
                message="loading.general"
            />
        )
    }
    else {

        return (<HomepageContent locale={intl.locale} user={user}/>);
        // return (<Homepage />);

    }


};

export default LoadHomepage;
