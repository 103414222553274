import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/mails";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getAllMails(){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint, {headers: {"x-auth-token" : jwt}})
}

export function getMailByName(name){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/" + name, {headers: {"x-auth-token" : jwt}})
}

export function updateMails(mailsObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.put(`${apiEndpoint}/`, mailsObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function sendMail(userObject, mailObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}/sendMail`, {userObject, mailObject}, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function sendReminder(mailData){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
         return httpService.post(`${apiEndpoint}/sendReminder`, {mailData}, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function sendSimpleMail(mail, subject, to, cci){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}/sendSimple`, {mail, subject, to, cci}, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}