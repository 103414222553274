import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import MailsForm from "../contents/Forms/Mails/MailsForm"

const MailsPageLayout = (locale) => {

    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <MailsForm locale={locale}/>
            </main>
            <Footer/>
        </>
    );
}

export default withRouter(injectIntl(MailsPageLayout));
