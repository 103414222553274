import React, {Component} from 'react';
import {BrowserRouter as Router} from 'react-router-dom';
import {IntlProvider} from "react-intl";
import locale_fr from "./locales/fr.json";
import locale_en from "./locales/en.json";
import { LangProvider } from "./LangContext";

import RouteConfig from "./routeConfig";
import {NotificationsProvider} from "./NotificationsContext";

class IntlProviderWrapper extends Component {

    switchToEnglish = () => {
        this.setState({ locale: "en", messages: locale_en });
    }

    switchToFrench = () => {
        this.setState({ locale: "fr", messages: locale_fr });
    }

    constructor(...args) {
        super(...args);

        const loadedLocale = window.location.pathname.split('/')[1] || "fr";
        const loadedMessages = (loadedLocale === 'en')?locale_en : locale_fr;
        const notifications = 0

        // pass everything in state to avoid creating object inside render method (like explained in the documentation)

        this.state = {
            locale: loadedLocale,
            messages: loadedMessages,
            notifications : notifications,
            switchToEnglish: this.switchToEnglish,
            switchToFrench: this.switchToFrench
        };
    }


    render() {
        const { locale, messages } = this.state;
        return (
            <>
                <Router>
                    <LangProvider value={this.state}>
                        <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale="fr">
                            <NotificationsProvider>
                                <RouteConfig locale={this.state.locale}/>
                            </NotificationsProvider>
                        </IntlProvider>
                    </LangProvider>
                </Router>
            </>
        );
    }
}

export {IntlProviderWrapper};
