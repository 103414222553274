import React, {Component} from 'react';
import LoadingComponent from "../../common/loadingComponent";
import {Redirect} from "react-router-dom";


class MatchSponsorPageContent extends Component {
    _isMounted = false;

    state = {
        RedirectToLoginPage:false,
        isLoading : false,
        locale : this.props.locale
    };

    componentDidMount() {
        this._isMounted = true;
        this.onLoadPage();
    }

    onLoadPage = async () =>{
        try {
            this.setState({isLoading:true});

            if (this._isMounted){
                this.setState({
                    isLoading: false
                });
            }

        }  catch(err)  {
            console.log("Error loading API")
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }

    renderPage = (matches) => {
        return(
            <>
                Match sponsor page for user ID {this.props.id}.
            </>
        )
    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.isLoading ? <LoadingComponent/> : this.renderPage(this.state.matches)}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MatchSponsorPageContent;