import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/configs";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getAllConfigs(){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint, {headers: {"x-auth-token" : jwt}})
}

export function getConfigsByNames(names){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/`, { params: {names}, headers: {"x-auth-token" : jwt}})
}

export function getPublicConfigsByNames(names){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/publicconfigs/`, { params: {names}, headers: {"x-auth-token" : jwt}})
}

export function setConfig(name, value){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const objectToSend = {value : value}
        return httpService.put(`${apiEndpoint}/${name}`, objectToSend, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function updateConfigs(configObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.put(`${apiEndpoint}/`, configObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function systemReset() {
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.delete(`${apiEndpoint}/`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}