import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import LoadMyPairing from "../contents/Pages/MyPairing/LoadMyPairing";

const MyPairingLayout = (locale) => {

    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <LoadMyPairing locale={locale.intl.locale} />
            </main>

            <Footer/>
        </>
    );
}

export default withRouter(injectIntl(MyPairingLayout));