import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import {UpdateCurrentSponsor, UpdateSponsor} from "../../../../services/sponsorService";
import {toast} from "react-toastify";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";
import authService from "../../../../services/authService";
import {UpdateIdulNotes} from "../../../../services/idulNotesService";
import ToastStdMessage from "../../common/htmlElements/ToastBoxes/ToastStdMessage";
import RichTextEditor from "react-rte";

import "./SponsorForm.css";
import {sendMail} from "../../../../services/mailsService";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class SponsorForm extends Form {

    currentSponsor = this.props.currentSponsor;

    createSelectItemsList = (ObjectList) => {
        //let returnObject = [{value: null, label:""}];
        let returnObject = [];

        // eslint-disable-next-line
        for (let [key, value] of Object.entries(ObjectList)) {
            returnObject.push({value: parseInt(value.id), label: value.name})
        }
        return returnObject;

    }

    state = {
        locale : this.props.locale,
        idulNotes : this.props.idulNotes?RichTextEditor.createValueFromString(this.props.idulNotes, 'html'):RichTextEditor.createEmptyValue(),
        facultyList : this.createSelectItemsList(this.props.facultyList),
        interestsList : this.createSelectItemsList(this.props.interestsList),
        languagesList : this.createSelectItemsList(this.props.languagesList),
        preferencesList : this.createSelectItemsList(this.props.preferencesList),
        countryList : this.createSelectItemsList(this.props.countryList),
        referencesList : this.createSelectItemsList(this.props.referencesList),
        sessionsList : this.createSelectItemsList(this.props.sessionsList),
        formationsList : this.createSelectItemsList(this.props.formationsList),
        sponsorLoadList : [{value:1,label:1}, {value:2,label:2}, {value:3,label:3}],
        sponsorStateList : [   //Modifier aussi components\contents\common\htmlElements\TableComponent\subTableComponents\CRUDTableBody.jsx
            {value: 1, label: "Nouvelle candidature"},
            {value: 2, label: "Candidature approuvée"},
            {value: 3, label: "Candidat désisté"},
            {value: 4, label: "Candidat non formé"},
            {value: 5, label: "Candidat refusé"},
            {value: 6, label: "Candidat exclus"},
            {value: 7, label: "À vérifier"},
            {value: 10, label: "Candidat approuvé et jumelé"}

        ],

        errors:{}
    }

    async setSponsorLoadList(){

        const maxSponsorLoad = this.props.sponsorLoad || 1;

        this.setState({
            sponsorLoadList: Array.from({ length: maxSponsorLoad }, (_, i) => ({ value: i + 1, label: i + 1 }))
        });
    }



    async componentDidMount() {
        await this.setSponsorLoadList();
    }



    constructor(props) {
        super(props);
        const currentSponsor = this.props.currentSponsor;
        const locale = this.props.locale;

        this.state.studyLevelList = [
            {value: 0, label: (locale === "fr")?"Premier cycle (certificat, baccalauréat)":"Undergraduate (certificate, bachelor)"},
            {value: 1, label: (locale === "fr")?"Deuxième cycle (maîtrise, DESS)":"Graduate program (masters, DESS)"},
            {value: 2, label: (locale === "fr")?"Troisième cycle (doctorat)":"Post-graduate program (doctorate)"}
        ];
        this.state.genderList = [
            {value: 0, label : (locale === "fr")?"Femme":"Female"},
            {value: 1, label : (locale === "fr")?"Homme":"Male"},
            {value: 2, label : (locale === "fr")?"Je préfère ne pas répondre":"I prefer not to answer"}
        ];

        this.state.sendButtonDisabled = false;

        this.state.data = {
            id : currentSponsor.id,
            state: currentSponsor.state,
            idul : currentSponsor.idul,
            ni : currentSponsor.ni,
            firstName : currentSponsor.firstName,
            lastName : currentSponsor.lastName,
            originCountry_id: currentSponsor.originCountry_id, //[{"value":1,"label":"Inconnue"}]
            birthdate : currentSponsor.birthdate,
            gender: currentSponsor.gender, //[{"value":1,"label":"Inconnue"}]
            emailUlaval : currentSponsor.emailUlaval,
            emailOther : currentSponsor.emailOther,
            phone : currentSponsor.phone,
            phoneOther : currentSponsor.phoneOther,
            messenger: currentSponsor.messenger,
            formationDate : currentSponsor.formationDate,
            formation_id : currentSponsor.formation_id,
            availabilityDate : currentSponsor.availabilityDate,
            firstSession_id : currentSponsor.firstSession_id,
            sponsorLoad : currentSponsor.sponsorLoad,
            faculty_id: currentSponsor.faculty_id,
            studyLevel: currentSponsor.studyLevel, //[{"value":1,"label":"Inconnue"}]
            isVeteran : currentSponsor.isVeteran?true:false,
            languagePrimary_id: currentSponsor.languagePrimary_id,
            languageOther: currentSponsor.languageOther,
            UnderstandingFrench : currentSponsor.UnderstandingFrench?true:false,
            interests : currentSponsor.interests,
            preferences : currentSponsor.preferences,
            references : currentSponsor.references,
            comments : currentSponsor.comments?currentSponsor.comments:"",

            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        state: Joi.number(),
        idul: Joi.string().max(10).required(),
        ni: Joi.string().max(10).regex(/^\d+$/).required(),
        firstName : Joi.string().max(50).required(),
        lastName : Joi.string().max(50).required(),
        originCountry_id : Joi.number().required(),
        birthdate : Joi.date().format("YYYY-MM-DD").required(),
        gender : Joi.number().required(),
        emailUlaval : Joi.string().max(100).regex(/^(.+)([uU][lL][aA][vV][aA][lL].[cC][aA])$/),
        emailOther : Joi.string().max(100).email({ tlds: {allow: false} }).allow(null),
        phone : Joi.string().max(30).allow(null),
        phoneOther : Joi.string().max(30).allow(null),
        messenger : Joi.string().max(50).allow(null),
        formationDate : Joi.date().format("YYYY-MM-DD").allow('',null),
        formation_id : Joi.number().when('isVeteran', {
            is: true,
            then: Joi.number().allow(null)
        }),
        availabilityDate : Joi.date().format("YYYY-MM-DD").required(),
        firstSession_id : Joi.number().required(),
        sponsorLoad : Joi.number().required(),
        isVeteran : Joi.boolean().allow(null),
        faculty_id : Joi.number().required(),
        studyLevel : Joi.number().required(),
        languagePrimary_id : Joi.number().required(),
        languageOther : Joi.array().items(Joi.number()).allow(null),
        UnderstandingFrench: Joi.boolean().invalid(false),
        interests : Joi.array().items(Joi.number()).allow(null),
        preferences : Joi.array().items(Joi.number()).allow(null),
        references : Joi.array().items(Joi.number()).allow(null),
        comments : Joi.string().max(1000).allow('',null),
        sponsorLoadCurrent : Joi.number().allow(null),
        inChargeOfClientsID : Joi.array().items(Joi.number()).allow(null),
        inChargeOfClientsNames : Joi.string().allow(null)
    };

    updateObjectUsers = (userObject) => {
        const updatedUsers = this.props.usersList.map((user) => {
            if (user.id === userObject.id) {
                return { ...user, ...userObject };
            }
            return user;
        });
        this.props.setUsersList(updatedUsers);
    };

    removeMatchFromSponsor = (userObject) => {
        return {...userObject, sponsorLoadCurrent:0, inChargeOfClientsID:[], inChargeOfClientsNames:""}
    }

    createMailObject = (userObject) => {
        return {mailTemplate: "PMInscrits", to: userObject.emailUlaval + ", " + userObject.emailOther, cci: "",}
    }

    doSubmit = async e => {
        const { formatMessage } = this.props.intl;
        const SAME_GENDER_PREF_ID = 1;
        const GENDER_UNDEFINED_VALUE = 2;

        const APPROVED_STATE = 2;
        const APPROVED_AND_MATCHED_STATE = 10;
        const doNotDeleteMatchStatus = [APPROVED_AND_MATCHED_STATE, APPROVED_STATE];


        this.setState({sendButtonDisabled:true})
        let userObject = {...this.state.data};
        userObject.active = true;
        if(this.state.data.isVeteran) userObject.formation_id = null;

        delete userObject.errors;

        if (!doNotDeleteMatchStatus.includes(userObject.state)){
            userObject = this.removeMatchFromSponsor(userObject);
        }

        const showGenderWarningMessage = (userObject.gender === GENDER_UNDEFINED_VALUE
            &&
            userObject.preferences?.includes(SAME_GENDER_PREF_ID));


        if(showGenderWarningMessage){
            toast.error(<ToastStdMessage title={formatMessage({id: "toast.error.gender.sameGenderPrefUnavailable.title"})}
                                         message={formatMessage({id: "toast.error.gender.sameGenderPrefUnavailable.content"})}
                                         icon="slash-square"
                        />, {autoClose:false});
            this.setState({sendButtonDisabled:false});
        }
        else{
            try{
                if (this.props.isAdminForm) {
                    await UpdateSponsor(userObject);
                    await UpdateIdulNotes({
                                                idul:this.currentSponsor.idul,
                                                note:this.state.idulNotes.toString('html')
                                            });
                }
                else if (this.currentSponsor.date_registered === null) {
                    const mailObject = this.createMailObject(userObject);
                    await UpdateCurrentSponsor(userObject);
                    await sendMail(userObject, mailObject);
                }
                else {
                    await UpdateCurrentSponsor(userObject);
                }

                if(this.props.setEditSponsorWindowState){
                    this.props.setEditSponsorWindowState(false);
                    this.updateObjectUsers(userObject);
                }
                else{
                    this.props.history.push(`/${this.props.locale}/homepage#SponsorCard`);
                }
            }catch(err){
                console.error(err.message);
            }
        }

    };

    renderFormationField = (isAdmin) => {
        return (
            <>
                {this.renderInputDropDown("formation_id", "sponsor.form.formation_id", this.state.formationsList,false,true)}
            </>
        );
    }

    handleIdulNotesChange = (idulNotes) =>{
        this.setState({
            idulNotes: idulNotes
        });
    }


    render() {
        //console.log(JSON.stringify(this.state.data));
        const user = authService.getCurrentUserToken();
        const { formatMessage } = this.props.intl;
        const isAdmin = user?user.isAdmin:null;
        const showDeleteMatchWarning = this.props.currentSponsor.inChargeOfClientsID && this.state.data.state !== 2;
        const showWarningBox1 = "WarningMessage".concat(showDeleteMatchWarning?"Enabled":"Disabled");

        const STATE_APPROVED = 2
        const STATE_MATCHED = 10
        const stateList = this.props.currentSponsor.inChargeOfClientsID?
            this.state.sponsorStateList.filter(function( obj ) {
                return obj.value !== STATE_APPROVED;
            })
            :
            this.state.sponsorStateList.filter(function( obj ) {
            return obj.value !== STATE_MATCHED;
        });

        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="sponsor.form.Title"/></h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit} style={{marginBottom:50}}>
                        {isAdmin &&
                            <>
                                <div style={{backgroundColor: "#c0c0c0", padding:20, marginTop:20,marginBottom:20}}>
                                    <h2>Section administrative</h2>

                                    {this.props.currentSponsor.inChargeOfClientsID &&
                                    <div className="matchedBox">
                                        <img src={require('./images/sponsoredIcon.png')} className="sponsoredImage" alt="Matched"/>
                                        <p>Jumelé à:
                                            <span dangerouslySetInnerHTML={{__html: this.props.currentSponsor.inChargeOfClientsNames}} /></p>
                                    </div>
                                    }

                                    {this.renderInputDropDown("state", "sponsor.form.state", stateList, false)}
                                    {this.renderDatePickerField("formationDate", "sponsor.form.formationCompletedOn", false, null, "+1y","-15:+1y",)}
                                    {!this.state.data.isVeteran && this.renderFormationField(1)}

                                    <p className={showWarningBox1}>{showDeleteMatchWarning && `ATTENTION ! Le ou les matchs seront supprimés lors de la sauvegarde si l'état de candidature n'est plus à "Candidature approuvé" ou "Candidat approuvé et jumelé"!`}</p>

                                    <div className="row">
                                        <div className="col-md-4">Notes administratives</div>
                                        <div className="col-md-8">
                                            <RichTextEditor
                                                value={this.state.idulNotes}
                                                onChange={this.handleIdulNotesChange}
                                            />
                                        </div>
                                    </div>


                                    <button disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                                        {this.state.sendButtonDisabled ? <FormattedMessage id="sponsor.form.savingChanges"/> : <FormattedMessage id="sponsor.form.saveChanges"/>}
                                    </button>
                                </div>

                            </>
                        }

                        <h2><FormattedMessage id="sponsor.form.Identification"/></h2>
                            {this.renderInput("ni", "sponsor.form.ni", false, "text", null, null, true)}

                            {this.renderInputViewOnly("idul", "sponsor.form.idul")}
                            {this.renderInputViewOnly("firstName", "sponsor.form.firstName")}
                            {this.renderInputViewOnly("lastName", "sponsor.form.lastName")}


                            {this.renderDatePickerField("birthdate", "sponsor.form.birthdate", false,null, "-15y","-75:-15",true)}
                            {this.renderInputDropDown("originCountry_id", "sponsor.form.originCountry_id", this.state.countryList,false,true)}
                            {this.renderInputDropDown("gender", "sponsor.form.gender", this.state.genderList,false,true)}
                        <h2><FormattedMessage id="sponsor.form.ContactInQuebec"/></h2>
                            {this.renderInput("emailUlaval", "sponsor.form.emailUlaval", false, "text", null, null, true)}
                            {this.renderInput("emailOther", "sponsor.form.emailOther")}
                            {this.renderInput("phone", "sponsor.form.phone", false, "text", null, null, false)}
                            {this.renderInput("phoneOther", "sponsor.form.phoneOther")}
                            {this.renderInput("messenger", "sponsor.form.messenger")}
                        <h2><FormattedMessage id="sponsor.form.Studies"/></h2>
                            {this.renderInputDropDown("firstSession_id", "sponsor.form.firstSession", this.state.sessionsList,false,true)}
                            {this.renderInputDropDown("faculty_id", "sponsor.form.faculty_id", this.state.facultyList,false,true)}
                            {this.renderInputDropDown("studyLevel", "sponsor.form.studyLevel", this.state.studyLevelList,false,true)}
                        <h2><FormattedMessage id="sponsor.form.Language"/></h2>
                            {this.renderInputDropDown("languagePrimary_id", "sponsor.form.languagePrimary_id", this.state.languagesList,false,true)}
                            {this.renderInputDropDown("languageOther", "sponsor.form.languageOther", this.state.languagesList, true)}
                            {this.renderCheckBox("UnderstandingFrench", "sponsor.form.UnderstandingFrench", false, true)}
                        <h2><FormattedMessage id="sponsor.form.ForeignStudent"/></h2>
                            <div><FormattedMessage id="sponsor.form.preferenceWarning"/></div><br/>
                            {this.renderInputDropDown("preferences", "sponsor.form.preferences", this.state.preferencesList, true)}
                            {this.renderInputDropDown("sponsorLoad", "sponsor.form.sponsorLoad", this.state.sponsorLoadList,false,true)}
                            {this.renderInputDropDown("interests", "sponsor.form.interests", this.state.interestsList, true)}
                        <h2><FormattedMessage id="sponsor.form.Interests"/></h2>
                            {this.renderDatePickerField("availabilityDate", "sponsor.form.availabilityDate", false, 0, "+6m","-15:+1y",true)}
                            {this.renderCheckBox("isVeteran", "sponsor.form.isVeteran")}
                            {!isAdmin && !this.state.data.isVeteran && this.renderFormationField()}

                        <h2><FormattedMessage id="client.form.Other"/></h2>
                            {this.renderInputDropDown("references", "sponsor.form.references", this.state.referencesList, true)}
                            {this.renderTextArea("comments", "sponsor.form.comments", true)}

                        <div className="collecteRenseignementsPerso">
                            <h2><FormattedMessage id="sponsor.form.collecteRenseignementsPerso.Title"/></h2>
                            <p><div dangerouslySetInnerHTML={{__html: formatMessage({id:"sponsor.form.collecteRenseignementsPerso.Message"})}}/></p>
                        </div>

                        <button disabled={this.state.sendButtonDisabled} className="btn btn-primary submit-btn">
                            {this.state.sendButtonDisabled ? <FormattedMessage id="sponsor.form.savingChanges"/> : <FormattedMessage id="client.form.saveChanges"/>}
                        </button>

                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(SponsorForm));
