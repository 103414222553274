/* const { windowWidth, windowHeight } = getWindowWidthAndHeight(); */
export const getWindowWidthAndHeight = () => {
    const w = window,
        d = document,
        e = d.documentElement,
        g = d.getElementsByTagName("body")[0],
        windowWidth = w.innerWidth || e.clientWidth || g.clientWidth,
        windowHeight = w.innerHeight || e.clientHeight || g.clientHeight;
    return {
        windowWidth,
        windowHeight: windowHeight
    };
};