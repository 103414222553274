import React, {Component} from 'react';
import LoadingComponent from "../../common/loadingComponent";
import {Redirect} from "react-router-dom";
import {getClientByID} from "../../../../services/clientService";
import {getCurrentMatchForID, getPossibleMatchesForID, saveMatch, deleteMatchFromClientID} from "../../../../services/matchUsersService";
import FicheMatchUser from "../../dataDisplay/FicheMatchUser";
import httpService from "../../../../services/httpService";
import {getCountryList} from "../../../../services/countryService";
import {getFacultyList} from "../../../../services/facultyService";
import {getInterestsList} from "../../../../services/interestsService";
import {getLanguagesList} from "../../../../services/languagesService";
import {getPreferencesList} from "../../../../services/preferencesService";
import './MatchClientPageContent.css';
import FilterMatchSelectionMenu from "../../Tables/FilterMatchSelectionMenu";
import moment from "moment";
import {getSessionsList} from "../../../../services/sessionsService";

class MatchClientPageContent extends Component {
    _isMounted = false;

    state = {
        searchType: "suggested",
        clientID : this.props.clientID,
        matches:[],
        RedirectToLoginPage:false,
        client: {},
        tempSelectedSponsor : {},
        selectedSponsor : null,
        availabilityDate : null,
        hideFullSponsors : true,
        userIsMatched: false,
        countryList : {},
        facultyList : {},
        interestsList : {},
        studyLevelList : [{id:0, name: "Premier cycle (certificat, baccalauréat)"},{id:1, name: "Deuxième cycle (Maîtrise, DESS)"},{id:2, name: "Troisième cycle (Doctorat)"}],
        sessionsList : {},
        languagesList : {},
        preferencesList : {},
        showMatchLimit : 500,
        isLoading : true,
        locale : this.props.locale
    };


    constructor(props) {
        super(props);
        this.state.availabilityDate = moment().format("YYYY-MM-DD")
    }


    componentDidMount() {
        this._isMounted = true;
        this.onLoadPage();
    }




    onLoadPage = async () =>{
        this.setState({isLoading:true});

        try {
            this.setState({isLoading:true});
            const SPONSORS_NOT_FULLY_LOADED_STATE_VALUE = 3;

            const [
                {data: countryList},
                {data: client},
                {data: matches},
                {data: selectedSponsor},
                {data: facultyList},
                {data: interestsList},
                {data: sessionsList},
                {data: languagesList},
                {data: preferencesList}
            ] = await httpService.all([
                getCountryList('fr'),
                getClientByID(this.state.clientID),
                getPossibleMatchesForID("clients", this.state.clientID, this.state.showMatchLimit, (this.state.searchType === "suggested")?true:false, this.state.availabilityDate,SPONSORS_NOT_FULLY_LOADED_STATE_VALUE),
                getCurrentMatchForID(this.state.clientID),
                getFacultyList('fr'),
                getInterestsList('fr'),
                getSessionsList('fr'),
                getLanguagesList('fr'),
                getPreferencesList('fr')
            ]);


            if (this._isMounted){
                this.setState({
                    countryList,
                    client,
                    matches,
                    selectedSponsor,
                    tempSelectedSponsor : selectedSponsor,
                    userIsMatched : selectedSponsor?true:false,
                    facultyList,
                    interestsList,
                    sessionsList,
                    languagesList,
                    preferencesList,
                    isLoading: false
                });
            }

        }  catch(err)  {
            console.log("Error loading API")
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }

    handleClickMatchFile = (e) => {
        this.setState({tempSelectedSponsor: e.currentTarget.dataset})
    }

    handleHideFullSponsors = (e) => {
        this.setState({hideFullSponsors : e.currentTarget.checked})
    }

    handleResultsLimitChange = (e) => {
        this.setState({showMatchLimit : e.currentTarget.value})
        console.log("New result limit : " + e.currentTarget.value)
    }

    handleSearchTypeChange = (e) => {
        this.setState({searchType : e.currentTarget.value})
        console.log("New search type : " + e.currentTarget.value)
    }

    handleAvailabilityDateChange = (e) => {
        this.setState({availabilityDate : e.currentTarget.value})
    }

    handleLaunchSearch = async () => {
        try {
            this.setState({isLoading:true});
            // const {data : matches} = await getPossibleMatchesForID("clients",
            //                                                 this.state.clientID,
            //                                                 this.state.showMatchLimit,
            //                                             (this.state.searchType === "suggested")?true:false,
            //                                                 this.state.availabilityDate,
            //                                                 this.state.hideFullSponsors);
            let {data: matches} = await getPossibleMatchesForID("clients", this.state.clientID, this.state.showMatchLimit, (this.state.searchType === "suggested")?true:false, this.state.availabilityDate, this.state.hideFullSponsors?3:0);
            this.setState({
                matches,
                isLoading: false}
                )
        }
        catch(err) {
            console.log("Error loading API")
            if (this._isMounted) {
                this.setState({isLoading: false, RedirectToLoginPage: true});
            }
        }
    }

    updateMatchUser = (deleting) => {
        const updatedUsers = this.props.usersList.map((user) => {
            if (user.id === this.state.clientID) {
                if(!deleting)
                {
                    return { ...user, takenInChargeByID: this.state.selectedSponsor.id, takenInChargeByName: this.state.selectedSponsor.firstName + " " + this.state.selectedSponsor.lastName};
                }
                return { ...user, takenInChargeByID: null, takenInChargeByName: null };
            }
            return user;
        });
        this.props.setUsersList(updatedUsers);
    }



    handleSaveMatch = async () => {
        //console.log("Saving Match: " + this.state.clientID + " with " + this.state.tempSelectedSponsor.id)
        const matchObject = {
            clientID: this.state.clientID,
            sponsorID: this.state.tempSelectedSponsor.id
        }

            try{
            // console.log(this.props.usersList);»
                this.setState({isLoading:true});
                await saveMatch(matchObject);
                this.setState({userIsMatched : true})
                const {data:currentMatch} = await getCurrentMatchForID(this.state.clientID);
                this.setState({selectedSponsor : currentMatch})
                await this.handleLaunchSearch();
                this.setState({isLoading:false});
                if(this.props.setMatchClientWindowState){
                    this.updateMatchUser(false);
                }
                this.props.setMatchClientWindowState(false);
            } catch (e){
                this.setState({isLoading:false});
            }

    }

    handleDeleteMatch = async () => {

        try{
            this.setState({isLoading:true});
            await deleteMatchFromClientID(this.state.clientID);
            this.props.deleteMatchInUserListState(this.state.clientID);
            this.setState({userIsMatched:false});
            this.setState({selectedSponsor:null});
            this.setState({tempSelectedSponsor:{}});
            await this.handleLaunchSearch();
            this.setState({isLoading:false});
            if(this.props.setMatchClientWindowState){
                this.updateMatchUser(true);
            }
            this.props.setMatchClientWindowState(false);
        }catch(err){
            this.setState({isLoading:false});
        }
    }


    renderPage = () => {
        const userMatches = this.state.matches.length?this.state.matches: {};
        const userMatchesLength = Object.keys(userMatches).length;
        const maxedOut = userMatchesLength === this.state.showMatchLimit;
        const currentClient = this.state.client;

        if (userMatches != null){
            return(
                <>

                    <FilterMatchSelectionMenu
                        selectedSponsor = {this.state.selectedSponsor}
                        resultsLimit = {this.state.showMatchLimit}
                        searchType = {this.state.searchType}
                        availabilityDate = {this.state.availabilityDate}
                        hideFullSponsors = {this.state.hideFullSponsors}
                        onResultsLimitChange = {this.handleResultsLimitChange}
                        onSearchTypeChange = {this.handleSearchTypeChange}
                        onHideFullSponsors = {this.handleHideFullSponsors}
                        onLaunchSearch = {this.handleLaunchSearch}
                        onAvailabilityDateChange = {this.handleAvailabilityDateChange}
                        facultyList = {this.state.facultyList}
                        countryList = {this.state.countryList}
                    />



                    <div className="container" >
                        <div className="row">
                            <div className="col-md-12 searchMatchFooter">
                                Affichage de {Object.keys(userMatches).length} éléments. {maxedOut?<div style={{color: "red"}}>ATTENTION ! Il est possible d'obtenir plus de résultat en augmentant la limite de résultats de recherche!</div>:<span></span>}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-md-6">
                                <div className="sticky-top">
                                    <button
                                        onClick={this.handleSaveMatch}
                                        disabled={this.state.tempSelectedSponsor?false:true}
                                        className="btn btn-primary saveMatchButton"
                                    >
                                        Sauvegarder le jumelage
                                    </button>

                                    <button
                                        onClick={this.handleDeleteMatch}
                                        disabled={this.state.userIsMatched?false:true}
                                        className="btn btn-danger saveMatchButton"
                                        style={{marginLeft:10}}
                                    >
                                        Supprimer le jumelage
                                    </button>

                                    <FicheMatchUser
                                        affix={true}
                                        data={currentClient}
                                        facultyList={this.state.facultyList}
                                        countryList={this.state.countryList}
                                        languageList={this.state.languagesList}
                                        preferencesList={this.state.preferencesList}
                                        interestsList={this.state.interestsList}
                                        sessionsList={this.state.sessionsList}
                                        studyLevelList={this.state.studyLevelList}
                                    />

                                </div>

                            </div>

                            <div className="col-md-6">
                                <ul className="matchFiles">
                                    {
                                        userMatches.length &&
                                        userMatches.map((value, key) => (
                                            <li key={key} className="matchFile" onClick={this.handleClickMatchFile} data-id={value.id}>
                                                <FicheMatchUser
                                                    selectedID={this.state.tempSelectedSponsor.id}
                                                    data={value}
                                                    facultyList={this.state.facultyList}
                                                    countryList={this.state.countryList}
                                                    languageList={this.state.languagesList}
                                                    preferencesList={this.state.preferencesList}
                                                    interestsList={this.state.interestsList}
                                                    sessionsList={this.state.sessionsList}
                                                    studyLevelList={this.state.studyLevelList}
                                                />
                                            </li>
                                        ))
                                    }
                                </ul>
                            </div>
                        </div>
                    </div>
                </>
            )
        }
        else {
            return(
                <>
                    Aucun match disponible.
                </>
                )

        }

    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default MatchClientPageContent;