import React from 'react';
import {NavLink} from "react-router-dom";
import {FormattedMessage, useIntl} from "react-intl";
import "./NavigationBar2023.css";
import PropTypes from "prop-types";
import authService from "../../../../services/authService";
import {useNotifications} from "../../../../Hooks/useNotifications";

const NavigationBar2023 = ({mobile, enabled, setCurrentActiveMenu}) => {

    const intl = useIntl();
    const user = authService.getCurrentUserToken();

    let { notifications } = useNotifications();

    if (notifications===0) notifications = null;

    const username = user?user.idul:null;
    const isAdmin = user?user.isAdmin:null;
    const isMatched = user?user.isMatched:null;

    let menuReturned = <></>;



    if (enabled)
        mobile?
            menuReturned =
                <>
                    <nav className="header-main-nav header-main-nav--mobile" aria-label="Principale">
                        <ul className="menu">
                            {user &&
                                <>
                                    <li className="menu-item">
                                        <a className="menu-link" href={`/${intl.locale}/homepage`}><FormattedMessage id="navigation1.homePage"/></a>
                                        {/*<NavLink to={`/${intl.locale}/homepage`} activeClassName="is-active" className="menu-link">
                                            <FormattedMessage id="navigation1.homePage"/>
                                        </NavLink>*/}
                                    </li>
                                    <li className="menu-item">
                                        <a className="menu-link" href={`/${intl.locale}/documents`}><FormattedMessage id="navigation1.documents"/></a>
                                        {/*<NavLink to={`/${intl.locale}/documents`} activeClassName="is-active"
                                                 className="menu-link">
                                            <FormattedMessage id="navigation1.documents"/>
                                        </NavLink>*/}
                                    </li>
                                </>
                            }
                            {isMatched &&
                                <>
                                    <li className="menu-item">
                                        <a className="menu-link" href={`/${intl.locale}/my-pairing`}><FormattedMessage id="navigation1.myPairing"/></a>

                                        {/*<NavLink to={`/${intl.locale}/my-pairing`} className="menu-link"
                                                 activeClassName="selected">
                                            <FormattedMessage id="navigation1.myPairing"/><span
                                            style={{color: "#e30513"}}>{notifications && `(${notifications})`}</span>
                                        </NavLink>*/}
                                    </li>
                                </>
                            }

                        </ul>
                    </nav>
                </>
            :
            menuReturned =
                <>
                    <nav className="header-main-nav header-main-nav--desktop" aria-label="Navigation principale">
                        <ul className="menu">
                            {user &&
                                <>
                                    <li className="menu-item">
                                        <NavLink to={`/${intl.locale}/homepage`} className="menu-link"
                                                 activeClassName="is-active">
                                            <FormattedMessage id="navigation1.homePage"/>
                                        </NavLink>
                                    </li>
                                    <li className="menu-item">
                                        <NavLink to={`/${intl.locale}/documents`} className="menu-link"
                                                 activeClassName="is-active">
                                            <FormattedMessage id="navigation1.documents"/>
                                        </NavLink>
                                    </li>
                                </>
                            }

                            {isMatched &&
                                <>
                                    <li className="menu-item">
                                        <NavLink to={`/${intl.locale}/my-pairing`} className="menu-link"
                                                 activeClassName="is-active">
                                            <FormattedMessage id="navigation1.myPairing"/>
                                        </NavLink><span className="notifications">{notifications && `(${notifications})`}</span>
                                    </li>
                                </>
                            }

                            {isAdmin &&
                                <>
                                    <li className="menu-item">
                                        <NavLink to={`/${intl.locale}/admin`} className="menu-link"
                                                 activeClassName="is-active">
                                            <FormattedMessage id="navigation1.AdministrationMenu"/>
                                        </NavLink>
                                    </li>
                                </>
                            }
                        </ul>
                    </nav>
                </>

    return menuReturned;


}


export default NavigationBar2023;


NavigationBar2023.propTypes = {
    mobile: PropTypes.bool,
    enabled: PropTypes.bool
}

NavigationBar2023.defaultProps = {
    mobile: false,
    enabled : true
};