import React from 'react';
import ReactTooltip from 'react-tooltip';
import {injectIntl} from "react-intl";
import "./Input.css"

const Input = ({error, name, label, longField, units, intl, value, tooltip, mandatory, ...rest}) => {

    const {formatMessage} = intl;

    value = (value !== null) ? value : ""
    let totalInputClass = "form-control input-sm"
    totalInputClass = longField?totalInputClass.concat(" longInputField"):totalInputClass
    totalInputClass = units?totalInputClass.concat(" hasUnits"):totalInputClass



    return (
        <div className="form-group">
            <div className="row">

                <ReactTooltip />

                <div className="col-md-4">
                    <label htmlFor={name} className="leftSideLabel">
                        {formatMessage({id:label})}
                        {mandatory?<span className="mandatoryInput">*</span>:""}
                    </label>
                    {(tooltip)?<span data-tip={tooltip} data-multiline="multiline" className="badge badge-primary badge-tooltip">i</span>:""}
                </div>
                <div className="col-md-5">
                    <input
                        {...rest}
                        value = {value}
                        id={name}
                        name={name}
                        className={totalInputClass}
                    />
                    {units? <span className={"inputUnits"}>{formatMessage({id:units})}</span>:null}

                </div>
                <div className="col-md-3">
                    {error && <div className="alert alert-danger"><span className="validationCustomMessage">{formatMessage({id:error.msg})}</span></div>}
                </div>
            </div>

        </div>
    );
}

export default injectIntl(Input);
