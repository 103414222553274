import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {getPublicConfigsByNames} from "../../../../../services/configsService";
import {getMyClientsBasicInfos} from "../../../../../services/sponsorService";
import './MySponsorPairing.css';
import moment from "moment";
import WhiteBoxContent from "../../../common/htmlElements/WhiteBoxContent/WhiteBoxContent";
import {confirmStatus, updateContactRequest} from "../../../../../services/matchUsersService";
import {confirmAlert} from "react-confirm-alert";
import {useNotifications} from "../../../../../Hooks/useNotifications";
import {sendReminder} from "../../../../../services/mailsService";

const MySponsorPairing = (props) => {
    const intl = useIntl();
    const notifications = useNotifications();

    const [isLoading, setIsLoading] = useState(false);
    const [introText, setIntroText] =useState(null);
    const [confirmationText, setConfirmationText] =useState(null);
    const [clientBasicInfosList, setClientBasicInfosList] = useState([]);
    const [fichesClients, setFichesClients] = useState(null);
    const [studyLevelList, setStudyLevelList] = useState([{id:0, name: "Premier cycle (certificat, baccalauréat)"},{id:1, name: "Deuxième cycle (Maîtrise, DESS)"},{id:2, name: "Troisième cycle (Doctorat)"}]);
    const [mailData,setMailData] = useState({})

    async function fetchClientsBasicInfosForSponsorID (){
        try {
            const clients = await getMyClientsBasicInfos();
            const clientBasicInfos = Object.values(clients.data);
            setClientBasicInfosList(clientBasicInfos)
        } catch (error) {
            console.error("Une erreur s'est produite lors de la récupération de la liste des clients pour le sponsor :", error);
        }
    }

    useEffect(() => {
        async function fetchConfigs() {
            try {
                const config = await getPublicConfigsByNames(["welcomePairingPM" + intl.locale, "congratulationPairing" + intl.locale]);
                const values = config.data;
                setIntroText(values[`welcomePairingPM${intl.locale.toUpperCase()}`]);
                setConfirmationText(values[`congratulationPairing${intl.locale.toUpperCase()}`]);
            } catch (error) {
                console.error("Une erreur s'est produite lors de la récupération des configurations :", error);
            }
        }

        async function fetchData(){
            await fetchConfigs();
            await fetchClientsBasicInfosForSponsorID();
        }

        fetchData();
    }, []);


    useEffect(() => {

        if (Object.keys(clientBasicInfosList).length !== 0)
        {
            setFichesClients(renderFichesClients(clientBasicInfosList));
        }

    }, [clientBasicInfosList]);


    useEffect(async () => {
        const sendMail = async () => {

            try{
                await sendReminder(mailData);
            }catch(e){
                console.error("Error sending contact attempt mail: " + e.message)
            }
        }

        if(Object.keys(mailData).length) {
            sendMail();
        }


    }, [mailData]);


    const handleConfirmContact = async (clientID) => {

        const doConfirmContact = async () =>{
            await confirmStatus(clientID, 2);
            await fetchClientsBasicInfosForSponsorID();
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">{intl.formatMessage({id: "myPairing.sponsor.firstContactAlertConfirmation"})}</p>
                        <button className="redButton" onClick={async () => {await doConfirmContact();onClose();}}>{intl.formatMessage({id: "myPairing.client.firstContactConfirmation.Yes"})}</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>{intl.formatMessage({id: "myPairing.client.firstContactConfirmation.No"})}</button>
                    </div>
                );
            }
        });
    };

    const handleContactAttempt = async (client) => {

        const doConfirmContactAttempt = async () =>{

            try {
                setIsLoading(true);
                //console.log("Sending mail with data:" + JSON.stringify(client));
                setMailData({
                    clientID: client.clientID,
                    contactEmailUlaval: "",
                    contactEmailOther: "",
                    contactAttempt: client.contactAttempt,
                });
                await updateContactRequest("sponsor","contactRequest", client.clientID);
                await fetchClientsBasicInfosForSponsorID();
                notifications.setNotification(0);
            } catch (error) {
                console.error(error.message);
            } finally {
                setIsLoading(false);
            }

        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">{intl.formatMessage({id: "myPairing.AddContactAttempt.message"}, {firstname: client?.firstName})}</p>
                        <button className="redButton" onClick={async () => {await doConfirmContactAttempt();onClose();}}>{intl.formatMessage({id: "myPairing.client.firstContactConfirmation.Yes"})}</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>{intl.formatMessage({id: "myPairing.client.firstContactConfirmation.No"})}</button>
                    </div>
                );
            }
        });
    };

    const renderFiche = (client) => {

        const DELAY_HOURS = 72;
        const dateActuelle = moment();
        const dateDernierContact = client.lastContactAttempt?moment(client.lastContactAttempt):moment(client.matchDate);
        const differenceHeures = dateActuelle.diff(dateDernierContact, 'hours' );
        const activerBoutonsContact = (dateDernierContact._isValid===false) ? true : (differenceHeures >= DELAY_HOURS);
        const cacherNonPremiereTentative = !((client.contactAttempt===0) && (differenceHeures >= DELAY_HOURS));
        const waitTime = DELAY_HOURS - differenceHeures;

        let traductionsStrings = {
            genderString : "",
            ageString : "",
            noneProvidedString : "",
        }



        switch (intl.locale){
            case "fr":
                traductionsStrings.genderString = (client?.gender)?"Homme":"Femme";
                traductionsStrings.ageString = " ans";
                traductionsStrings.noneProvidedString = "Non spécifié";
                break;
            case "en":
                traductionsStrings.genderString = (client?.gender)?"Male":"Female";
                traductionsStrings.ageString = " years old";
                traductionsStrings.noneProvidedString = "None provided";
                break;
            default:
                break;
        }

        const maxAttemptReached = client.contactAttempt >= 3;
        const matchConfirmed = client?.confirmationStatus >= 2;

        let buttonLink1 = async()=>{await handleConfirmContact(client.clientID)};
        let buttonLink2 = async ()=>{await handleContactAttempt(client)};

        const displayMaxReachAttemptedMessage = !matchConfirmed && maxAttemptReached;
        const displayConfirmationButtons = (!matchConfirmed && activerBoutonsContact) || (!matchConfirmed && maxAttemptReached);
        const displayWaitToConfirmMessage = !maxAttemptReached && !matchConfirmed && !activerBoutonsContact;
        const displayConfirmedMatchMessage = matchConfirmed;
        let displayMyPairingFirstInstructionsMessage = false;

        let firstContactMessage = "";
        switch(true){
            case (client.contactAttempt === 0):
                firstContactMessage = intl.formatMessage({ id: "myPairing.sponsor.firstContactMessage" } , {firstname: client?.firstName});
                if(cacherNonPremiereTentative) {
                    displayMyPairingFirstInstructionsMessage = true
                    buttonLink2 = null;
                }
                break;
            case (client.contactAttempt === 1):
                firstContactMessage = intl.formatMessage({ id: "myPairing.sponsor.secondContactMessage" } , {firstname: client?.firstName});
                break;
            case (client.contactAttempt === 2):
                firstContactMessage = intl.formatMessage({ id: "myPairing.sponsor.thirdContactMessage" } , {firstname: client?.firstName});
                break;
            case (client.contactAttempt > 2):
                firstContactMessage = intl.formatMessage({ id: "myPairing.sponsor.finalContactMessage" } , {firstname: client?.firstName});
                buttonLink2 = null;
                break;
        }

        const link1={
            link:buttonLink1,
            linkIcon:"",
            linkText:intl.formatMessage({ id: "myPairing.sponsor.firstContactConfirmation.Yes" })
        }

        const link2={
            link:buttonLink2,
            linkIcon:"",
            linkText:intl.formatMessage({ id: "myPairing.sponsor.firstContactConfirmation.No" })
        }

        return <>
            <div className="fichePairingClient">
                <div className="infoxJumelage">
                    <h2>{client?.firstName} {client?.lastName}</h2>

                    <div className="row">
                        <div className="col-md-6 infoBlock">
                            <h3>{intl.formatMessage({id: "myPairing.sponsor.firstContactPersonInfos"})}</h3>
                            <span className="gender"><label>{intl.formatMessage({id: "client.form.gender"})}:</label>{traductionsStrings.genderString}</span>
                            <span className="age"><label>{intl.formatMessage({id: "client.form.age"})}:</label>{moment().diff(client?.birthdate, 'years')} {traductionsStrings.ageString}</span>
                            <span className="language"><label>{intl.formatMessage({id: "client.form.languagePrimary_id"})}:</label>{client?.languagePrimary}</span>
                            <span className="country"><label>{intl.formatMessage({id: "client.form.originCountry_id"})}:</label>{client?.originCountry}</span>
                        </div>

                        <div className="col-md-6 infoBlock">
                            <h3>{intl.formatMessage({id: "myPairing.sponsor.firstContactPersonStudies"})}</h3>
                            <span className="faculte"><label>{intl.formatMessage({id: "client.form.faculty_id"})}:</label>{client?.faculty}</span>
                            <span className="studyLevel"><label>{intl.formatMessage({id: "client.form.studyLevel"})}:</label>{studyLevelList.find(lvl => lvl.id === client?.studyLevel)?.name}</span>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-12 infoBlock">
                            <h3>{intl.formatMessage({id: "myPairing.sponsor.firstContactPerson"})}</h3>
                            <span className="messenger"><label>{intl.formatMessage({id: "client.form.messenger"})}:</label>{client?.messenger?client.messenger:traductionsStrings.noneProvidedString}</span>
                            <span className="emailUlaval"><label>{intl.formatMessage({id: "client.form.emailUlaval"})}:</label>{client?.emailUlaval}</span>
                            <span className="emailOther"><label>{intl.formatMessage({id: "client.form.emailOther"})}:</label>{client?.emailOther}</span>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12 infoBlock">
                        <hr/>
                        {displayMyPairingFirstInstructionsMessage && displayWaitToConfirmMessage &&
                            <>
                                <h3>{intl.formatMessage({id:"myPairing.sponsor.nextStep"})}</h3>
                                <div className="infoBlockDelay">{intl.formatMessage({id: "myPairing.sponsor.waitToConfirmMessage"}, {duree : waitTime})}</div>
                            </>
                        }
                    </div>
                </div>

                {displayMaxReachAttemptedMessage &&
                    <>
                        <div className="infoBlockInformations">Le Bureau de la vie étudiante a été avisé de cette situation. Une personne du BVE prendra contact avec vous sous peu après avoir communiqué avec votre personne jumelée. </div>
                    </>
                }

                {!displayMyPairingFirstInstructionsMessage && !displayConfirmationButtons && !displayConfirmedMatchMessage &&
                    <>
                        <h3>{intl.formatMessage({id:"myPairing.sponsor.nextStep"})}</h3>
                        <div className="infoBlockDelay">{intl.formatMessage({id: "myPairing.sponsor.messageSentAndDelay"}, {duree : waitTime, firstname:client?.firstName})}</div>
                    </>
                }

                {displayConfirmationButtons &&
                    <WhiteBoxContent
                        title={intl.formatMessage({ id: "myPairing.sponsor.firstContactTitle" })}
                        titleColor="blackTitle"
                        isTitleH1={false}
                        message={firstContactMessage}

                        link={link1.link}
                        linkIcon ={link1.linkIcon}
                        linkText ={link1.linkText}

                        link2={link2.link}
                        link2Icon ={link2.linkIcon}
                        link2Text ={link2.linkText}
                    />
                }

                {displayConfirmedMatchMessage &&
                    <WhiteBoxContent
                        title={intl.formatMessage({ id: "myPairing.sponsor.firstContactTitle.established" })}
                        titleColor="blackTitle"
                        isTitleH1={false}
                        message={confirmationText}

                    />
                }

            </div>
        </>
    }

    const renderFichesClients = (infosClients) => {

            const rows = [];

            // Itérer sur les éléments du tableau deux par deux
            for (let i = 0; i < infosClients.length; i += 2) {
                // Grouper chaque paire d'éléments dans un div avec la classe 'row'
                rows.push(
                    <div className="row" key={i}>
                        <div className="col-md-6">
                            {renderFiche(infosClients[i])}
                        </div>
                        {/* Vérifier si le deuxième élément existe avant de l'ajouter */}
                        {infosClients[i + 1] && (
                            <div className="col-md-6">
                                {renderFiche(infosClients[i + 1])}
                            </div>
                        )}
                    </div>
                );
            }

            return <>{rows}</>;

    }



    return (
        <div>

            <h1>{intl.formatMessage({id: "myPairing.title"})}</h1>

            <div dangerouslySetInnerHTML={{__html: introText}}/>


            {fichesClients}

        </div>
    );
};

export default MySponsorPairing;
