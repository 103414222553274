import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import "./InputDatePickerField.css";
import JQueryDatePicker from "./JQueryDatePicker";


const InputDatePickerField = ({error, date, onChange, minDate, maxDate, yearRange, name, formattedMessage, displayTitles, intl, mandatory}) => {

    const {formatMessage} = intl;

    const convertDoubleDigits = n =>{
        if(n <= 9){
            return "0" + n;
        }
        return n
    }

    const displayTitle = displayTitles?"displayDateTitle":"";

    const date1 = new Date(date);
    let formattedDate = "";

    const dd = convertDoubleDigits(date1.getUTCDate())
    const mm = convertDoubleDigits(date1.getUTCMonth()+1)
    const YYYY = date1.getUTCFullYear()

    formattedDate = date? YYYY+"-"+mm+"-"+dd : 0

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-md-4">
                    <label
                        htmlFor={name}
                        className={`dateOnlyLabel ${displayTitle}`}>
                        <span className={"field-name"}>
                            <FormattedMessage id={formattedMessage}/>
                            {mandatory?<span className="mandatoryInput">*</span>:""}
                        </span>
                        {/*<div className="noteInputField">{(intl.locale === 'fr')?*/}
                        {/*    "Cliquez sur l'icône de calendrier pour choisir une date valide" :*/}
                        {/*    "Please click on the calendar icon to select a valid date"*/}
                        {/*    }</div>*/}
                    </label>
                </div>

                <div className="col-md-5">
                    <div className="dateField">
                        <span className={`dateLeftSideLabel ${displayTitle}`}>Date</span>
                        <JQueryDatePicker
                            value={formattedDate}
                            onDateChange = {onChange}
                            id={name}
                            name={name}
                            locale={intl.locale}
                            minDate={minDate}
                            maxDate={maxDate}
                            yearRange={yearRange}
                        />
                    </div>
                </div>

                <div className="col-md-3">
                    {error && <div className="alert alert-danger"><span className="validationCustomMessage">{formatMessage({id:error.msg})}</span></div>}
                </div>
            </div>
        </div>
    );
};

export default injectIntl(InputDatePickerField);
