import React from 'react';
import {injectIntl} from "react-intl";
import './DisplayFormErrors.css'

const DisplayFormErrors = ({errors, intl}) => {
    const {formatMessage} = intl;
    //{"field":"complianceCert","error_code":"EMPTY_MANDATORY_VALUE"}
    if(errors.length){
        return (
            <section >
                <details className={"alert alert-danger"} id={"alert-danger"} open={"open"}>
                    <summary className={"h2"}>
                        <h2>{formatMessage({id:"DisplayFormErrors.title"})}</h2>
                    </summary>
                    <p>
                        <ul>
                            {errors.map(item =>
                                <li key={errors.indexOf(item)}>
                                    {formatMessage({id:"DisplayFormErrors.theField"})} <span className={"formErrorFieldName"}>{item.field}</span> {formatMessage({id:"DisplayFormErrors.hasReported"})} <span className={"formErrorFieldValue"}>{item.error_code}</span>
                                </li>)}
                        </ul>
                    </p>
                </details>
            </section>
        );
    }
    else{
        return null;
    }

};

export default injectIntl(DisplayFormErrors);
