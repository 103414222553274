import React from 'react';
import Moment from 'react-moment';
import moment from "moment";
import './FilterMatchSelectionMenu.css'

const FilterMatchSelectionMenu = ({
                                      resultsLimit,
                                      hideFullSponsors,
                                      searchType,
                                      onResultsLimitChange,
                                      onSearchTypeChange,
                                      onHideFullSponsors,
                                      onLaunchSearch,
                                      availabilityDate,
                                      onAvailabilityDateChange,
                                      selectedSponsor,
                                      facultyList, countryList}) => {

    return (
        <div className="container">
            <div className="containerfilterSelectionMenuBox">
                <div className="row">
                    <div className="col-md-12">
                        <div className="filterSelectionMenuTitle">
                            Menu de sélection pour les parrains/marraines
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        <div className="row">
                            <div className="col-md-8"><label >Limite des résultats de recherche</label></div>
                            <div className="col-md-4"><input type="text" className="limitSearchResults" name="limitSearchResults" value={resultsLimit} onChange={onResultsLimitChange} /></div>
                        </div>
                        <div className="row">
                            <div className="col-md-8"><label >Respecter les préférences de l'étudiant étranger</label></div>
                            <div className="col-md-4">
                                <input
                                    type="radio"
                                    id="suggested"
                                    name="searchResults"
                                    onChange={onSearchTypeChange}
                                    checked = {searchType === "suggested"}
                                    value = "suggested"
                                /><label htmlFor="suggested">Oui</label>
                                <br/>
                                <input
                                    type="radio"
                                    id="all"
                                    name="searchResults"
                                    onChange={onSearchTypeChange}
                                    checked ={searchType === "all"}
                                    value = "all"
                                /><label htmlFor="all">Non</label>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8"><label >Cacher les PM complets</label></div>
                            <div className="col-md-4">
                                <input
                                    type="checkbox"
                                    id="sponsorLoadDisplay"
                                    name="showOverloadedSponsors"
                                    onChange={onHideFullSponsors}
                                    checked = {hideFullSponsors}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-8"><label htmlFor="maxResults">Date de disponibilité des parrains et marraines</label></div>
                            <div className="col-md-4">
                                <input
                                    type="date"
                                    data-rule-dateiso={true}
                                    name="availabilityDate"
                                    value={availabilityDate}
                                    onChange={onAvailabilityDateChange}
                                />
                            </div>

                        </div>
                        <div className="row filterSelectionMenuFooter">
                            <div className="col-md-12">
                                <button className="btn btn-black" onClick={onLaunchSearch}>Mettre à jour la liste des matches</button>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        {!objectIsEmpty(selectedSponsor)?<>
                            <div className="currentSelected">
                                <div className="cardTitle">Parrain sélectionné le <Moment format="YYYY/MM/DD hh:mm">{selectedSponsor.matchDate}</Moment></div>
                                <div className="cardData">

                                        <span className="nameSelectedSponsor">{selectedSponsor.firstName} {selectedSponsor.lastName} ({moment().diff(selectedSponsor.birthdate, 'years')} ans)</span>
                                        <span className="nameSelectedSponsorFaculty">{facultyList.find(fac => fac.id === selectedSponsor.faculty_id).name}</span>
                                        <span className="nameSelectedSponsorOriginCountry">Pays d'origine: {countryList.find(country => country.id === selectedSponsor.originCountry_id).name}</span>

                                </div>
                            </div>
                        </>
                            :
                        <>
                            Aucun jumelage associé.
                        </>
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

function objectIsEmpty(obj) {
    if (obj === undefined) return true;
    else if (obj === null) return true;
    else if (obj === "") return true;
    else return (Object.keys(obj).length === 0 && obj.constructor === Object)
}

export default FilterMatchSelectionMenu;