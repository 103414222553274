import React, {Component} from 'react';
import {Link, Redirect} from 'react-router-dom'
import {FormattedMessage, injectIntl} from "react-intl";
import HorizontalCard from "../../common/htmlElements/horizontalCard";
import LoadingComponent from "../../common/loadingComponent";
import httpService from "../../../../services/httpService";
import {getAllConfigs} from "../../../../services/configsService";
import {currentClientIsFilled, resetCurrentClient, currentClientIsMatched} from "../../../../services/clientService";
import {currentSponsorIsFilled, resetCurrentSponsor, currentSponsorIsMatched} from "../../../../services/sponsorService";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import authService from "../../../../services/authService";

import "./adminpageContent.css"

const moment = extendMoment(Moment);

class AdminPageContent extends Component {

    _isMounted = false;

    user = authService.getCurrentUserToken();
    username = this.user?this.user.idul:null;

    state = {
        locale : this.props.locale,
        isLoading : true
    }

    constructor(props) {
        super(props);
        this.state.locale = this.props.locale;
        this.state.userIsLoggedIn = this.props.user?true:false;
        this.state.userToken = this.props.user;
    }

    getConfig = (configName) => {
        const config = this.state.configs.filter(obj => {return obj.name === configName});
        if (config[0] !== undefined){
            return config[0].value;
        }
        else return null;
    }

    componentDidMount() {
        this._isMounted = true;
        if (!authService.getCurrentUserToken()){
            this.setState({userIsLoggedIn : false, isLoading:false})
        }
        if (this.props.user) {
            this.onPageLoad();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onPageLoad = async () => {
        try{
            this.setState({isLoading:true});
            const [
                {data: configs}
            ] = await httpService.all([
                getAllConfigs()
            ]);
            if (this._isMounted){
                this.setState({
                    configs,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false});
            }
        }

    };


    renderPage = () => {
        const userIsLoggedIn = this.state.userIsLoggedIn;
        const startDate = this.getConfig("startDate");
        const endDate = this.getConfig("endDate");
        const endTime = this.getConfig("endTime");
        const startTime = this.getConfig("startTime");

        const infoBoxTitle = this.state.locale === "fr"? this.getConfig("infoBoxTitleFR"):this.getConfig("infoBoxTitleEN");
        const infoBoxText = this.state.locale === "fr"? this.getConfig("infoBoxTextFR"): this.getConfig("infoBoxTextEN");

        const moment_dateStart = moment(startDate + " " + startTime, 'YYYY-MM-DD hh:mm');
        const moment_dateEnd = moment(endDate + " " + endTime, 'YYYY-MM-DD hh:mm');
        const dateRange = moment.range(moment_dateStart, moment_dateEnd);
        const currentDateIsInRange = dateRange.contains(moment(new Date()));

        if (!userIsLoggedIn){
            return <Redirect to='/fr/login' />
        }
        else{
            return (
                <>
                    <div className="Title">
                        <h1 className="homepageTitle"><FormattedMessage id="adminpage.title" /></h1>
                    </div>

                    <div className="row">

                        <div className="col-md-8">

                            Accédez au menu pour les options.

                        </div>
                        <div className="col-md-4">
                            <div className="card bg-light mb-3">
                                <div className="card-header">État actuel du système</div>
                                <div className="card-body">
                                    {currentDateIsInRange &&
                                        <div>Le programme est actuellement actif</div>
                                        ||
                                        <div>Le programme est actuellement désactivé</div>
                                    }
                                </div>
                            </div>
                        </div>


                    </div>
                </>
            );
        }

    };

    render() {

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        )


    }
}

export default injectIntl(AdminPageContent);
