import React, {Component} from 'react';
import {getAllReferences} from "../../services/referencesService"
import {injectIntl} from "react-intl";
import {withRouter} from "react-router-dom";
import Pagination from "../contents/Tables/pagination";
import CRUDTableOptions from "../contents/common/htmlElements/TableComponent/subTableComponents/CRUDTableOptions";
import ShowReferencesTable from "../contents/Tables/ShowReferencesTable";
import ButtonLink from "../contents/common/htmlElements/ButtonLink";

class ReferencesTableFrame100 extends Component {

    _isMounted = false;

    state = {
      locale : this.props.locale,
      userType : this.props.userType,
      isLoading : true,
      referencesList: {},
      filterString : "",
      NoReferencesFound : false,
      pageSize:50,
      currentPage:1,
      showInactive : 0,
      sortColumn: { path: "id", order: "asc" },
      apiSummary:{
          totalElements:null,
          totalPages:null,
          currentPage:null,
          pageSize:null,
          elementsInCurrentPage:null
      }
    };


    componentDidMount() {
        this._isMounted = true;
        this.loadDataFromAPI(this.state.currentPage, this.state.pageSize, this.state.filterString);
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    isEmptyObject(obj){
        for(var prop in obj) {
            if(obj.hasOwnProperty(prop))
                return false;
        }
        return true;
    }

    loadDataFromAPI = async (currentPage, pageSize, filter) => {

        try{
            this.setState({ isLoading: true });
            const {data : referencesList} = await getAllReferences( filter )

            if (this._isMounted) {
                this.setState({
                    apiSummary: {
                        totalElements:referencesList.length,
                        totalPages:1,
                        currentPage:1,
                        pageSize:referencesList.length,
                        elementsInCurrentPage:referencesList.length
                    },
                    referencesList : referencesList,
                    isLoading : false
                });
            }

        }
        catch(err){
            if (this._isMounted) {
                this.setState({isLoading: false, NoUsersFound: true});
            }
        }
    };


    handlePageChange = page => {
        this.setState({ currentPage: page });
        this.loadDataFromAPI(page, this.state.pageSize, this.state.filterString)
    };

    handleFilterPage = string => {
        this.loadDataFromAPI(1, this.state.pageSize, string)
        this.setState({filterString: string})
    };

    handleSort = sortColumn => {
        this.setState({ sortColumn });
    };

    handlePageSize = pageSize => {
        this.setState({pageSize: parseInt(pageSize.target.value)})
        this.loadDataFromAPI(1, parseInt(pageSize.target.value), this.state.filterString)
    };


    render() {
        const count = this.state.apiSummary.totalElements == null ? 0 : this.state.apiSummary.totalElements;
        const pageSize = this.state.apiSummary.pageSize == null ? 0 : this.state.apiSummary.pageSize;
        const currentPage = this.state.apiSummary.currentPage == null ? 0 : this.state.apiSummary.currentPage;
        const { sortColumn, referencesList } = this.state;
        const pageSizeOptions = [1,2,3,20,50,100];
        const { formatMessage } = this.props.intl;
        const addNewLink =  "/" + this.state.locale + "/admin/configs/manage-references/new";

        return (
            <div className="container noShowElements">
                <div className="row" style={{marginTop:30}}>

                    <div className="col-md-12">
                        <CRUDTableOptions
                            itemCounts = {count}
                            OnPageFilter = {this.handleFilterPage}
                            OnPageSizeChange = {this.handlePageSize}
                            pageSize = {pageSize}
                            pageSizeOptions = {pageSizeOptions}
                            locale = {this.props.locale}
                        />

                        <ShowReferencesTable
                            itemCounts={count}
                            referencesList={referencesList}
                            sortColumn={sortColumn}
                            onSort={this.handleSort}
                            isLoading={this.state.isLoading}
                            locale = {this.props.locale}
                        />

                        <ButtonLink
                            link={addNewLink}
                            label={formatMessage({id:"reference.addNewButton"})}
                        />

                        <Pagination
                            itemsCount={count}
                            pageSize={pageSize}
                            currentPage={currentPage}
                            onPageChange={this.handlePageChange}
                            renderFullButtons={false}
                            isLoading={this.state.isLoading}
                        />
                    </div>

                </div>
            </div>
        );
    }
}

export default injectIntl(withRouter(ReferencesTableFrame100));
