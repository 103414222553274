import React, {Component} from 'react';
import JoiBase from "joi";
import JoiDate from "@hapi/joi-date";
import {FormattedMessage} from "react-intl";
import Input from "./formElements/Input";
import Checkbox from "./formElements/Checkbox";
import InputDateOnly from "./formElements/InputDateOnly";
import InputTimeOnly from "./formElements/InputTimeOnly";
import FullsizeTextarea from "./formElements/FullsizeTextarea";
import InputTimeDateSeparated from "./formElements/InputTimeDateSeparated";
import Textarea from "./formElements/Textarea";
import SelectDropdown from "./formElements/SelectDropdown";
import {toast} from "react-toastify";
import InputViewOnly from "./formElements/InputViewOnly";
import "./Form.css"
import ToastStdMessage from "./htmlElements/ToastBoxes/ToastStdMessage";
import InputDatePickerField from "./formElements/InputDatePickerField";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date

class Form extends Component {


    state = {
        data:{},
        errors:{}
    };


    i18nValidate = () => {
        const options = { abortEarly: false, stripUnknown :true };
        const schema = Joi.compile(this.schemaDefinition)
        const errors = schema.validate(this.state.data, options);

        return errors.error ? this.buildUsefulErrorObject(errors.error.details) : null;
    };


    validateProperty = ({name, value}) => {
        const obj = { [name]: value };
        const schemaDefinition = { [name]:this.schemaDefinition[name] };
        const schema = Joi.compile(schemaDefinition);
        const errors = schema.validate(obj);

        return errors.error ? this.buildUsefulErrorObject(errors.error.details)[name] : null;
    };

    handleSubmit = e =>{
        const { formatMessage } = this.props.intl;
        e.preventDefault();
        const errors = this.i18nValidate();
        this.setState({errors: errors || {} });
        // console.log(JSON.stringify(errors))
        if (errors) {
            toast.error(<ToastStdMessage title={formatMessage({id: "toast.error.errorInForm.Title"})}
                                         message={formatMessage({id: "toast.error.errorInForm.content"})}
                                         icon="slash-square"
            />)
            return;
        }
        this.doSubmit();
    };

    handleChange = ({ currentTarget: input }) => {
      const errors = { ...this.state.errors };
      const errorMessage = this.validateProperty(input);
      if(errorMessage) errors[input.name] = errorMessage;
      else delete errors[input.name];

      const data = { ...this.state.data };
      data[input.name] = input.value;

      this.setState({ data, errors });
    };

    handleDatePickerChange = (obj, e) => {
        const input = {name : e.id, value: obj}

        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty(input);
        if(errorMessage) errors[input.name] = errorMessage;
        else delete errors[input.name];

        const data = { ...this.state.data };
        data[input.name] = input.value;

        this.setState({ data, errors });
    }

    toggleCheckboxChange = ({ currentTarget: input }) => {
        const data = { ...this.state.data };
        data[input.name] = input.checked;
        this.setState({ data });
    }


    buildUsefulErrorObject(errors) {
        const usefulErrors = {};
        errors.map((error) => {
            if (!usefulErrors.hasOwnProperty(error.path.join('_'))) {
                usefulErrors[error.path.join('_')] = {
                    type: error.type,
                    msg: `error.${error.path.join('_')}.${error.type}`
                };
            }
            return null
        });
        return usefulErrors;
    };

    createSelectItemsList = (ObjectList) => {
        let returnObject = []
        for (let [key, value] of Object.entries(ObjectList)) {
            returnObject.push({value: value.id, label: value.name})
            //returnObject.push({value: parseInt(key)+1, label: value.name})
            // value.id?
            //     returnObject.push({value: value.id, label: value.name})
            //     :
            //     returnObject.push({value: parseInt(key)+1, label: value.name})
        }
        return returnObject
    }


    renderButton(label){
        return(
            <button className="btn btn-primary">
                <FormattedMessage id={label}/>
            </button>
        );
    }

    renderCheckBox(name, label, tooltip, mandatory ){
        const {data, errors} = this.state;

        return (
            <Checkbox
                isChecked={data[name]}
                onChange={this.toggleCheckboxChange }
                name={name}
                label={label}
                error={errors[name]}
                tooltip={tooltip}
                mandatory={mandatory}
            />
        );
    }

    renderInput(name, label, longField=false, type = "text", units = null, tooltip, mandatory = false){
        const {data, errors} = this.state;

        return (
            <Input
                value={data[name]}
                onChange={this.handleChange}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                longField={longField}
                units={units}
                tooltip={tooltip}
                mandatory={mandatory}
            />
        );
    }

    renderInputViewOnly(name, label, longField=false, type = "text", units = null, tooltip){
        const {data, errors} = this.state;

        return (
            <InputViewOnly
                value={data[name]}
                onChange={this.handleChange}
                name={name}
                label={label}
                error={errors[name]}
                type={type}
                longField={longField}
                units={units}
                tooltip={tooltip}
            />
        );
    }

    renderDatePickerField(name, label, displayTitle = true, minDate = null, maxDate = "-15y", yearRange = "-75:-15", mandatory=false){
        const {data, errors} = this.state;

        return(
            <InputDatePickerField
                date={data[name]}
                onChange={this.handleDatePickerChange}
                formattedMessage={label}
                name={name}
                displayTitles={displayTitle}
                error={errors[name]}
                mandatory={mandatory}
                minDate={minDate}
                maxDate={maxDate}
                yearRange={yearRange}
            />
        );
    }

    renderInputDateOnly(name, label, displayTitle = true, mandatory=false){
        const {data, errors} = this.state;

        return(
            <InputDateOnly
                date={data[name]}
                onChange={this.handleChange}
                formattedMessage={label}
                name={name}
                displayTitles={displayTitle}
                error={errors[name]}
                mandatory={mandatory}
            />
        );
    }

    renderInputTimeOnly(name, label, displayTitle = true){
        const {data, errors} = this.state;

        return(
            <InputTimeOnly
                time={data[name]}
                onChange={this.handleChange}
                formattedMessage={label}
                name={name}
                displayTitles={displayTitle}
                error={errors[name]}
            />
        );
    }

    renderFullSizeTextArea(name, label){
        const {data, errors} = this.state;

        return(
            <FullsizeTextarea
                value={data[name]}
                onChange={this.handleChange}
                name={name}
                formattedMessage={label}
                error={errors[name]}
            />
        );
    }

    renderTextArea(name, label, longField = true){
        const {data, errors} = this.state;

        return(
            <Textarea
                value={data[name]}
                onChange={this.handleChange}
                name={name}
                formattedMessage={label}
                longField={longField}
                error={errors[name]}
            />
        );
    }

    renderInputTimeDateSeparated(name, time, date, label, displayTitles=true){
        const {data, errors} = this.state;

        return(
            <InputTimeDateSeparated
                time={data[time]}
                date={data[date]}
                onChange={this.handleChange}
                formattedMessage={label}
                name={name}
                displayTitles={displayTitles}
                errors={[errors[time], errors[date]]}
            />
        );
    }


    renderInputDropDown(name, label, options, isMulti = false, mandatory=false){
        const {data, errors} = this.state;

        return (
            <SelectDropdown
                isMulti = {isMulti}
                selectedOption = {data[name] != null?data[name]: []}
                onChange={this.handleSelectChange}
                name={name}
                label={label}
                options={options}
                error={errors[name]}
                mandatory={mandatory}
            />
        );
    }

    handleSelectChange = (selectedOption, actionMeta)  => {
        let arrayOfElements = [];
        let returnArray = [];

        //if isMulti, return array element. Else, return INT
        if(Array.isArray(selectedOption)){
            arrayOfElements = Array.isArray(selectedOption) ? selectedOption : [selectedOption]
            arrayOfElements.forEach( item => {returnArray.push(item.value)})
        }
        else{
            try{
                returnArray = selectedOption.value;
            }catch (err){
                returnArray = null;
            }
        }

        const errors = { ...this.state.errors };
        const errorMessage = this.validateProperty({name:actionMeta.name, value:returnArray});
        if(errorMessage) errors[actionMeta.name] = errorMessage;
        else delete errors[actionMeta.name];

        const data = { ...this.state.data };

        data[actionMeta.name] = returnArray;
        this.setState({ data, errors });
    };

}


export default Form;
