import React, {useEffect, useState} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";
import "./ShowUsersTable.css"
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import PopperWindow from "../PopperWindow/PopperWindow";
import {getWindowWidthAndHeight} from "../../../utility/javascriptTools.mjs";
import {Button} from "react-bootstrap";
import LoadClientForm from "../Forms/Client/LoadClientForm";
import MatchClientPageContent from "../Pages/MatchClientPage/MatchClientPageContent";
import LoadSponsorForm from "../Forms/Sponsor/LoadSponsorForm";
import editUserIcon from "../Icons/User.jpg";
import matchUserIcon from "../Icons/Match.jpg";
import deleteMatchIcon from "../Icons/DeleteMatch.jpg";
import {confirmAlert} from "react-confirm-alert";
import {deleteMatchFromClientID} from "../../../services/matchUsersService";

function ShowUsersTable ({userType, userList, onSort, sortColumn, isLoading, locale, onSelectElement, intl, userConfirmationFunction, isLoadingEmailConfirmation}) {
    const USER_APPROVED_STATE = 2;

    const { windowWidth, windowHeight } = getWindowWidthAndHeight();
    const [usersList, setUsersList] = useState(userList);
    const [columns, setColumns] = useState([]);
    const [renderCrudTable, setRenderCrudTable] = useState();
    const [editClientWindowState, setEditClientWindowState] = useState(false);
    const [editSponsorWindowState, setEditSponsorWindowState] = useState(false);
    const [matchClientWindowState, setMatchClientWindowState] = useState(false);
    const [userID, setUserID] = useState();

    const confirmUserMatch = async (user) =>{
        userConfirmationFunction(user);
    }

    const createColumns = () => {
        let columns;
        columns = [
            //{path:"id",label:intl.formatMessage({id: "table.users.id.short"})},
            {
                key: "status", content: user => {
                    if (user.confirmation_Status === 1) { //L'étudiant a confirmé
                        return (
                            <LockOutlinedIcon className="lockIconLeft"></LockOutlinedIcon>
                        );
                    } else if (user.confirmation_Status === 2) { //Le sponsor a confirmé
                        return (
                            <LockOutlinedIcon className="lockIconRight"></LockOutlinedIcon>
                        );
                    } else if (user.confirmation_Status === 3) { //Les 2 ont confirmés
                        return (
                            <LockOutlinedIcon className="lockIconFull"></LockOutlinedIcon>
                        );
                    }
                    return null;
                },
                label: intl.formatMessage({id: "table.users.status.short"})
            },
            {path: "date_registered", label: intl.formatMessage({id: "table.users.dateCreated.short"})},
            {path: "ni", label: intl.formatMessage({id: "table.users.ni.short"})},
            {path: "firstName", label: intl.formatMessage({id: "table.users.firstname.short"})},
            {path: "lastName", label: intl.formatMessage({id: "table.users.lastname.short"})},
            {path: "state", label: intl.formatMessage({id: "table.users.state.short"})}
        ];
        switch (userType){

            case "clients" :
                columns.push(
                    {path:"takenInChargeByName",label:intl.formatMessage({id: "table.users.takenInChargeByName.short"})},
                    {path:"arrivalInQuebec",label:intl.formatMessage({id: "table.users.arrivalInQuebec.short"})},
                    {key:"edit", content: user => (
                            // <Link to={`./${userType}/${user.id}`} >{intl.formatMessage({id: "table.users.editLink"})}</Link>
                            <Button className="editAndMatchButton" title={intl.formatMessage({id: "table.users.editLink"})} onClick={() => handleEditClientButtonClick(user)}>
                                <img alt="Editer Utilisateur" src={editUserIcon} />
                            </Button>
                        )},
                    {key:"deletematch", content: user => (
                            (user.takenInChargeByID) &&
                            <Button className="editAndMatchButton" title={intl.formatMessage({id: "table.users.deleteMatchLink"})} onClick={() => handleDeleteMatchButtonClick(user)}>
                                <img alt="Supprimer le jumelage" src={deleteMatchIcon} />
                            </Button>
                        )},
                    {key:"match", content: user => (
                            (user.state === USER_APPROVED_STATE) &&
                            // <Link to={`./match/${userType}/${user.id}`} >{intl.formatMessage({id: "table.users.matchLink"})}</Link>
                            <Button className="editAndMatchButton" title={intl.formatMessage({id: "table.users.matchLink"})} onClick={() => handleMatchButtonClick(user)}>
                                <img alt="Jumeler Utilisateur" src={matchUserIcon} />
                            </Button>
                        ), label:"Actions"},
                    {key:"confirm", content: user => (
                            (user.takenInChargeByID) &&
                            <Link to={`./clientlist`} onClick={async ()=> {
                                await confirmUserMatch(user)
                            }
                            }
                            >{(user.state === 10)?intl.formatMessage({id: "table.users.ResendConfirmLink"}):intl.formatMessage({id: "table.users.confirmLink"})}</Link>
                        )}
                );

                break;

            case "sponsors" :
                columns.push({path:"inChargeOfClientsNames",label:intl.formatMessage({id: "table.users.takenInChargeByName.short"})});
                columns.push({key:"edit", content: user => (
                        // <Link to={`./${userType}/${user.id}`} >{intl.formatMessage({id: "table.users.editLink"})}</Link>
                        <Button className="editAndMatchButton" title={intl.formatMessage({id: "table.users.editLink"})} onClick={() => handleEditSponsorButtonClick(user)}>
                            <img alt="Editer Utilisateur" src={editUserIcon} />
                        </Button>
                    )});
                columns.push({key:"match", content: null, label:"Actions"});
                break;

            default:
                break;
        }

        return columns;
    };

    const renderNewCrudTable = () => {
        return <CRUDTable
            columns={columns}
            data = {usersList}
            sortColumn={sortColumn}
            onSort={onSort}
            isLoading={isLoading}
            locale = {locale}
            select ={userType === "clients"? true: false}
            onSelectElement = {onSelectElement}
            handleMatchButtonClick = {handleMatchButtonClick}
        />;
    };

    useEffect(() => {
        setUsersList(userList);
    }, [userList]);

    useEffect(() => {
        setColumns(createColumns());
    }, [usersList]);

    useEffect(() => {
        if(columns.length !== 0) {
            setRenderCrudTable(renderNewCrudTable());
        }
    }, [columns, sortColumn]);

    const hideEditClientWindow = () => {
        setEditClientWindowState(false);
    }

    const hideEditSponsorWindow = () => {
        setEditSponsorWindowState(false);
    }

    const hideMatchClientWindow = () => {
        setMatchClientWindowState(false);
    }

    const handleEditClientButtonClick = (user) => {
        setUserID(user.id);
        setEditClientWindowState(true);
    }

    const deleteMatchInUserListState = (userID) => {

        const updatedUsers = usersList.map((user) => {

            if (user.id === userID) {
                return { ...user, confirmation_Status: 0, takenInChargeByID: null, takenInChargeByName: null, state:2 };
            }
            return user;

        });
        setUsersList(updatedUsers);
    }


    const handleDeleteMatchButtonClick = (user) => {

        const doDeleteMatch = async () =>{
            try {
                deleteMatchFromClientID(user.id);
                deleteMatchInUserListState(user.id);
            }catch (e) {
                console.error(e.message);
            }
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">Souhaitez-vous vraiment supprimer le jumelage de {user.firstName} {user.lastName}?</p>
                        <p>Actuellement pris en charge par {user.takenInChargeByName}</p>
                        <button className="redButton" onClick={async () => {await doDeleteMatch();onClose();}}>Oui</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>Non</button>
                    </div>
                );
            }
        });



    }

    const handleEditSponsorButtonClick = (user) => {
        setUserID(user.id);
        setEditSponsorWindowState(true);
    }

    const handleMatchButtonClick = (user) => {
        setUserID(user.id);
        setMatchClientWindowState(true);
    }

    return[
        <>

            <h1 className="userListTitle">{userType === "clients"? "Jumeler les étudiants de l'international":"Gestion des parrains et marraines"}</h1>

            {renderCrudTable}

        </>
        ,
        <div>
            <PopperWindow
                modalTitle={"Formulaire - Étudiant de l'international"}
                modalContent={
                    <LoadClientForm
                        locale={locale}
                        id={userID}
                        usersList={usersList}
                        setUsersList={setUsersList}
                        setEditClientWindowState={setEditClientWindowState}
                    />
                }
                saveText="Sauvegarder"
                saveColor="success"
                cancelText="Annuler"
                width={Math.max(windowWidth*0.4, 1000)}
                height={Math.max(windowHeight*0.4, 720)}
                showValue={editClientWindowState}
                closeWindow={hideEditClientWindow}
                isForm={true}
                isLargeWidth={true}
            />
            <PopperWindow
                modalTitle={"Formulaire - Jumelage"}
                modalContent={
                    <MatchClientPageContent
                        locale={locale}
                        clientID={userID}
                        usersList={usersList}
                        setUsersList={setUsersList}
                        setMatchClientWindowState={setMatchClientWindowState}
                        deleteMatchInUserListState ={deleteMatchInUserListState}
                    />
                }
                saveText="Sauvegarder"
                saveColor="success"
                cancelText="Annuler"
                width={Math.max(windowWidth*0.8, 1000)}
                height={Math.max(windowHeight*0.75, 720)}
                showValue={matchClientWindowState}
                closeWindow={hideMatchClientWindow}
                isForm={true}
                isLargeWidth={true}
            />
            <PopperWindow
                modalTitle={"Formulaire - Parrain/marraine"}
                modalContent={
                    <LoadSponsorForm
                        locale={locale}
                        id={userID}
                        usersList={usersList}
                        setUsersList={setUsersList}
                        setEditSponsorWindowState={setEditSponsorWindowState}
                    />
                }
                saveText="Sauvegarder"
                saveColor="success"
                cancelText="Annuler"
                width={Math.max(windowWidth*0.4, 1000)}
                height={Math.max(windowHeight*0.4, 720)}
                showValue={editSponsorWindowState}
                closeWindow={hideEditSponsorWindow}
                isForm={true}
                isLargeWidth={true}
            />
        </div>
    ];



}
export default injectIntl(ShowUsersTable);
