import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/notifications";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getNotificationsForUser(user){
    console.log("User obj: " + JSON.stringify(user));
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(apiEndpoint + `/?clientID=${user.clientID}&sponsorID=${user.sponsorID}`, {headers: {"x-auth-token" : jwt}})
    } catch (ex){
        return null;
    }
}

