import React, {Fragment, useEffect, useState} from "react";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link, useHistory} from "react-router-dom";
import NavigationBar2023 from "./NavigationBar2023/NavigationBar2023";
import LoginDropdown from "./LoginDropdown/LoginDropdown";
import LanguageSwitcherDropdown2023 from "./LanguageSwitcherDropdown2023/LanguageSwitcherDropdown2023";
import MenuUlaval2023 from "./MenuUlaval2023/MenuUlaval";
import SubMenu2023 from "./SubMenu2023/SubMenu2023";


const Header = () => {

    const [currentActiveMenu, setCurrentActiveMenu] = useState(0)

    const history = useHistory();

    useEffect(() => {
        return history.listen((location) => {
            reloadScripts();
        })
    },[history]);


    useEffect(() => {
        reloadScripts();
    }, []); // Exécute le code une fois après le premier rendu (équivalent à componentDidMount)


    const reloadScripts = () => {
        removeScripts();
        loadScripts();
    }


    const loadScripts = () => {
        ['https://www.jumelage.bve.ulaval.ca/js/normes.js', 'https://www.jumelage.bve.ulaval.ca/js/header-search.js'].forEach(src => {
            const script = document.createElement('script');
            script.src = src;
            //script.async = true;
            document.body.appendChild(script);
        });
    }

    const removeScripts = () => {
        ['https://www.jumelage.bve.ulaval.ca/js/normes.js', 'https://www.jumelage.bve.ulaval.ca/js/header-search.js'].forEach(src => {
            // Sélectionnez le script spécifique à supprimer
            const script = document.querySelector(`script[src="${src}"]`);
            if (script) {
                // Supprimez le script seulement s'il existe dans le document
                document.body.removeChild(script);
            }
        });
    }


    return(
        <>
            <header className="header">
                <div className="header-container">
                    <div className="container">
                        <div className="header-top">
                            <div className="header-top-brand">

                                <a className="header-university-link" href="https://www.ulaval.ca/" target="_blank">

                                    {/* eslint-disable-next-line jsx-a11y/aria-props */}
                                    <svg aria-hidden="true" className="university-logo-simplified" viewBox='0 0 102.6 129.8'>
                                        <path fill='#E30513' d='M0 0v101.4c0 8.7 6.7 15.6 15.3 15.6h24.1c3.2 0 7.4 2.2 9.5 8.3l1.2 3.4c.3.7.7 1.1 1.2 1.1s.9-.4 1.2-1.1l1.2-3.4c2.1-6.1 6.3-8.3 9.5-8.3h24.1c8.6 0 15.3-6.8 15.3-15.6V0H0z' />
                                    </svg>
                                    <span>Université Laval</span>
                                </a>
                                <span className="divider"></span>
                                <a className="header-unit-link" href="#">Bureau de la vie étudiante</a>
                            </div>

                            <div className="header-top-meta">
                                <MenuUlaval2023 mobile={false}/>
                            </div>
                        </div>
                        <div className="header-main">
                            <div className="header-main-brand">
                                <a href="/" className="header-title">Programme de jumelage</a>
                            </div>

                            <NavigationBar2023
                                mobile={false}
                                enabled={true}
                                setCurrentActiveMenu = {setCurrentActiveMenu}
                            />

                            <div className="header-main-utilities">
                                <div className="header-tools">
                                    {/*<button type="button" className="header-search-trigger" aria-expanded="false" aria-controls="header-search" aria-label="Chercher">*/}
                                    {/*    <i aria-hidden="true" className="ul-i-search" role="presentation"></i>*/}
                                    {/*</button>*/}

                                    <LoginDropdown />

                                    <LanguageSwitcherDropdown2023
                                        mobile={false}
                                        enabled={true}
                                    />


                                    <button type="button" className="header-menu-trigger" aria-expanded="false" aria-controls="ul-mobile-menu">
                                        <svg aria-hidden="true" className="nav-mobile-icon" viewBox="0 0 48 48">
                                            <g>
                                                <path className="ouvrir-haut ouvrir-milieu ouvrir-bas" transform="translate(16 16)" d="M.978,16a1,1,0,0,1,0-2H15.025a1,1,0,0,1,0,2Zm0-7a1,1,0,0,1,0-2H15.025a1,1,0,0,1,0,2Zm0-7A.989.989,0,0,1,0,1,.989.989,0,0,1,.978,0H15.025A.988.988,0,0,1,16,1a.988.988,0,0,1-.975,1Z" />
                                                <path className="fermer-gauche" transform="translate(16 16)" d="M.261,15.738a.884.884,0,0,1,0-1.255L6.746,8,.261,1.517A.888.888,0,0,1,1.517.262L8,6.745,14.483.262a.883.883,0,0,1,1.255,0A.868.868,0,0,1,16,.889a.874.874,0,0,1-.262.627L9.255,8l6.483,6.483a.882.882,0,0,1,0,1.255.885.885,0,0,1-1.255,0L8,9.255,1.517,15.738a.884.884,0,0,1-1.256,0Z" />
                                            </g>
                                        </svg>
                                        <span className="screen-reader-text visually-hidden">Ouvrir le menu principal</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container header-mobile-menu" id="ul-mobile-menu">
                    <NavigationBar2023
                        mobile={true}
                        enabled={true}
                        setCurrentActiveMenu = {setCurrentActiveMenu}
                    />

                    <MenuUlaval2023 mobile={true}/>

                    <LanguageSwitcherDropdown2023
                        mobile={true}
                        enabled={true}
                    />

                </div>
                <div className="header-search" id="header-search">
                    <div className="header-search-container container">

                        <form id="header-search-form" className="header-search-form" role="search" method="get" action="/chercher/google">
                            <fieldset className="header-search-type">
                                <legend className="visually-hidden">Choisir le type de recherche</legend>

                                <input checked="checked" id="search-in--website" tabIndex="-1" className="visually-hidden" type="radio" name="search-type" value="website" data-action="/chercher/google" data-placeholder="Que cherchez-vous?"/>
                                    <label htmlFor="search-in--website" tabIndex="0">Tout ulaval.ca</label>

                                    <input id="search-in--staff" tabIndex="-1" className="visually-hidden" type="radio" name="search-type" value="staff" data-action="/chercher/trouver-une-personne" data-placeholder="Qui voulez-vous joindre?"/>
                                        <label htmlFor="search-in--staff" tabIndex="0">Bottin</label>

                                        <input id="search-in--map" tabIndex="-1" className="visually-hidden" type="radio" name="search-type" value="map" data-action="/plan-du-campus" data-placeholder="Que cherchez-vous?"/>
                                            <label htmlFor="search-in--map" tabIndex="0">Plan du campus</label>
                            </fieldset>

                            <div className="header-search-main">
                                <label htmlFor="header-search-input" tabIndex="-1" className="visually-hidden">Que cherchez-vous?</label>
                                <input id="header-search-input" tabIndex="0" className="header-search-input" type="search" placeholder="Chercher par mots-clés" name="q" value="" />
                                <button className="header-search-button" tabIndex="0" type="submit" value="" aria-label="Chercher">
                                    <i className="ul-i-search" aria-hidden="true" role="presentation"></i>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="header-sub-nav">
                    <SubMenu2023
                        Caption=""
                        CurrentActiveMenu={currentActiveMenu}
                    />
                </div>
            </header>

            <ToastContainer/>


        </>
    );
}

export default Header;
