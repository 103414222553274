import React from 'react';
import {FormattedMessage, injectIntl} from "react-intl";
import "./InputTimeOnly.css"


const InputTimeOnly = ({error, time, onChange, name, formattedMessage, displayTitles, intl}) => {

    const {formatMessage} = intl;

    const convertDoubleDigits = n =>{
        if(n <= 9){
            return "0" + n;
        }
        return n
    }

    const displayTitle = displayTitles?"displayDateTitle":"";

    let time1 = new Date();
    time1.setUTCHours(time?.split(':')[0]);
    time1.setUTCMinutes(time?.split(':')[1]);

    let formattedTime = "";

    const min = convertDoubleDigits(time1.getUTCMinutes())
    const hour = convertDoubleDigits(time1.getUTCHours())

    formattedTime = time? hour+":"+min : "00:00"

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-md-4">
                    <label
                        htmlFor={name}
                        className={`timeOnlyLabel ${displayTitle}`}>
                        <span className={"field-name"}>
                            <FormattedMessage id={formattedMessage}/>
                        </span>
                    </label>
                </div>

                <div className="col-md-5">
                    <div className="TimeField">
                        <span className={`timeLeftSideLabel ${displayTitle}`}>{formatMessage({id:"formation.form.time"})}</span>
                        <input
                            value={formattedTime}
                            onChange={onChange}
                            type="time"
                            id={name}
                            name={name}
                            className="form-control timeOnlyField"
                        />
                    </div>
                </div>

                <div className="col-md-3">
                    {error && <div className="alert alert-danger"><span className="validationCustomMessage">{formatMessage({id:error.msg})}</span></div>}
                </div>
            </div>
        </div>
    );
};

export default injectIntl(InputTimeOnly);
