import httpService from "./httpService";
import jwtDecode from "jwt-decode";

const apiEndpoint = process.env.REACT_APP_API + "/auth";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export async function login(username, password){
    const {data: jwt} = await httpService.post(apiEndpoint, {username,password}, {
        headers:{'Content-Type': 'application/json'}
    });
    sessionStorage.setItem(tokenKey, jwt);
}

export function loginWithJwt(jwt){
    sessionStorage.setItem(tokenKey, jwt);
}

export function logout(){
    sessionStorage.removeItem(tokenKey);
}

export function getCurrentUserToken() {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        if (tokenHasExpired(jwt)){
            console.log("Token expired, logging out...")
            logout();
        }
        else{
            return jwtDecode(jwt);
        }
    } catch (ex){
        return null;
    }
}

export function getCurrentUserNotifs() {
    return 1;
}

export function tokenHasExpired(userToken){
    try{
        const token = jwtDecode(userToken);
        const dateNow = new Date();
        const tokenDate = new Date(token.exp * 1000);
        return (tokenDate < dateNow);
    }
    catch{
        return false;
    }
}



const exportedObject = {
    login,
    loginWithJwt,
    logout,
    getCurrentUserToken,
    tokenHasExpired
};

export default exportedObject;
