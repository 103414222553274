import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import {withRouter} from "react-router-dom"
import {injectIntl, useIntl} from "react-intl";
import MatchSponsorPageContent from "../contents/Pages/MatchSponsor/MatchSponsorPageContent";

const MatchClientPageLayout = ({match, locale}) => {

    const intl = useIntl();

    return(
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <MatchSponsorPageContent locale={intl.locale} id={match.params.id}/>
            </main>
            <Footer/>
        </>
    );
}


export default withRouter(injectIntl(MatchClientPageLayout));
