import React from 'react';
import Joi from 'joi';
import Form from "../common/Form";
import {injectIntl} from "react-intl";
import {FormattedMessage} from "react-intl";
import auth from "../../../services/authService";
import { withRouter } from 'react-router-dom';


import "./LoginForm.css";
import LanguageLink from "../../headers/LanguageLink";
import {cryptoSecretKey, encryptData} from "../../../utility/crypto-js";

class LoginForm extends Form {
    state = {
        locale : this.props.locale,
        data: {
            username: "",
            password: ""
        },
        errors:{}
    }

    schemaDefinition = {
        username: Joi.string().min(3),
        password: Joi.string().min(3).allow(null)
    };

    doSubmit = async e => {

        try{
            const {data} = this.state;
            await auth.login(data.username, encryptData(data.password, cryptoSecretKey));
            window.location = `/${this.props.locale}/homepage`;
        } catch (ex){
            if(ex.response && ex.response.status === 400){
                const errors = {...this.state.errors};
                errors.username = {msg: "USER_PASS_INVALID"};
                this.setState({errors});
            }
        }

        // console.log("JWToken received: " + retValue.data);

    }

    render() {
        //const {data, errors} = this.state;
        const toolTip = this.state.locale === "fr"?
            `Votre identifiant est votre IDUL. Celui-ci est composé de cinq lettres, générées à partir de votre prénom et de votre nom, suivies généralement d'un à trois chiffres.`
            :
            `Your ID is your IDUL. This one is generally composed of five letters from your first and lastname followed by one to three digits.`;

        return (
            <div className="loginForm">

                <h1><FormattedMessage id="loginForm.Title"/></h1>

                <form onSubmit={this.handleSubmit}>
                    {/*renderInput(name, label, longField=false, type = "text", units = null, tooltip){*/}
                    {this.renderInput("username", "loginForm.username", false, "text", null, toolTip)}
                    {this.renderInput("password", "loginForm.password", false, "password")}
                    {/*<button className={"btn btn-success"} disabled={this.validate()}>*/}
                    <div className="row">
                        <div className="col-md-4"></div>
                        <div className="col-md-5">
                            <button className={"btn btn-success"} style={{backgroundColor:"#09f",color:"#fff"}}>
                                <FormattedMessage id="loginForm.SubmitForm"/>
                            </button>
                        </div>
                        <div className="col-md3"></div>
                    </div>
                    {/*<FormattedMessage id="application.switchLangTo"/><LanguageLink/>*/}
                </form>
            </div>
        );
    }
}


export default injectIntl(withRouter(LoginForm));
