import TipTapEditor from "../../TiptapEditor/TiptapEditor";
import React, {useState} from "react";
import "./SendMailForm.css";
import authService from "../../../../services/authService";
import {useVerifyLogin} from "../../common/formFunctionSharing";
import {Button, Form} from "react-bootstrap";
import {sendSimpleMail} from "../../../../services/mailsService";


function SendMailForm({cci, hideWindowState}) {

    const user = authService.getCurrentUserToken();
    useVerifyLogin(user);

    const [object, setObject] = useState('');
    const [messageBody, setMessageBody] = useState('');

    const handleObjectChange = (e) => {
        setObject(e.target.value);
    }

    const handleBodyChange = (text) => {
        setMessageBody(text);
    }

    const handleSendMail = async (e) => {
        e.preventDefault();
        try {
            await sendSimpleMail(messageBody, object, "", cci);
        } catch (e) {
            console.error(e.message);
        }
        hideWindowState();
    }

    return(
        <div>
            <Form onSubmit={handleSendMail}>
                <Form.Group className="objet" controlId="Objet">
                    <Form.Label>Objet</Form.Label>
                    <Form.Control required type="text" value={object} onChange={handleObjectChange}/>
                </Form.Group>
                <TipTapEditor value={messageBody} onChange={handleBodyChange}/>
                <Button variant="dark" className="submitBtn" type="submit">Envoyer</Button>
            </Form>
        </div>
    );
}

export default SendMailForm;