import React, {useEffect, useState} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import WhiteBoxContent from "../../../common/htmlElements/WhiteBoxContent/WhiteBoxContent";
import {getPublicConfigsByNames} from "../../../../../services/configsService";
import {confirmStatus, getMyMatchData, updateContactRequest} from "../../../../../services/matchUsersService";
import {getCurrentUserToken} from "../../../../../services/authService";
import {confirmAlert} from "react-confirm-alert";
import moment from "moment/moment";
import {useNotifications} from "../../../../../Hooks/useNotifications";
import {sendReminder} from "../../../../../services/mailsService";

const MyClientPairing = () => {
    const intl = useIntl();
    const notifications = useNotifications();
    const user = getCurrentUserToken();
    const [isLoading, setIsLoading] = useState(false);
    const [configs, setConfigs] = useState({});
    const [myMatchData, setMyMatchData] = useState({});
    const [displayIntroMessage, setDisplayIntroMessage] = useState(true);
    const [renderFirstContactBox, setRenderFirstContactBox] = useState();
    const [mailData,setMailData] = useState({})

    const fetchMatchData = async () => {
        const matchData = await getMyMatchData(user);
        setMyMatchData(matchData.data);
    };

    useEffect( () => {

        const fetchConfigs = async () => {
            const configs = await getPublicConfigsByNames(["welcomePairingEI" + intl.locale, "congratulationPairing" + intl.locale, "congratulationPairing" + intl.locale]);
            setConfigs(configs.data)
        };

        fetchConfigs();
        fetchMatchData();
    }, []);

    const renderFicheClient = () => {
        const DELAY_HOURS = 72;
        const dateActuelle = moment();
        const dateDernierContact = moment(myMatchData.last_client_contactrequest);
        const differenceHeures = dateActuelle.diff(dateDernierContact, 'hours' );
        const activerBoutonsContact = (dateDernierContact._isValid===false) ? true : (differenceHeures >= DELAY_HOURS);
        const waitTime = DELAY_HOURS - differenceHeures;

        const maxAttemptReached = myMatchData.client_contactrequestAttempt >= 3;
        const matchConfirmed = myMatchData?.confirmation_Status === 1 || myMatchData?.confirmation_Status === 3;

        let buttonLink1 = async()=>{await handleConfirmContact()};
        let buttonLink2 = async ()=>{await handleContactAttempt()};

        const displayMaxReachAttemptedMessage = !matchConfirmed && maxAttemptReached;
        const displayConfirmationButtons = (!matchConfirmed && activerBoutonsContact) || (!matchConfirmed && maxAttemptReached);
        const displayWaitToConfirmMessage = !maxAttemptReached && !matchConfirmed && !activerBoutonsContact;
        const displayConfirmedMatchMessage = matchConfirmed;

        let firstContactMessage = "";
        switch(true){
            case (myMatchData.client_contactrequestAttempt === 0):
                firstContactMessage = intl.formatMessage({ id: "myPairing.client.firstContactMessage" });
                break;
            case (myMatchData.client_contactrequestAttempt === 1):
                firstContactMessage = intl.formatMessage({ id: "myPairing.client.secondContactMessage" });
                setDisplayIntroMessage(false);
                break;
            case (myMatchData.client_contactrequestAttempt === 2):
                firstContactMessage = intl.formatMessage({ id: "myPairing.client.thirdContactMessage" });
                setDisplayIntroMessage(false);
                break;
            case (myMatchData.client_contactrequestAttempt > 2):
                firstContactMessage = intl.formatMessage({ id: "myPairing.client.finalContactMessage" });
                setDisplayIntroMessage(false);
                buttonLink2 = null;
                break;
        }

        const link1 = {
            link:buttonLink1,
            linkIcon:"",
            linkText:intl.formatMessage({ id: "myPairing.client.firstContactConfirmation.Yes" })
        }

        const link2 = {
            link:buttonLink2,
            linkIcon:"",
            linkText:intl.formatMessage({ id: "myPairing.client.firstContactConfirmation.No" })
        }

        return (
            <div>
                {displayMaxReachAttemptedMessage &&
                    <>
                        <div dangerouslySetInnerHTML={{__html: intl.formatMessage({id: "myPairing.client.MaxContactAttempt"})}}/>
                    </>
                }
                {displayWaitToConfirmMessage && myMatchData.client_contactrequestAttempt !== 0 &&
                    <p>{intl.formatMessage({id: "myPairing.client.messageSentAndDelay.Intro"})}</p>
                }
                {displayWaitToConfirmMessage &&
                    <>

                        <WhiteBoxContent
                            title={intl.formatMessage({id: "myPairing.client.nextStep"})}
                            titleColor="blackTitle"
                            isTitleH1={true}
                            message={intl.formatMessage({id: "myPairing.client.waitToConfirmMessage"}, {duree: waitTime})}

                            link={null}
                            linkIcon={link1.linkIcon}
                            linkText={link1.linkText}

                            link2={null}
                            link2Icon={link2.linkIcon}
                            link2Text={link2.linkText}
                        />
                    </>
                }

                {displayConfirmationButtons &&
                    <WhiteBoxContent
                        title={intl.formatMessage({id: "myPairing.client.firstContactTitle"})}
                        titleColor="blackTitle"
                        isTitleH1={true}
                        message={firstContactMessage}

                        link={link1.link}
                        linkIcon={link1.linkIcon}
                        linkText={link1.linkText}

                        link2={link2.link}
                        link2Icon={link2.linkIcon}
                        link2Text ={link2.linkText}
                    />
                }

                {displayConfirmedMatchMessage &&
                    <WhiteBoxContent
                        title={intl.formatMessage({ id: "myPairing.client.firstContactTitle.established" })}
                        titleColor="blackTitle"
                        isTitleH1={false}
                        message={intl.locale === 'fr' ? configs.congratulationPairingFR : configs.congratulationPairingEN}
                    />}
            </div>
        )
    }

    useEffect(() => {

        if (Object.keys(myMatchData).length !== 0)
        {
            setRenderFirstContactBox(renderFicheClient());
        }

    }, [myMatchData]);


    useEffect(async () => {
        const sendMail = async () => {

            try{
                await sendReminder(mailData);
            }catch(e){
                console.error("Error sending contact attempt mail: " + e.message)
            }
        }

        if(Object.keys(mailData).length) {
            sendMail();
        }

    }, [mailData]);

    const handleConfirmContact = () => {
        const doConfirmContact = async () =>{
            await confirmStatus(user.clientID, 1);
            await fetchMatchData();

        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">{intl.formatMessage({id: "myPairing.client.firstContactAlertConfirmation"})}</p>
                        <button className="redButton" onClick={async () => {await doConfirmContact();onClose();}}>{intl.formatMessage({ id: "myPairing.sponsor.firstContactConfirmation.Yes" })}</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>{intl.formatMessage({ id: "myPairing.sponsor.firstContactConfirmation.No" })}</button>
                    </div>
                );
            }
        });
    };



    const handleContactAttempt = () => {

        const doConfirmContactAttempt = async () =>{
            try {
                setIsLoading(true);
                setMailData({
                    clientID: user.clientID,
                    contactEmailUlaval: myMatchData.contactEmailUlaval,
                    contactEmailOther: myMatchData.contactEmailOther,
                    contactAttempt: myMatchData.client_contactrequestAttempt,
                });
                //console.log("Sending mail with data:" + JSON.stringify(myMatchData));
                await updateContactRequest("client","contactRequest", user.clientID);
                notifications.setNotification(0);
                await fetchMatchData();
            } catch (error) {
                console.error(error.message);
            } finally{
                setIsLoading(false);
            }
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">{intl.formatMessage({id: "myPairing.client.AddContactAttempt.message"})}</p>
                        <button className="redButton" disabled={isLoading} onClick={async () => {await doConfirmContactAttempt();onClose();}}>{intl.formatMessage({ id: "myPairing.sponsor.firstContactConfirmation.Yes" })}</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>{intl.formatMessage({ id: "myPairing.sponsor.firstContactConfirmation.No" })}</button>
                    </div>
                );
            }
        });
    };

    return (
        <div>
            <h1>{intl.formatMessage({id: "myPairing.title"})}</h1>

            {(displayIntroMessage) &&
                <div dangerouslySetInnerHTML={{__html: intl.locale === "fr" ? configs.welcomePairingEIFR : configs.welcomePairingEIEN}}/>
            }

            {renderFirstContactBox}

        </div>
    );
};

export default MyClientPairing;
