import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import React from "react";
import SessionsTableFrame100 from "../tableLayouts/SessionsTableFrame100";

const SessionsListLayout = (props) => {
    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <SessionsTableFrame100 locale={props.locale}/>
            </main>
            <Footer/>
        </>
    );
}

export default withRouter(injectIntl(SessionsListLayout));
