import React from 'react';
import AppHeader from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import {Link} from "react-router-dom";

class TemplatedNotFound extends React.Component{
    render(){
        return (
            <>
                <AppHeader/>
                <div className="container">
                    <div className="row">
                        <h1 className="wb-inv">(Erreur 404) - <span lang="en">(Error 404)</span></h1>
                    </div>
                    <div className="row">
                        <main role="main" property="mainContentOfPage" className="container">
                            <div className="row mrgn-tp-lg">

                                <section className="col-md-6">
                                    <h2><span className="glyphicon glyphicon-warning-sign mrgn-rght-md"></span> Nous ne pouvons
                                        trouver cette page Web (Erreur 404)</h2>
                                    <p>Nous sommes désolés que vous ayez abouti ici.</p>
                                    <ul>
                                        <li>Retournez à la <Link to="/">page d'accueil</Link></li>
                                    </ul>
                                </section>
                                <section className="col-md-6" lang="en">
                                    <h2><span className="glyphicon glyphicon-warning-sign mrgn-rght-md"></span> We couldn't find
                                        that Web page (Error 404)</h2>
                                    <p>We're sorry you ended up here.</p>
                                    <ul>
                                        <li>Return to the <Link to="/">home page</Link></li>
                                    </ul>
                                </section>
                            </div>
                        </main>
                    </div>
                </div>
                <Footer/>
            </>
        );
    }
}

export default TemplatedNotFound
