import React, {useEffect, useState} from 'react';
import {getCurrentUserToken} from "../../../../services/authService";
import {useVerifyLogin} from "../../common/formFunctionSharing";
import {getUserClientOrSponsorObject} from "../../../../services/usersService";
import MyClientPairing from "./MyClientPairing/MyClientPairing";
import MySponsorPairing from "./MySponsorPairing/MySponsorPairing";

const LoadMyPairing = (locale) => {
    const user = getCurrentUserToken();
    const [userTitles, setUserTitles] = useState({isClient:null,isSponsor:null})
    useVerifyLogin(user);

    useEffect(async () => {
       const getUserInfos = async () => {
           const {data:userTitle} = await getUserClientOrSponsorObject(user.idul);
           // console.log(JSON.stringify(userTitle));
           setUserTitles(userTitle)
       }

       await getUserInfos();

    }, []);

    return (
        <>
            {!!userTitles.isClient && <MyClientPairing/>}
            {!!userTitles.isSponsor && <MySponsorPairing/>}
        </>

    );
};

export default LoadMyPairing;