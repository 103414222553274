import React from "react";
import {Redirect} from "react-router-dom"
import auth from '../../services/authService';

class Logout extends React.Component {

    state = {
        redirect: false
    }

    componentDidMount() {
        auth.logout();
        this.setState({ redirect: true });
    }

    render(){
        if (this.state.redirect) {
            return <Redirect to="/" />
        }
        return <div>Logout in progress...</div>;
    }
}

export default Logout;
