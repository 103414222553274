import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/formations";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getFormationsList(language){
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}
    return httpService.get(apiEndpoint + "?locale=" +lang, {headers: {"x-auth-token" : jwt}})
}

export function getAvailableFormationsList(language){
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}
    return httpService.get(apiEndpoint + "?locale=" +lang + "&availableOnly=1", {headers: {"x-auth-token" : jwt}})
}


export function getAllFormations(filterString){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/?filterString=" + filterString + "&showInactive=0", {headers: {"x-auth-token" : jwt}})
}

export function getRegisteredSponsorsForFormationID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "/registeredforid/" + id, {headers: {"x-auth-token" : jwt}})
}

export function getFormationByID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
}

export function UpdateFormation(formationObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = formationObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, formationObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function CreateNewFormation(formationObject){
    if(formationObject.id !== undefined)
        delete formationObject.id

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}`, formationObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}