import React from 'react';
import {FormattedMessage} from "react-intl";
import * as PropTypes from "prop-types";
import {injectIntl} from "react-intl";
import "./InputTimeDateSeparated.css"


const InputTimeDateSeparated = ({errors, time, date, onChange, name, formattedMessage, displayTitles, intl}) => {

    const {formatMessage} = intl;

    const convertDoubleDigits = n =>{
        if(n <= 9){
            return "0" + n;
        }
        return n
    }

    const displayTitle = displayTitles?"displayDTTitle":"";

    const date1 = new Date(date);
    const time1 = new Date(time);
    let formattedDate = "";
    let formattedTime = "";

    const min = convertDoubleDigits(time1.getUTCMinutes())
    const hour = convertDoubleDigits(time1.getUTCHours())

    const dd = convertDoubleDigits(date1.getUTCDate())
    const mm = convertDoubleDigits(date1.getUTCMonth()+1)
    const YYYY = date1.getUTCFullYear()

    formattedTime = time? hour+":"+min : "00:00"
    formattedDate = date? YYYY+"-"+mm+"-"+dd : 0


    Object.size = function(obj) {
        let size = 0, key;
        for (key in obj) {
            if (obj.hasOwnProperty(key)) size++;
        }
        return size;
    };

    let errorFound = null;
    errors.forEach(item => {
        if(Object.size(item)){errorFound = true;}
    })

    return (
        <div className="form-group">
            <div className="row">
                <div className="col-md-4">
                    <label
                        htmlFor={name.concat("Time")}
                        className={`dtLeftSideLabel ${displayTitle}`}>
                        <span className={"field-name"}>
                            <FormattedMessage id={formattedMessage}/>
                        </span>
                    </label>
                </div>

                <div className="col-md-5">
                    <div className="dtField">
                        <span className={`dtLeftSideLabel ${displayTitle}`}>{formatMessage({id:"InputTimeDateSeparated.time"})}</span>
                        <input
                            value={formattedTime}
                            onChange={onChange}
                            type="time"
                            id={name.concat("Time")}
                            name={name.concat("Time")}
                            className="form-control"
                        />
                    </div>

                    <div className="dtField">
                        <span className={`dtLeftSideLabel ${displayTitle}`}>{formatMessage({id:"InputTimeDateSeparated.date"})}</span>
                        <input
                            value={formattedDate}
                            onChange={onChange}
                            type="date"
                            data-rule-dateiso={true}
                            id={name.concat("Date")}
                            name={name.concat("Date")}
                            className="form-control"
                        />
                    </div>
                </div>

                <div className="col-md-3">
                    {errorFound &&
                    <div className="alert alert-danger">
                        {errors.map(err =>
                            {
                                if (Object.size(err) > 0 ){
                                    return <span className={"validationCustomMessage"} key={errors.indexOf(err)}>{formatMessage({id:err.msg})}</span>
                                }
                                else return null
                            }

                        )}
                    </div>}
                </div>
            </div>
        </div>
    );
};

InputTimeDateSeparated.propTypes = {
    errors : PropTypes.array,
    time: PropTypes.string.isRequired,
    date: PropTypes.string.isRequired,
    onChange : PropTypes.func.isRequired,
    name : PropTypes.string.isRequired,
    displayTitles : PropTypes.bool.isRequired,
    formattedMessage : PropTypes.string,
    intl : PropTypes.object.isRequired
}

export default injectIntl(InputTimeDateSeparated);
