import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/sponsors";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getAllSponsors(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}`, {headers: {"x-auth-token" : jwt}});
    }
    catch (ex){
        return null;
    }
}

export function getCurrentSponsor() {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/me`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function currentSponsorIsMatched(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/ismatched`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function currentSponsorIsFilled() {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/isfilled`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function resetCurrentSponsor() {
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.delete(`${apiEndpoint}/me`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function UpdateCurrentSponsor(sponsorObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.put(`${apiEndpoint}/me`, sponsorObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function UpdateSponsor(sponsorObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = sponsorObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, sponsorObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function UpdateSponsorFormation(sponsorObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = sponsorObject.id;
        return httpService.put(`${apiEndpoint}/formation/${id}`, sponsorObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function getSponsorByID(id) {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function getMatchListByID(id) {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/matchList/${id}`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function getMyClientsBasicInfos(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/myClientsList`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}
