import React, {useEffect, useState} from 'react';
import * as ExcelJS from "exceljs";
import dayjs from "dayjs";
import {
    convertSessionIDToString,
    convertStateNoToString,
    downloadWorkbook, GenEE_NonJumelesSheet, GenEESheet, GenFormationsSheets,
    GenJumelagesSheet, GenPM_NonJumelesSheet, GenPMSheet, InsertRegisteredSponsorsToFormationsList,
} from "./ExcelJSUtilities";
import './ExcelJSExport.css';
import httpService from "../../../services/httpService";
import {getAllClients} from "../../../services/clientService";
import {getAllSponsors} from "../../../services/sponsorService";
import {getFormationsList} from "../../../services/formationsService";
import {getSessionsList} from "../../../services/sessionsService";


const ExcelJsExportButton = (props) => {

    const [matchSheet, setMatchSheet] = useState(props.matchesList);
    const [clientsList, setClientList] = useState([]);
    const [clientsNotMatched, setClientsNotMatched] = useState([]);
    const [sponsorsList, setSponsorsList] = useState([]);
    const [sponsorsNotMatched, setSponsorsNotMatched] = useState([]);
    const [facultyList, setFacultyList] = useState(props.facultyList);
    const [countryList, setCountryList] = useState(props.countryList);
    const [languageList, setLanguageList] = useState(props.languageList);
    const [preferencesList, setPreferencesList] = useState(props.preferencesList);
    const [formationsList, setFormationsList] = useState([]);
    const [referencesList, setReferencesList] = useState(props.referencesList);
    const [interestsList, setInterestsList] = useState(props.interestsList);
    const [sessionsList, setSessionsList] = useState([]);
    const [nextSessionList, setNextSessionList] = useState([{id:0, name: "Je ne sais pas"},{id:1, name: "À Québec"},{id:2, name: "À distance, de mon pays"}]);
    const [studyLevelList, setStudyLevelList] = useState([{id:0, name: "Premier cycle (certificat, baccalauréat)"},{id:1, name: "Deuxième cycle (Maîtrise, DESS)"},{id:2, name: "Troisième cycle (Doctorat)"}]);


    const LoadData = async () => {
        const [
            {data: clientsList},
            {data: sponsorsList},
            {data: formationsList},
            {data : sessionsList}
        ] = await httpService.all([getAllClients(), getAllSponsors(), getFormationsList("fr"), getSessionsList('fr', 0, 1)]);

        clientsList.shift();  //Remove summary at position 0
        sponsorsList.shift();

        const formations = await InsertRegisteredSponsorsToFormationsList(formationsList);
        setFormationsList(formations)


        clientsList.forEach(client => {client.state = convertStateNoToString(client.state)})
        sponsorsList.forEach(sponsor => {sponsor.state = convertStateNoToString(sponsor.state)})

        clientsList.forEach(client => {client.firstSession_text = convertSessionIDToString(client.firstSession_id, sessionsList)})
        sponsorsList.forEach(sponsor => {sponsor.firstSession_text = convertSessionIDToString(sponsor.firstSession_id, sessionsList)})

        return {
            clientsList,
            sponsorsList,
            sessionsList,
            clientsNotMatched : clientsList.filter(user => {return user.takenInChargeByName === null}),
            sponsorsNotMatched : sponsorsList.filter(user => {return user.sponsorLoadCurrent === 0}),
            formationsList
        }

    }


    const generateExcelJsFile = async () => {
        const fetchedData = await LoadData();
        const workbook = new ExcelJS.Workbook();
        workbook.creator = 'Jumelage - Université Laval';
        workbook.lastModifiedBy = 'Jumelage - Université Laval';
        workbook.created = new Date(dayjs().year(), dayjs().month(), dayjs().day());
        workbook.modified = new Date();

        const buildObject = {
            matches : matchSheet,
            clientsList: fetchedData.clientsList,
            sponsorsList: fetchedData.sponsorsList,
            sessionsList : fetchedData.sessionsList,
            clientsNotMatched : fetchedData.clientsNotMatched,
            sponsorsNotMatched : fetchedData.sponsorsNotMatched,
            formationsList : fetchedData.formationsList,
            preferencesList : preferencesList,
            referencesList : referencesList,
            countryList : countryList,
            languageList: languageList,
            facultyList : facultyList,
            studyLevelList : studyLevelList,
            nextSessionList : nextSessionList,
            position: 'A2'
        }

        GenJumelagesSheet(workbook, buildObject);
        GenEESheet(workbook, buildObject);
        GenEE_NonJumelesSheet(workbook, buildObject);
        GenPMSheet(workbook, buildObject);
        GenPM_NonJumelesSheet(workbook, buildObject);
        GenFormationsSheets(workbook, buildObject);

        await downloadWorkbook(workbook);

    }





    return (
        <span>
            <button onClick={generateExcelJsFile} className="downloadButton">
                Télécharger les données en format Microsoft Excel
            </button>
        </span>
    );
}

export default ExcelJsExportButton;