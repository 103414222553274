import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import LoadSponsorForm from "../contents/Forms/Sponsor/LoadSponsorForm";
import {withRouter} from "react-router-dom";
import {injectIntl} from "react-intl";

const SponsorFormLayout = ({match, locale}) => {

    return(
        <>
            <Header languageDisabled={true}/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <LoadSponsorForm locale={locale} id={match.params.id}/>
            </main>
            <Footer/>
        </>
    );
}


export default withRouter(injectIntl(SponsorFormLayout));
