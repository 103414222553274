import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/faculty?locale=";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getFacultyList(language){
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}
    return httpService.get(apiEndpoint + lang, {headers: {"x-auth-token" : jwt}})
}
