import React, {Fragment, useEffect} from 'react';
import {Link, NavLink} from "react-router-dom";
import './LoginDropdown.css';
import authService from "../../../../services/authService";
import {FormattedMessage} from "react-intl";

const LoginDropdown = () => {

    const user = authService.getCurrentUserToken();
    const username = user?user.idul:null;

    return (
            <>

                {user?
                    <></>
                    :
                    <>
                        <Link to="#" className="ul-secure-login" aria-label="Espace sécurisé" title="Espace sécurisé"><i className="ul-i-login" aria-role="presentation" aria-hidden="true"></i></Link>
                    </>
                }

                <nav className="ul-secure-menu-container" style={user?{}:{display:"none"}}>
                    <button type="button" id="ul-secure-menu-button" aria-haspopup="true" className="ul-secure-menu-trigger" aria-expanded="false" aria-controls="ul-secure-menu" aria-label="Ouvrir le menu sécurisé" title="Espace sécurisé">
                        <i aria-hidden="true" aria-role="presentation" className="ul-i-secure-logged"></i>
                    </button>
                    <ul id="ul-secure-menu" className="ul-secure-menu menu" role="menu" aria-labelledby="ul-secure-menu-button" hidden>
                        {/*<li className="menu-item" role="presentation" aria-selected="false">*/}
                        {/*    <Link className="menu-link" to="Profile" role="menuitem">Mon espace</Link>*/}
                        {/*</li>*/}
                        <li className="menu-item" role="presentation">
                            <Link className="menu-link" to={'/logout'}><FormattedMessage id="navigation1.logoutMessage"/>{username}</Link>
                        </li>
                    </ul>
                </nav>

        </>
    )
}

export default LoginDropdown;


