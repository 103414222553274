import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/preferences";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getPreferencesList(language, showItemsFor = 0, showInactive = 0){
    //showItemsFor : 0 = All , 1 = Clients, 2 = Sponsors
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){lang="fr"}

    return httpService.get(apiEndpoint + `?locale=${lang}&showItemsFor=${showItemsFor}&showInactive=${showInactive}`, {headers: {"x-auth-token" : jwt}})
}

export function getAllPreferences(filterString){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + "?filterString=" + filterString + "&showInactive=1", {headers: {"x-auth-token" : jwt}})
}

export function getPreferenceByID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
}

export function UpdatePreference(preferenceObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = preferenceObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, preferenceObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function CreateNewPreference(preferenceObject){
    if(preferenceObject.id !== undefined)
        delete preferenceObject.id

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}`, preferenceObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}