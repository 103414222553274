import React, {Component} from 'react';
import {FormattedMessage} from "react-intl";

class LoadingComponent extends Component {
    render() {
        return (
            <div className="text-center">
                <div className="spinner-border m-5" role="status">
                    <span className="sr-only">
                        <FormattedMessage id="api.loading" tagName="div" />
                    </span>
                </div>
            </div>
        );
    }
}

export default LoadingComponent;
