import React from 'react';
import Form from "../../common/Form";
import Joi from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";
import {CreateNewSession, UpdateSession} from "../../../../services/sessionsService";


class SessionForm extends Form {

    state = {
        locale : this.props.locale,
        session : this.props.session,

        errors:{}
    }

    constructor(props) {
        super(props);
        const currentSession = this.props.session;
        this.state.sendButtonDisabled = false;

        this.state.data = {
            id : currentSession.id,
            active : (currentSession.active === 1)?true:false,
            fr : currentSession.fr,
            en : currentSession.en,
            clientOnly: (currentSession.clientOnly === 1)?true:false,
            sponsorOnly: (currentSession.sponsorOnly === 1)?true:false,

            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        active : Joi.boolean(),
        fr: Joi.string().required(),
        en : Joi.string().required(),
        clientOnly: Joi.boolean(),
        sponsorOnly: Joi.boolean(),
    };

    doSubmit = async e => {
        this.setState({sendButtonDisabled:true})
        let sessionObject = {...this.state.data};

        delete sessionObject.errors;
        if(sessionObject.id){
            try{
                await UpdateSession(sessionObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }
        else{
            try{
                await CreateNewSession(sessionObject);
                this.props.history.goBack();
            }catch(err){
                console.error(err.message);
            }
        }

    };


    render() {
        //console.log(JSON.stringify(this.state.data));
        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="session.form.h1" /> {this.props.session.id}</h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit}>
                        <h2><FormattedMessage id="session.form.h2" /></h2>
                            {this.renderInput("fr", "session.form.fr")}
                            {this.renderInput("en", "session.form.en")}
                            {this.renderCheckBox("active", "session.form.active")}
                            {this.renderCheckBox("clientOnly", "session.form.clientOnly")}
                            {this.renderCheckBox("sponsorOnly", "session.form.sponsorOnly")}
                        <button disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                            {this.state.sendButtonDisabled ? <FormattedMessage id="session.form.savingChanges"/> : <FormattedMessage id="session.form.saveChanges"/>}
                        </button>

                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(SessionForm));
