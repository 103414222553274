import React from 'react';
import Form from "../../common/Form";
import JoiBase from "joi";
import {FormattedMessage, injectIntl} from "react-intl";
import JoiDate from "@hapi/joi-date";
import {UpdateCurrentClient, UpdateClient} from "../../../../services/clientService";
import {toast} from "react-toastify";
import DisplayFormErrors from "../../common/formElements/DisplayFormErrors";
import {withRouter} from "react-router-dom";
import ToastStdMessage from "../../common/htmlElements/ToastBoxes/ToastStdMessage";
import authService from "../../../../services/authService";
import './ClientForm.css'
import {UpdateIdulNotes} from "../../../../services/idulNotesService";

import RichTextEditor from "react-rte";
import {sendMail} from "../../../../services/mailsService";

const Joi = JoiBase.extend(JoiDate); // extend Joi with Joi Date
const NEXTSESSIONISQUEBECVALUE = 1;


class ClientForm extends Form {

    currentClient = this.props.currentClient;

    createSelectItemsList = (ObjectList) => {
        let returnObject = [];

        // eslint-disable-next-line
        for (let [key, value] of Object.entries(ObjectList)) {
            returnObject.push({value: parseInt(value.id), label: value.name})
        }
        return returnObject;
    }

    state = {
        locale : this.props.locale,
        idulNotes : this.props.idulNotes?RichTextEditor.createValueFromString(this.props.idulNotes, 'html'):RichTextEditor.createEmptyValue(),
        selectedSponsor : this.props.selectedSponsor,
        facultyList : this.createSelectItemsList(this.props.facultyList),
        interestsList : this.createSelectItemsList(this.props.interestsList),
        languagesList : this.createSelectItemsList(this.props.languagesList),
        preferencesList : this.createSelectItemsList(this.props.preferencesList),
        countryList : this.createSelectItemsList(this.props.countryList),
        referencesList : this.createSelectItemsList(this.props.referencesList),
        sessionsList : this.createSelectItemsList(this.props.sessionsList),
        clientStateList : [   //Modifier aussi components\contents\common\htmlElements\TableComponent\subTableComponents\CRUDTableBody.jsx
            {value: 1, label: "Nouvelle candidature"},
            {value: 2, label: "Candidature approuvée"},
            {value: 3, label: "Candidat désisté"},
            {value: 5, label: "Candidat refusé"},
            {value: 7, label: "À vérifier"},
            {value: 10, label: "Candidat approuvé et jumelé"}
        ],

        errors:{}
    }

    constructor(props) {
        super(props);
        const currentClient = this.props.currentClient;
        const locale = this.props.locale;
        this.state.sendButtonDisabled = false;

        this.state.studyLevelList = [
            {value: 0, label: (locale === "fr")?"Premier cycle (certificat, baccalauréat)":"Undergraduate (certificate, bachelor)"},
            {value: 1, label: (locale === "fr")?"Deuxième cycle (maîtrise, DESS)":"Graduate program (masters, DESS)"},
            {value: 2, label: (locale === "fr")?"Troisième cycle (doctorat)":"Post-graduate program (doctorate)"}
        ];
        this.state.genderList = [
            {value: 0, label : (locale === "fr")?"Femme":"Female"},
            {value: 1, label : (locale === "fr")?"Homme":"Male"},
            {value: 2, label : (locale === "fr")?"Je préfère ne pas répondre":"I prefer not to answer"}
        ];
        this.state.nextSessionList = [
            {value: 1, label: (locale === "fr")?"À Québec":"In Quebec City"},
            {value: 2, label: (locale === "fr")?"À distance, de mon pays":"Remotely, from my own country"},
            {value: 0, label: (locale === "fr")?"Je ne sais pas":"I don't know"}
        ];

        this.state.data = {
            id : currentClient.id,
            state: currentClient.state,
            idul : currentClient.idul,
            ni : currentClient.ni,
            firstName : currentClient.firstName,
            lastName : currentClient.lastName,
            originCountry_id: currentClient.originCountry_id, //[{"value":1,"label":"Inconnue"}]
            birthdate : currentClient.birthdate,
            gender: currentClient.gender, //[{"value":1,"label":"Inconnue"}]
            emailUlaval : currentClient.emailUlaval,
            emailOther : currentClient.emailOther,
            phone : currentClient.phone,
            phoneOther : currentClient.phoneOther,
            messenger: currentClient.messenger,
            arrivalInQuebec : currentClient.arrivalInQuebec,
            firstSession_id : currentClient.firstSession_id,
            nextSession : currentClient.nextSession,
            faculty_id: currentClient.faculty_id,
            studyLevel: currentClient.studyLevel, //[{"value":1,"label":"Inconnue"}]
            isVisitor : currentClient.isVisitor?true:false,
            languagePrimary_id: currentClient.languagePrimary_id,
            languageOther: currentClient.languageOther,
            interests : currentClient.interests,
            preferences : currentClient.preferences,
            references : currentClient.references,
            comments : currentClient.comments?currentClient.comments:"",

            errors:[]
        };

    }

    schemaDefinition = {
        id: Joi.number(),
        state: Joi.number(),
        idul: Joi.string().max(10).required(),
        ni: Joi.string().max(10).regex(/^\d+$/).required(),
        firstName : Joi.string().max(50).required(),
        lastName : Joi.string().max(50).required(),
        originCountry_id : Joi.number().required(),
        birthdate : Joi.date().format("YYYY-MM-DD").required(),
        gender : Joi.number().required(),
        emailUlaval : Joi.string().max(100).regex(/^(.+)([uU][lL][aA][vV][aA][lL].[cC][aA])$/),
        emailOther : Joi.string().max(100).email({ tlds: {allow: false} }).allow(null),
        phone : Joi.string().max(30).allow(null),
        phoneOther : Joi.string().max(30).allow(null),
        messenger : Joi.string().max(50).allow(null),
        arrivalInQuebec : Joi.date().format("YYYY-MM-DD").allow('',null),
        firstSession_id : Joi.number().required(),
        nextSession : Joi.number().allow(null),
        isVisitor : Joi.boolean().allow(null),
        faculty_id : Joi.number().required(),
        studyLevel : Joi.number().required(),
        languagePrimary_id : Joi.number().required(),
        languageOther : Joi.array().items(Joi.number()).allow(null),
        interests : Joi.array().items(Joi.number()).allow(null),
        preferences : Joi.array().items(Joi.number()).allow(null),
        references : Joi.array().items(Joi.number()).allow(null),
        comments : Joi.string().max(1000).allow('',null),
        takenInChargeByID : Joi.number().allow(null),
        takenInChargeByName : Joi.string().allow('',null)
    };

    updateObjectUsers = (userObject) => {
        const updatedUsers = this.props.usersList.map((user) => {
            if (user.id === userObject.id) {
                return { ...user, ...userObject };
            }
            return user;
        });
        this.props.setUsersList(updatedUsers);
    };

    removeMatchFromClient = (userObject) => {
        return {...userObject, takenInChargeByID:null, takenInChargeByName:""}
    }

    createMailObject = (userObject) => {
        return {mailTemplate: "EEInscrits" + this.props.locale.toUpperCase(), to: userObject.emailUlaval + ", " + userObject.emailOther, cci: "",}
    }

    doSubmit = async e => {
        const { formatMessage } = this.props.intl;
        const SAME_GENDER_PREF_ID = 1;
        const GENDER_UNDEFINED_VALUE = 2;

        const APPROVED_STATE = 2;
        const APPROVED_AND_MATCHED_STATE = 10;
        const doNotDeleteMatchStatus = [APPROVED_AND_MATCHED_STATE, APPROVED_STATE];

        this.setState({sendButtonDisabled:true});
        let userObject = {...this.state.data};
        userObject.active = true;

        if (this.state.data.nextSession !== NEXTSESSIONISQUEBECVALUE){
            userObject.arrivalInQuebec = null;
        }

        delete userObject.errors;

        if (!doNotDeleteMatchStatus.includes(userObject.state)){
            userObject = this.removeMatchFromClient(userObject);
        }

        const showGenderWarningMessage = (userObject.gender === GENDER_UNDEFINED_VALUE
                                            &&
                                          userObject.preferences?.includes(SAME_GENDER_PREF_ID));

        if(showGenderWarningMessage){
            toast.error(<ToastStdMessage title={formatMessage({id: "toast.error.gender.sameGenderPrefUnavailable.title"})}
                                         message={formatMessage({id: "toast.error.gender.sameGenderPrefUnavailable.content"})}
                                         icon="slash-square"  // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
            />, {autoClose:false});
            this.setState({sendButtonDisabled:false});
        }
        else{
            try{
                if (this.props.isAdminForm) {
                    await UpdateClient(userObject);
                    await UpdateIdulNotes({
                                        idul: this.currentClient.idul,
                                        note: this.state.idulNotes.toString('html')
                    });
                }
                else if (this.currentClient.date_registered === null) {
                    const mailObject = this.createMailObject(userObject);
                    await UpdateCurrentClient(userObject);
                    await sendMail(userObject, mailObject);
                }
                else {
                    await UpdateCurrentClient(userObject);
                }


                if(this.props.setEditClientWindowState){
                    this.props.setEditClientWindowState(false);
                    this.updateObjectUsers(userObject);
                }
                else{
                    this.props.history.push(`/${this.props.locale}/homepage#ClientCard`);
                }

            }catch(err){
                console.error(err.message);
            }
        }
    };

    arrivalQuebecInputDateHiddenState = () => {
        return this.state.data.nextSession !== NEXTSESSIONISQUEBECVALUE;
    }


    handleIdulNotesChange = (idulNotes) =>{
        this.setState({
            idulNotes: idulNotes
        });
    }

    render() {
        const user = authService.getCurrentUserToken();
        const { formatMessage } = this.props.intl;
        const isAdmin = user?user.isAdmin:null;
        const showDeleteMatchWarning = this.props.selectedSponsor && ![2,10].includes(this.state.data.state);
        const showWarningBox1 = "WarningMessage".concat(showDeleteMatchWarning?"Enabled":"Disabled");

        const STATE_APPROVED = 2
        const STATE_MATCHED = 10
        const stateList = this.state.selectedSponsor?
            this.state.clientStateList.filter(function( obj ) {
                return obj.value !== STATE_APPROVED;
            })
            :
            this.state.clientStateList.filter(function( obj ) {
                return obj.value !== STATE_MATCHED;
            });


        return (
            <>
                <div className="container">
                    <h1><FormattedMessage id="client.form.Title"/></h1>
                    <DisplayFormErrors
                        errors = {this.state.data.errors}
                    />

                    <form onSubmit={this.handleSubmit}>

                        {isAdmin &&
                        <>
                            <div style={{backgroundColor: "#c0c0c0", padding:20, marginTop:20,marginBottom:20}}>
                                <h2>Section administrative</h2>
                                {this.props.selectedSponsor &&
                                    <div className="matchedBox">
                                        <img src={require('./images/sponsoredIcon.png')} className="sponsoredImage" alt="Matched"/>
                                        <p>PM sélectionné: <span>{this.props.selectedSponsor?.firstName} {this.props.selectedSponsor?.lastName}</span></p>
                                    </div>
                                }
                                {this.renderInputDropDown("state", "client.form.state", stateList, false)}

                                <p className={showWarningBox1}>{showDeleteMatchWarning && `ATTENTION ! Le match sera supprimé lors de la sauvegarde si l'état de candidature n'est plus à "Candidature approuvé" ou "Confirmé"!`}</p>

                                <div className="row">
                                    <div className="col-md-4">Notes administratives</div>
                                    <div className="col-md-8">
                                        <RichTextEditor
                                            value={this.state.idulNotes}
                                            onChange={this.handleIdulNotesChange}
                                        />
                                    </div>
                                </div>

                                <button disabled={this.state.sendButtonDisabled} className="btn btn-primary">
                                    {this.state.sendButtonDisabled ? <FormattedMessage id="client.form.savingChanges"/> : <FormattedMessage id="client.form.saveChanges"/>}
                                </button>
                            </div>

                        </>
                        }

                        <h2><FormattedMessage id="client.form.Identification"/></h2>

                            {this.renderInputViewOnly("idul", "client.form.idul")}
                            {this.renderInputViewOnly("firstName", "client.form.firstName")}
                            {this.renderInputViewOnly("lastName", "client.form.lastName")}

                            {this.renderDatePickerField("birthdate", "client.form.birthdate", false,null, "-15y","-75:-15",true)}
                            {this.renderInputDropDown("originCountry_id", "client.form.originCountry_id", this.state.countryList,false,true)}
                            {this.renderInputDropDown("gender", "client.form.gender", this.state.genderList,false,true)}
                            {this.renderInputDropDown("nextSession", "client.form.nextSession", this.state.nextSessionList)}
                            <div hidden={this.arrivalQuebecInputDateHiddenState()}>
                                {this.renderDatePickerField("arrivalInQuebec", "client.form.arrivalInQuebec", false, "-1y","+1y","-1y:+1y", true)}
                            </div>
                        <h2><FormattedMessage id="client.form.ContactInQuebec"/></h2>
                            {this.renderInput("emailUlaval", "client.form.emailUlaval", false, "text", null, null, true)}
                            {this.renderInput("emailOther", "client.form.emailOther")}
                            {this.renderInput("phone", "client.form.phone", false, "text", null, null, false)}
                            {this.renderInput("phoneOther", "client.form.phoneOther")}
                            {this.renderInput("messenger", "client.form.messenger")}
                            {this.renderCheckBox("isVisitor", "client.form.isVisitor", formatMessage({id:"client.form.isVisitor.tooltip"}))}
                        <h2><FormattedMessage id="client.form.Studies"/></h2>
                            {this.renderInput("ni", "client.form.ni", false, "text", null, null, true)}
                            {this.renderInputDropDown("firstSession_id", "client.form.firstSession", this.state.sessionsList,false,true)}
                            {this.renderInputDropDown("faculty_id", "client.form.faculty_id", this.state.facultyList,false,true)}
                            {this.renderInputDropDown("studyLevel", "client.form.studyLevel", this.state.studyLevelList,false,true)}
                        <h2><FormattedMessage id="client.form.Language"/></h2>
                            {this.renderInputDropDown("languagePrimary_id", "client.form.languagePrimary_id", this.state.languagesList,false,true)}
                            {this.renderInputDropDown("languageOther", "client.form.languageOther", this.state.languagesList, true)}
                        <h2><FormattedMessage id="client.form.Pairing"/></h2>
                            <div><FormattedMessage id="client.form.preferenceWarning"/></div><br/>
                            {this.renderInputDropDown("preferences", "client.form.preferences", this.state.preferencesList, true)}
                            {this.renderInputDropDown("interests", "client.form.interests", this.state.interestsList, true)}
                        <h2><FormattedMessage id="client.form.Other"/></h2>
                            {this.renderInputDropDown("references", "client.form.references", this.state.referencesList, true)}
                            {this.renderTextArea("comments", "client.form.comments", true)}

                        <div className="collecteRenseignementsPerso">
                            <h2><FormattedMessage id="client.form.collecteRenseignementsPerso.Title"/></h2>
                            <p><div dangerouslySetInnerHTML={{__html: formatMessage({id:"client.form.collecteRenseignementsPerso.Message"})}}/></p>
                        </div>

                        <strong style={{color: "red"}}><p><FormattedMessage id="client.form.submitWarning"/></p></strong>
                        <button disabled={this.state.sendButtonDisabled} className="btn btn-primary submit-btn">
                            {this.state.sendButtonDisabled ? <FormattedMessage id="client.form.savingChanges"/> : <FormattedMessage id="client.form.saveChanges"/>}
                        </button>

                    </form>
                </div>
            </>
        );
    }
}

export default withRouter(injectIntl(ClientForm));
