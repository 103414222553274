import {getCurrentUserToken} from "../../../../services/authService";
import React, {useEffect, useState} from "react";
import {useVerifyLogin} from "../../common/formFunctionSharing";
import {getUserClientOrSponsorObject} from "../../../../services/usersService";
import MyClientPairing from "../MyPairing/MyClientPairing/MyClientPairing";
import MySponsorPairing from "../MyPairing/MySponsorPairing/MySponsorPairing";
import {getPublicConfigsByNames} from "../../../../services/configsService";
import {useIntl} from "react-intl";
import EssentialDocuments from "./EssentialDocuments";

const LoadEssentialDocuments = () => {
    const user = getCurrentUserToken();
    const [userTitles, setUserTitles] = useState({isClient:null,isSponsor:null})
    const intl = useIntl();
    const [userSpecificTexts, setUserSpecificTexts] = useState("");
    useVerifyLogin(user);

    useEffect(async () => {
        const getUserInfos = async () => {
            const {data:userTitle} = await getUserClientOrSponsorObject(user.idul);
            setUserTitles(userTitle)
        }

        await getUserInfos();

    }, []);

    useEffect(async () => {
        const getConfigs = async () => {
            let userConfig = "";
            if (userTitles.isClient)
            {
                userConfig = await getPublicConfigsByNames(["essentialDocumentsEI" + intl.locale]).data;
                setUserSpecificTexts(Object.values(userConfig.data)[0]);
            } else if (userTitles.isSponsor)
            {
                userConfig = await getPublicConfigsByNames(["essentialDocumentsPM" + intl.locale]);
                setUserSpecificTexts(Object.values(userConfig.data)[0]);
            }
        }

        await getConfigs();

    }, [userTitles]);

    return (
        <>
            <EssentialDocuments userSpecificTexts={userSpecificTexts} />
        </>

    );
}

export default LoadEssentialDocuments;