import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowPreferencesTable extends Component {

    columns = [
        {path:"id",label:this.props.intl.formatMessage({id: "table.preferences.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.preferences.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.preferences.fr.short"})},
        {path:"en",label:this.props.intl.formatMessage({id: "table.preferences.en.short"})},
        {path:"clientOnly",label:this.props.intl.formatMessage({id: "table.preferences.clientOnly.short"})},
        {path:"sponsorOnly",label:this.props.intl.formatMessage({id: "table.preferences.sponsorOnly.short"})},
        {path:"priorityLevel",label:this.props.intl.formatMessage({id: "table.preferences.priorityLevel.short"})},
        {key:"edit", content: preference => (
                <Link to={`./manage-preferences/${preference.id}`} >{this.props.intl.formatMessage({id: "table.preferences.editLink"})}</Link>
            )}
    ];

    render() {
        const {preferencesList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {preferencesList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowPreferencesTable);
