import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import {FormattedMessage, injectIntl} from "react-intl";
import HorizontalCard from "../../common/htmlElements/horizontalCard";
import LoadingComponent from "../../common/loadingComponent";
import httpService from "../../../../services/httpService";
import {getAllConfigs} from "../../../../services/configsService";
import {currentClientIsFilled, resetCurrentClient, currentClientIsMatched} from "../../../../services/clientService";
import {currentSponsorIsFilled, resetCurrentSponsor, currentSponsorIsMatched} from "../../../../services/sponsorService";
import Moment from 'moment';
import { extendMoment } from 'moment-range';
import authService from "../../../../services/authService";
import ICAL from 'ical.js';
import { saveAs } from 'file-saver';

import "./homepageContent.css"
import BlackBoxContent from "../../common/htmlElements/BlackBoxContent/BlackBoxContent";
import GreyBoxContent from "../../common/htmlElements/GreyBoxContent/GreyBoxContent";
import InfoBoxContent from "../../common/htmlElements/InfoBoxContent/InfoBoxContent";
import {confirmAlert} from "react-confirm-alert";

const moment = extendMoment(Moment);

class HomepageContent extends Component {

    _isMounted = false;

    user = authService.getCurrentUserToken();
    username = this.user?this.user.idul:null;

    state = {
        locale : this.props.locale,
        isLoading : true,
        ClientFormIsFilled : false,
        ClientIsMatched : false,
        SponsorIsMatched : false,
        SponsorFormIsFilled : false,
        configs :  //default configs to display when user is not logged id
            [
                {"id":1,"name":"startDate","value":"2000-01-01"},
                {"id":2,"name":"endDate","value":"20000-01-02"},
                {"id":3,"name":"startTime","value":"15:10"},
                {"id":4,"name":"endTime","value":"16:30"},
                {"id":5,"name":"infoBoxTitleFR","value":"Informations"},
                {"id":6,"name":"infoBoxTitleEN","value":"informations"},
                {"id":7,"name":"infoBoxTextFR","value":""},
                {"id":8,"name":"infoBoxTextEN","value":""}
            ]
    }

    constructor(props) {
        super(props);
        this.state.locale = this.props.locale;
        this.state.userIsLoggedIn = this.props.user?true:false;
        this.state.userToken = this.props.user;
    }

    scrollToHash() {
        // Vérifiez si un hash existe dans l'URL
        if (window.location.hash !== '') {
            // Retirez le caractère '#' du hash
            let id = window.location.hash.replace('#', '');
            // Utilisez 'document.querySelector' pour trouver l'élément avec l'ID correspondant
            let element = document.querySelector(`#${id}`);
            // Si l'élément existe, faites défiler jusqu'à cet élément
            if (element) element.scrollIntoView({ behavior: 'smooth' });
        }
    }

    getConfig = (configName) => {
        const config = this.state.configs.filter(obj => {return obj.name === configName});
        if (config[0] !== undefined){
            return config[0].value;
        }
        else return null;
    }

    componentDidMount() {
        this._isMounted = true;
        if (!authService.getCurrentUserToken()){
            this.setState({userIsLoggedIn : false, isLoading:false})
        }
        if (this.props.user) {
            this.onPageLoad();
        }
        this.scrollToHash();
    }

    componentDidUpdate() {
        this.scrollToHash();
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onPageLoad = async () => {
        try{
            this.setState({isLoading:true});
            const [
                {data: ClientFormIsFilled},
                {data: ClientIsMatched},
                {data: SponsorIsMatched},
                {data: SponsorFormIsFilled},
                {data: configs}
            ] = await httpService.all([
                currentClientIsFilled(),
                currentClientIsMatched(),
                currentSponsorIsMatched(),
                currentSponsorIsFilled(),
                getAllConfigs()
            ]);
            if (this._isMounted){
                this.setState({
                    ClientFormIsFilled,
                    ClientIsMatched,
                    SponsorIsMatched,
                    SponsorFormIsFilled,
                    configs,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false});
            }
        }

    };

    redirectMonJumelage = () =>{
        if (window.matchMedia("(max-width: 992px)").matches) {
            window.location.href = `/${this.props.locale}/my-pairing`;
        } else  {
            this.props.history.push(`/${this.props.locale}/my-pairing`);
        }
    }

    deleteClientProfile = async () => {
        const { formatMessage } = this.props.intl;

        const doDeleteClientProfile = async () =>{
            try {
                await resetCurrentClient();
                this.onPageLoad();
            }catch (e) {
                console.error(e.message);
            }
        }
//<h2><FormattedMessage id="client.form.Identification"/></h2>
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">{formatMessage({id:"homepage.confirmWithdrawal"})}</p>

                        <button className="redButton" onClick={async () => {await doDeleteClientProfile();onClose();}}>{formatMessage({id:"homepage.confirmWithdrawal.Yes"})}</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>{formatMessage({id:"homepage.confirmWithdrawal.No"})}</button>
                    </div>
                );
            }
        });

    };

    deleteSponsorProfile = async () => {
        const { formatMessage } = this.props.intl;

        const doDeleteSponsorProfile = async () =>{
            try {
                await resetCurrentSponsor();
                this.onPageLoad();
            }catch (e) {
                console.error(e.message);
            }
        }

        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <div className='react-confirm-alert-blur-custom-ui smallWindow'>
                        <p className="bigMessage">{formatMessage({id:"homepage.confirmWithdrawal"})}</p>
                        <button className="redButton" onClick={async () =>{await doDeleteSponsorProfile();onClose();}}>{formatMessage({id:"homepage.confirmWithdrawal.Yes"})}</button>
                        <button className="redButton" style={{marginRight:10}} onClick={onClose}>{formatMessage({id:"homepage.confirmWithdrawal.No"})}</button>
                    </div>
                );
            }
        });

    };


    renderBlackBox = (title, message, link, linkIcon, linkText) => {
        const { formatMessage } = this.props.intl;

        return (
            <>
                <BlackBoxContent
                    title={formatMessage({id:title})}
                    message={formatMessage({id:message})}
                    link={link}
                    linkIcon ={linkIcon}
                    linkText ={linkText}
                />
            </>
        )
    }

    renderGreyBox = (title, message, link, linkIcon, linkText) => {
        const { formatMessage } = this.props.intl;

        return (
            <>
                <GreyBoxContent
                    title={formatMessage({id:title})}
                    message={message}
                    link={link}
                    linkIcon ={linkIcon}
                    linkText ={linkText}
                />
            </>
        )
    }

    renderDownloadRappelGreyBox = (title, message, link, linkIcon, linkText) => {
        const downloadRappelAgenda = () => {
            const { formatMessage } = this.props.intl;

            // Créer le rappel
            const reminder = new ICAL.Event();
            reminder.uid = 'JUMELAGE_BVE_REMINDER';

            let dateReminder = new Date(this.getConfig("startDate"));
            dateReminder.setDate(dateReminder.getDate() -5);

            reminder.startDate = ICAL.Time.fromJSDate(dateReminder);
            reminder.summary = formatMessage({id:"calendarEvent.reminder.Summary"});
            reminder.description = formatMessage({id:"calendarEvent.reminder.Description"});
            reminder.location = 'Québec, Canada';
            reminder.url = 'https://www.jumelage.bve.ulaval.ca';

            // Créer l'événement
            const event = new ICAL.Event();
            event.uid = 'JUMELAGE_BVE_EVENT';

            let dateEvent = new Date(this.getConfig("startDate"));

            event.startDate = ICAL.Time.fromJSDate(dateEvent);
            event.summary = formatMessage({id:"calendarEvent.event.Summary"});
            event.description = formatMessage({id:"calendarEvent.event.Description"});
            event.location = 'Québec, Canada';
            event.url = 'https://www.jumelage.bve.ulaval.ca';

            // Ajouter les deux événements au calendrier
            const comp = new ICAL.Component(['vcalendar', [], []]);
            comp.addSubcomponent(reminder.component);
            comp.addSubcomponent(event.component);

            const blob = new Blob([comp.toString()], {type: "text/plain;charset=utf-8"});
            saveAs(blob, 'rappel_reminder_Jumelage-Ulaval.ics');
        };



        return this.renderGreyBox(title, message, downloadRappelAgenda, linkIcon, linkText)
    }

    renderCongratulationBox = (title, message, link1, link2)=>{
        const { formatMessage } = this.props.intl;

        return (
            <>
                <GreyBoxContent
                    title={formatMessage({id:title})}
                    titleColor="blackTitle"
                    isTitleH1={true}
                    message={message}

                    link={link1?.link}
                    linkIcon ={link1?.linkIcon}
                    linkText ={link1?.linkText}

                    link2={link2?.link}
                    link2Icon ={link2?.linkIcon}
                    link2Text ={link2?.linkText}

                />
            </>
        )
    }

    renderInfoBox = (title, message, link, linkIcon, linkText) => {
        const { formatMessage } = this.props.intl;

        return (
            <>
                <InfoBoxContent
                    title={title}
                    message={message}
                    link={link}
                    linkIcon ={linkIcon}
                    linkText ={linkText}
                />
            </>
        )
    }


    renderPage = () => {
        const clientFormURL = `/${this.state.locale}/forms/client`;
        const sponsorFormURL = `/${this.state.locale}/forms/sponsor`;
        const clientIsActive = this.state.ClientFormIsFilled.Active;
        const sponsorIsActive = this.state.SponsorFormIsFilled.Active;
        const bothProfileInactives = (!clientIsActive & !sponsorIsActive);
        const clientRegistrationDate = clientIsActive?this.state.ClientFormIsFilled.registration_date:null;
        const clientLastUpdate = clientIsActive?this.state.ClientFormIsFilled.last_update:null;
        const sponsorRegistrationDate = sponsorIsActive?this.state.SponsorFormIsFilled.registration_date:null;
        const sponsorLastUpdate = sponsorIsActive?this.state.SponsorFormIsFilled.last_update:null;
        const userIsLoggedIn = this.state.userIsLoggedIn;
        const pmMatchedMessage = this.getConfig("pmConfirmation");
        const startDate = this.getConfig("startDate");
        const endDate = this.getConfig("endDate");
        const endTime = this.getConfig("endTime");
        const startTime = this.getConfig("startTime");
        const infoBoxTitle = this.state.locale === "fr"? this.getConfig("infoBoxTitleFR"):this.getConfig("infoBoxTitleEN");
        const infoBoxText = this.state.locale === "fr"? this.getConfig("infoBoxTextFR"): this.getConfig("infoBoxTextEN");

        const homepageDisabled = this.state.locale === "fr"? this.getConfig("homepageDisabledTextFR"): this.getConfig("homepageDisabledTextEN");
        const homepageEnabled = this.state.locale === "fr"? this.getConfig("homepageEnabledTextFR"): this.getConfig("homepageEnabledTextEN");
        const clientRegisteredText = this.state.locale === 'fr' ? this.getConfig("clientRegisteredTextFR") : this.getConfig("clientRegisteredTextEN");
        const sponsorRegisteredText = this.state.locale === 'fr' ? this.getConfig("sponsorRegisteredTextFR") : this.getConfig("sponsorRegisteredTextEN");


        const moment_dateStart = moment(startDate + " " + startTime, 'YYYY-MM-DD hh:mm');
        const moment_dateEnd = moment(endDate + " " + endTime, 'YYYY-MM-DD hh:mm');
        const dateRange = moment.range(moment_dateStart, moment_dateEnd);
        const currentDateIsInRange = dateRange.contains(moment(new Date()));

        const currentDate = moment();

        // Définissez les dates de début et de fin de la session d'automne
        const sessionAutumnStart = moment().set('month', 8).set('date', 1); // 1er septembre
        const sessionAutumnEnd = moment().set('month', 11).set('date', 25); // 25 decembre

        // Vérifiez si la date d'aujourd'hui est comprise entre les dates de la session d'été
        const displayAutomnBanner = currentDate.isBetween(sessionAutumnStart, sessionAutumnEnd, null, '[]'); // '[]' pour inclure les bornes

        const displayPageH1 = currentDateIsInRange && userIsLoggedIn && (sponsorIsActive || clientIsActive);

        const modifierClientFormButton = () => {
            const { formatMessage } = this.props.intl;

            return {
                link:clientFormURL,
                linkIcon: "",
                linkText: formatMessage({id:"homepage.congratsBox.modifierCandidature"})
            };

        }

        const retirerClientCandidatureButton = () => {
            const { formatMessage } = this.props.intl;

            return {
                link:this.deleteClientProfile,
                linkIcon: "",
                linkText: formatMessage({id:"homepage.congratsBox.retirerCandidature"})
            };

        }

        const modifierSponsorFormButton = () => {
            const { formatMessage } = this.props.intl;

            return {
                link:sponsorFormURL,
                linkIcon: "",
                linkText: formatMessage({id:"homepage.congratsBox.modifierCandidature"})
            };

        }


        const retirerSponsorCandidatureButton = () => {
            const { formatMessage } = this.props.intl;

            return {
                link:this.deleteSponsorProfile,
                linkIcon: "",
                linkText: formatMessage({id:"homepage.congratsBox.retirerCandidature"})
            };

        }

        const redirectMonJumelageButton = () => {
            const { formatMessage } = this.props.intl;

            return {
                link:this.redirectMonJumelage,
                linkIcon: "",
                linkText: formatMessage({id:"homepage.congratsBox.redirectionMonJumelage"})
            };

        }


        if (!userIsLoggedIn){
            return <Redirect to='/fr/login' />
        }
        else{
            return (
                <>
                {displayPageH1?
                    <>
                    </>
                    :
                    <>
                        <div className="Title">
                            <h1 className="homepageTitle"><FormattedMessage id="homepage.title" /></h1>
                        </div>
                    </>
                }

                    <div className="headerImage">
                        {displayAutomnBanner?<img src={require('./Images/Automn.jpg')} alt="Image accueil"/>:<img src={require('./Images/Winter.jpg')} alt="Image accueil"/>}
                    </div>

                    <div className="row">

                        <div className="col-md-12">

                            {/*{this.state.ClientIsMatched && <><h2><FormattedMessage id="client.hasBeenMatched" /></h2></>}*/}
                            {this.state.SponsorIsMatched &&
                                <>
                                    <div dangerouslySetInnerHTML={{__html: pmMatchedMessage}}></div>

                                </>
                            }

                            {/*{!currentDateIsInRange && <FormattedMessage id="homepage.content1Outdated" values={{ p: (...chunks) => <p>{chunks}</p>}}/>}*/}
                            {!currentDateIsInRange &&
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.renderDownloadRappelGreyBox("homepage.warningTitle", homepageDisabled, "www.google.ca", null, "Ajouter un rappel à votre agenda")}
                                        </div>
                                    </div>

                                </>


                            }
                            {currentDateIsInRange && !(sponsorIsActive || clientIsActive) &&
                                <>
                                    <div className="row">
                                        <div className="col-md-12">
                                            {this.renderGreyBox("homepage.warningTitle", homepageEnabled, null, null, null)}
                                        </div>
                                    </div>
                                </>
                            }

                        </div>

                    </div>

                    {(currentDateIsInRange && userIsLoggedIn && bothProfileInactives)?
                        <>
                            <div className="row loginCards">
                                <div className="col-md-6 clientCard">
                                    {this.renderBlackBox("client.card.title", "client.card.description", clientFormURL, 'plus', null)}
                                </div>
                                <div className="col-md-6 sponsorCard">
                                    {this.renderBlackBox("sponsor.card.title", "sponsor.card.description", sponsorFormURL, 'plus', null)}
                                </div>
                            </div>
                        </>
                        :null
                    }

                    {currentDateIsInRange && userIsLoggedIn && ((clientIsActive && !sponsorIsActive))?
                        <div id="ClientCard">
                            {this.renderCongratulationBox(
                                "client.card.description.formFilled.Title",
                                clientRegisteredText ? clientRegisteredText:"vide",
                                this.state.ClientIsMatched?redirectMonJumelageButton():modifierClientFormButton(),
                                this.state.ClientIsMatched?null:retirerClientCandidatureButton()
                                )
                            }
                        </div>
                        :null
                    }

                    {currentDateIsInRange && userIsLoggedIn & ((sponsorIsActive & !clientIsActive))?
                        <div id="SponsorCard">
                            {this.renderCongratulationBox(
                                "sponsor.card.description.formFilled.Title",
                                sponsorRegisteredText,
                                this.state.SponsorIsMatched?redirectMonJumelageButton():modifierSponsorFormButton(),
                                this.state.SponsorIsMatched?null:retirerSponsorCandidatureButton()
                                )
                            }
                        </div>
                        :null
                    }

                    <div className="row">
                        <div className="col-md-12">
                            {(infoBoxTitle.toUpperCase() !== 'NULL') && this.renderInfoBox(infoBoxTitle, infoBoxText, null, null, null)}
                        </div>
                    </div>

                </>
            );
        }

    };

    render() {

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        )


    }
}

export default injectIntl(withRouter(HomepageContent));
