import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import LoadClientForm from "../contents/Forms/Client/LoadClientForm";
import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";

const ClientFormLayout = ({match, locale}) => {

    return(
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <LoadClientForm locale={locale} id={match.params.id}/>
            </main>
            <Footer/>
        </>
    );
}


export default withRouter(injectIntl(ClientFormLayout));
