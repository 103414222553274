import httpService from "./httpService";

const clientsAPIEndpoint = process.env.REACT_APP_API + "/clients";
const sponsorsAPIEndpoint = process.env.REACT_APP_API + "/sponsors";
const apiEndpoint = process.env.REACT_APP_API + "/users";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getAllUsers(userType = "clients", sortBy, currentPage, pageSize, showInactive = 0, filterString, showMatchedUserStatus, showUserState) {
    const selectedAPIEndpoint = (userType==="sponsors")?sponsorsAPIEndpoint : clientsAPIEndpoint;

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${selectedAPIEndpoint}?sortBy=${sortBy}&pageNumber=${currentPage}&pageSize=${pageSize}&showInactive=${showInactive}&filterString=${filterString}&showMatchedUserStatus=${showMatchedUserStatus}&showUserState=${showUserState}`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function getUserClientOrSponsorObject(idul){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/getClientOrSponsorObject/${idul}`,  {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}