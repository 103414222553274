import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/clients";
const tokenKey = process.env.jumelage_jwtPrivateKey;


export function getAllClients(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}`, {headers: {"x-auth-token" : jwt}});
    }
    catch (ex){
        return null;
    }
}

export function getCurrentClient() {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/me`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function currentClientIsMatched(){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/ismatched`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function currentClientIsFilled() {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/isfilled`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function resetCurrentClient() {
    try {
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.delete(`${apiEndpoint}/me`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function UpdateCurrentClient(clientObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.put(`${apiEndpoint}/me`, clientObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function UpdateClient(clientObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = clientObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, clientObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function getClientByID(id) {
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}
