import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import AdminpageContent from "../contents/Pages/AdminPage/adminpageContent";
import {injectIntl} from "react-intl";


const AdminPageLayout = ({user, intl}) => {

    return(
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <AdminpageContent locale={intl.locale} user={user}/>
            </main>
            <Footer/>
        </>
    );
}


export default injectIntl(AdminPageLayout);
