import React, {Component} from 'react';
import {getCountryByID} from "../../../../services/countryService"
import {Redirect, withRouter} from 'react-router-dom';
import LoadingComponent from "../../common/loadingComponent";
import CountryForm from "./CountryForm";
import httpService from "../../../../services/httpService";
import {getWorldRegionsList} from "../../../../services/worldregionsService";

class LoadCountryForm extends Component {

    _isMounted = false;

    state = {
        RedirectToLoginPage:false,
        isLoading : false,
        userToken : this.props.user,
        country : {},
        worldRegionsList : {},
        locale : this.props.locale
    };


    componentDidMount() {
        this._isMounted = true;

        if (this.props.id !== undefined){
            this.onLoadCountry();
        }
        else{
            console.log("Loading else")
            this.loadWorldRegionsOnly();
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    loadWorldRegionsOnly = async () =>{
        const language = this.props.locale;
        try{
            this.setState({isLoading:true});
            const {data : worldRegionsList} = await getWorldRegionsList(language)
            console.log(worldRegionsList)
            if (this._isMounted) {
                this.setState(
                    {
                        worldRegionsList,
                        isLoading: false
                    }
                )
                console.log("loaded:" + JSON.stringify(this.state.worldRegionsList))
            }

        }catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }

    onLoadCountry = async () =>{
        const language = this.props.locale;
        try {

            this.setState({isLoading:true});
            const [
                {data: country},
                {data:worldRegionsList}
                ] = await httpService.all([
                    getCountryByID(this.props.id),
                    getWorldRegionsList(language)
                    ]);
            if (this._isMounted){
                this.setState({
                    country,
                    worldRegionsList,
                    isLoading: false
                });
            }

        }  catch(err)  {
            if (this._isMounted){
                this.setState({isLoading: false, RedirectToLoginPage:true});
            }
        }
    }


    renderPage = () => {
        return (
          <>
              <CountryForm
                  isAdminForm = {(this.props.id)?true:false}
                  locale = {this.props.locale}
                  country = {this.state.country}
                  worldRegionsList = {this.state.worldRegionsList}
              />
          </>
        );
    }

    render() {

        if (this.state.RedirectToLoginPage) {
            const loginPage = `/${this.state.locale}/login`
            return <Redirect to={loginPage}  />
        }

        return (
            <>
                {this.state.isLoading ? <LoadingComponent/> : this.renderPage()}
            </>
        );
    }
}

export default withRouter(LoadCountryForm);
