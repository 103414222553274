import "react-app-polyfill/ie11";
import "react-app-polyfill/stable";
import React from "react";
import ReactDOM from 'react-dom';
import {IntlProviderWrapper} from "./IntlProviderWrapper";
import * as serviceWorker from './serviceWorker';
import "./index.css";

function App() {
    return (<IntlProviderWrapper/>);
}

ReactDOM.render(
    <App/>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
