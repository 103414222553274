import * as PropTypes from 'prop-types'
import React from "react";
import { Route } from 'react-router-dom'

const buttonStyle = {
    margin: '10px 10px 10px 0'
};

const ButtonLink = ({link, label}) => (
    <Route render={({ history}) => (
        <button
            className="btn btn-success"
            style={buttonStyle}
            onClick={() => { history.push(link) }}
        >
            {label}
        </button>
    )} />
)



ButtonLink.propTypes = {
    link: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired
};

export default ButtonLink