import React, {useRef} from 'react';
import PropTypes from 'prop-types';
import "bootstrap-icons/font/bootstrap-icons.css";
import "./ToastStdMessage.css";

const ToastStdMessage = props => {
    // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    const iconClass = "bi bi-" + props.icon;
    const refContainer = useRef();
    console.log(refContainer);

    return (
        <div className="ToastStdMessage">
            <div className="Block-Icon">
                <i className={iconClass}></i>
            </div>
            <div className="Block-Content">
                <span className="title">{props.title}</span>
                <span className="content">{props.message}</span>
            </div>


        </div>
    );
};

ToastStdMessage.propTypes = {
    icon: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    message: PropTypes.string.isRequired
};

ToastStdMessage.defaultProps = {
    icon: "slash-square",  // (warning)exclamation-square, (success)check-square, (error)slash-square, (info)info-square
    title: "title",
    message: "message"
};


export default ToastStdMessage;
