import httpService from "./httpService";

const apiEndpoint = process.env.REACT_APP_API + "/countries";
const tokenKey = process.env.jumelage_jwtPrivateKey;

export function getCountryList(language){
    const jwt = sessionStorage.getItem(tokenKey);
    let lang = language;
    if(!lang){ lang="fr"}
    return httpService.get(apiEndpoint + "?locale=" +lang +"&sortBy=name", {headers: {"x-auth-token" : jwt}})
}

export function getAllCountries(sortBy, locale, currentPage, pageSize, showInactive = 0, filterString){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(apiEndpoint + `?sortBy=${sortBy}&locale=${locale}&pageNumber=${currentPage}&pageSize=${pageSize}&showInactive=${showInactive}&filterString=${filterString}`, {headers: {"x-auth-token" : jwt}});
}

export function getCountryByID(id){
    const jwt = sessionStorage.getItem(tokenKey);
    return httpService.get(`${apiEndpoint}/${id}`, {headers: {"x-auth-token" : jwt}})
}

export function UpdateCountry(countryObject){
    try{
        const jwt = sessionStorage.getItem(tokenKey);
        const id = countryObject.id;
        return httpService.put(`${apiEndpoint}/${id}`, countryObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}

export function CreateNewCountry(countryObject){
    if(countryObject.id !== undefined)
        delete countryObject.id

    try{
        const jwt = sessionStorage.getItem(tokenKey);
        return httpService.post(`${apiEndpoint}`, countryObject, {headers: {"x-auth-token" : jwt}});
    } catch (ex){
        return null;
    }
}
