import axios from "axios"

axios.interceptors.response.use(null, error => {
    const expectedError =
        error.response &&
        error.response.status >= 404 &&
        error.response.status < 500;

    if (!expectedError){
        console.log("An unexpected error occurred:", error)
    }

    return Promise.reject(error);
});



const exportedObject = {
    all: axios.all,
    get: axios.get,
    post: axios.post,
    put: axios.put,
    delete: axios.delete,
};

export default exportedObject;
