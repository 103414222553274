import React, { createContext, useState, useContext } from 'react';

// Créer le contexte
export const NotificationsContext = createContext();

// Créer un provider qui contient la logique de gestion des notifications
export function NotificationsProvider({ children }) {
    const [notifications, setNotifications] = useState(0);

    const addNotification = () => {
        setNotifications(notifications + 1);
    };

    const removeNotification = () => {
        setNotifications(notifications - 1);
    };

    const setNotification = (amount) =>{
        setNotifications(amount)
    }

    return (
        <NotificationsContext.Provider value={{ notifications, addNotification, removeNotification, setNotification }}>
            {children}
        </NotificationsContext.Provider>
    );
}

