import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowCountriesTable extends Component {

    worldRegions = this.props.worldRegionsList;

    columns = [
        //{path:"id",label:this.props.intl.formatMessage({id: "table.countries.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.countries.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.countries.fr.short"})},
        {path:"en",label:this.props.intl.formatMessage({id: "table.countries.en.short"})},
        {path:"worldRegion_fr",label:this.props.intl.formatMessage({id: "table.countries.WorldRegionID.short"})},
        {key:"edit", content: country => (
                <Link to={`./manage-countries/${country.id}`} >{this.props.intl.formatMessage({id: "table.countries.editLink"})}</Link>
            )}
    ];

    componentDidMount() {
        console.log(this.props.countriesList)
    }



    render() {
        const {countriesList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {countriesList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowCountriesTable);
