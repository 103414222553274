import {withRouter} from "react-router-dom"
import {injectIntl} from "react-intl";
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import React from "react";
import WorldRegionsTableFrame100 from "../tableLayouts/WorldRegionsTableFrame100";

const WorldRegionsListLayout = (props) => {
    return (
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <WorldRegionsTableFrame100 locale={props.locale}/>
            </main>
            <Footer/>
        </>
    );
}

export default withRouter(injectIntl(WorldRegionsListLayout));