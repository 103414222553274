import React, {Component} from 'react';
import CRUDTable from "../common/htmlElements/TableComponent/subTableComponents/CRUDTable";
import {Link} from "react-router-dom";
import {injectIntl} from "react-intl";

class ShowSessionsTable extends Component {

    columns = [
        //{path:"id",label:this.props.intl.formatMessage({id: "table.sessions.id.short"})},
        {path:"active",label:this.props.intl.formatMessage({id: "table.sessions.active.short"})},
        {path:"fr",label:this.props.intl.formatMessage({id: "table.sessions.fr.short"})},
        {path:"en",label:this.props.intl.formatMessage({id: "table.sessions.en.short"})},
        {path:"clientOnly",label:this.props.intl.formatMessage({id: "table.sessions.clientOnly.short"})},
        {path:"sponsorOnly",label:this.props.intl.formatMessage({id: "table.sessions.sponsorOnly.short"})},
        {key:"edit", content: session => (
                <Link to={`./manage-sessions/${session.id}`} >{this.props.intl.formatMessage({id: "table.sessions.editLink"})}</Link>
            )}
    ];

    render() {
        const {sessionsList, onSort, sortColumn} = this.props;

        return (
            <CRUDTable
                columns={this.columns}
                data = {sessionsList}
                sortColumn={sortColumn}
                onSort={onSort}
                isLoading={this.props.isLoading}
                locale = {this.props.intl}
            />
        );
    }
}

export default injectIntl(ShowSessionsTable);
