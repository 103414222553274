import React from 'react';
import Moment from 'react-moment';
import moment from 'moment';
import ReactTooltip from 'react-tooltip';
import "./FicheMatchUser.css"

const FicheMatchUser = (props) => {
    const user = props.data;
    const forcedFooter = props.forceFooter;
    const selected = user.id === parseInt(props.selectedID);
    const facultyList = props.facultyList;
    const countryList = props.countryList;
    const languageList = props.languageList;
    const preferencesList = props.preferencesList;
    const interestsList = props.interestsList;
    const sessionsList = props.sessionsList;
    const studyLevelList = props.studyLevelList;
    const adminComments = (user.adminComments === "<p><br></p>")?null:user.adminComments;

    const maybePluralize = (count, noun, suffix = 's') =>
        ` ${noun}${count !== 1 ? suffix : ''}`;


    const showMatchScore = () => {
        return (
            <>
                <span className="userScoreTitle">Respect des choix du parrain/marrainne <br/>+10 par préférence (Même sexe ne compte pas)<br/>+5 si même niveau d'études<br/>+1 par intérêt</span>
                <div className="scoreDisplay">
                    <span className="userScore">{user.matchAttributes.score} {maybePluralize(user.matchAttributes.score, 'point', 's')}</span>
                </div>
            </>
        )
    }

    const renderFicheFooter = (forcedFooter, user) => {

        if (forcedFooter === undefined){
            return ((user.matchAttributes)?showMatchScore():<span className="userScoreTitle">Étudiant étranger</span>)
        }
        else{
            return (forcedFooter)
        }
    }

    const renderVeteranIconClass = (isVeteran) => {
        switch(isVeteran){
            case 0: return "recruitIcon";
            case 1: return "veteranIcon";
            default: return "recruitIcon";
        }
    }

    const renderGenderIconClass = (genderID) => {
        switch(genderID){
            case 0: return "genderIconFemale";
            case 1: return "genderIconMale";
            default: return "genderIconUnset";
        }
    }

    let userAge = moment().diff(user.birthdate, 'years');
    if (isNaN(userAge)){
        userAge = null;
    }

    let headerClass = selected?"row userHeader selected":"row userHeader";
    let footerClass = selected?"row userFooter selected":"row userFooter";
    const valueCurrentLoad = (user.sponsorLoadCurrent<=user.sponsorLoad)?"valueCurrentLoad":"valueCurrentLoad overLoaded";

    return (
    <>
        <ReactTooltip />
        <div className="container userCard">
            <div className={headerClass}>
                <div className={"col-md-1 icon " + renderGenderIconClass(user.gender)}></div>
                <div className={"col-md-1 icon " + renderVeteranIconClass(user.isVeteran)}></div>
                <div className="col-md-8">
                    <span className="userName">{user.firstName} {user.lastName} <span className="userAge">{userAge?"(" + userAge+ " ans)":null}</span></span>
                    <span className="userFaculty">{facultyList.find(fac => fac.id === user.faculty_id)?.name}</span>
                    <span className="userStudyLevel">{studyLevelList.find(lvl => lvl.id === user.studyLevel)?.name}</span>
                    <span className="userCountry">{countryList.find(country => country.id === user.originCountry_id)?.name}</span>
                </div>


                {(user.inChargeOfClientsID?.length)?
                    <>
                        <div className="col-md-2">
                            <div className="badge sponsorLoad">
                                <span className={valueCurrentLoad}>{user.inChargeOfClientsID.length}</span>
                                <span className="slashBar">/</span>
                                <span className="totalCurrentLoad">{user.sponsorLoad}</span>
                            </div>
                        </div>
                    </>
                    :
                    <></>
                }


                <span className="userRegisteredOn"> Création de la fiche:
                        <Moment format="YYYY/MM/DD">
                            {user.date_created}
                        </Moment>
                </span>

                {(user.firstSession_id !== null) ?
                    <span className="firstSession">
                    Session d'entrée: {
                        sessionsList.find(session => session.id === user.firstSession_id)?.name?sessionsList.find(session => session.id === user.firstSession_id)?.name:"Erreur - Choix inexistant dans la BD"
                    }
                </span>
                    :
                <></>
                }

                    {(user.sponsorLoadCurrent > 0)?
                        <>
                            <span className="userAvailableOn"> Disponible le:
                                {(user.availabilityDate !== null)?
                                    <Moment format="YYYY/MM/DD">
                                        {user.availabilityDate}
                                    </Moment>
                                        :
                                    " Inconnue"
                                }
                            </span>
                        </>
                        :
                        <span className="userAvailableOn"> Arrivée au Québec:
                            {(user.arrivalInQuebec !== null)?
                                <Moment format="YYYY/MM/DD">
                                    {user.arrivalInQuebec}
                                </Moment>
                                    :
                                " Inconnue"
                            }
                            </span>
                    }
            </div>

            {(user.sponsorLoadCurrent > 0) ?
                <>
                    <div className="row">
                        <div className="col-md-4 rowTitle">
                            <p>Matches actuels (
                                <span className={
                                    (user.inChargeOfClientsID?.length > user.sponsorLoad)?"redColor":null
                                }>
                                    {user.inChargeOfClientsID?.length}</span> / {user.sponsorLoad})
                            </p>
                        </div>
                        <div className="col-md-8">
                            {user.inChargeOfClientsNames.split("<br/>").map(item => <span key={user.inChargeOfClientsNames.indexOf(item)} className="sponsorLoadNames badge badge-info">{item}</span>)}
                        </div>
                    </div>
                </>
                :
                <></>
            }
            <div className="row">
                <div className="col-md-3 rowTitle">Langues parlées</div>
                <div className="col-md-9 ">
                    <span className="primaryLang">{languageList.find(lang => lang.id === user.languagePrimary_id).name}</span>
                    <ul className="secondaryLang">
                    {user.languageOther?user.languageOther.map(currentLang => (
                            <li key={languageList.find(lang => lang.id === currentLang).id}>
                                {languageList.find(lang => lang.id === currentLang).name}
                            </li>))
                        :null}
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 rowTitle">Préférences</div>
                <div className="col-md-9 ">
                    <ul className="preferenceItemList">
                        {user.preferences?
                            user.preferences.map(currentPref => (
                                <li key={preferencesList.find(pref => pref.id === currentPref)?.id}>
                                    {preferencesList.find(pref => pref.id === currentPref)?.name}
                                </li>)
                            )
                            :null}
                    </ul>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 rowTitle">Intérêts</div>
                <div className="col-md-9 ">
                    <ul className="interestItemList">
                        {user.interests?user.interests.map(currentInterest => (
                                <li key={interestsList.find(interest => interest.id === currentInterest).id}>
                                    {interestsList.find(interest => interest.id === currentInterest).name}
                                </li>))
                            :null}
                    </ul>
                </div>
            </div>
            <div className="row commentsRow">
                <div className={"col-md-3 rowTitle"}>Commentaires de l'étudiant</div>
                <div className="col-md-9">
                    <span className="userComments">{user.comments}</span>
                </div>
            </div>

            {adminComments &&
            <div className="row AdminCommentsRow">
                <div className={"col-md-3 rowTitle adminCommentsTitle"}>Commentaires administratifs</div>
                <div className="col-md-9">
                    <span className="adminComments"><div dangerouslySetInnerHTML={{__html: adminComments}} /></span>
                </div>
            </div>
            }

            <div className={footerClass}>
                <div className="col-md-12 ">
                <div className="userDetailsIcon"></div>
                <div className="userDetailsData">
                    {renderFicheFooter(forcedFooter, user)}
                </div>
            </div>
            </div>
        </div>

    </>
    );
};

export default FicheMatchUser;