import {useHistory} from "react-router-dom";
import {useEffect} from "react";

export const useVerifyLogin = (user) => {
    const history = useHistory();

    useEffect(() => {
        if (!user) {
            history.push('/fr/login');
        }
    }, [user, history]);
};