import React from 'react';
import Header from "../headers/HeaderUlaval2023/HeaderUlaval2023";
import Footer from "../footers/FooterUlaval2023/FooterUlaval2023";
import HomepageContent from "../contents/Pages/HomePage/homepageContent";
import {injectIntl} from "react-intl";
import {useNotifications} from "../../Hooks/useNotifications";
import LoadHomePage from "../contents/Pages/HomePage/LoadHomePage";

const HomePageLayout = ({user, intl}) => {

    return(
        <>
            <Header/>
            <main role="main" property="mainContentOfPage" className="container main-content-container">
                <LoadHomePage />
            </main>
            <Footer/>
        </>
    );
}


export default injectIntl(HomePageLayout);
